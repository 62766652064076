import React, { useContext } from 'react';
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material/styles';
import GameContext from 'contexts/GameContext';
import { useAppSelector } from 'hooks';

import ExitTableButton from './ExitTableButton';
import SitOutButton from './SitOutButton';
import StandUpButton from './StandUpButton';

import './TableActions.scss';

const TableActions = () => {
  const { isStandingUp, isSatOut } = useContext(GameContext);

  const isSeated = useAppSelector((state) => state.currentTable.currentPlayer.isSeated);
  const game = useAppSelector<any>((state) => state.currentTable.game);
  const seatId = useAppSelector<any>((state) => state.currentTable.currentPlayer.seatId);
  const isWaitingAndExit = useAppSelector<any>(
    (state) => state.currentTable.currentPlayer.isWaitingAndExit
  );
  const tableType = useAppSelector<any>((state) => state.currentTable.game.tabletype);
  const noPlayers = useAppSelector<any>((state) => state.currentTable.gameState.noPlayers);
  const playerStatus = game[`p${seatId}status`];

  return (
    <>
      <ThemeProvider theme={theme}>
        <div className='table-actions'>
          {!(playerStatus === 5 || playerStatus === 4) &&
            isSeated &&
            !isStandingUp &&
            !isWaitingAndExit &&
            tableType !== 'm' &&
            tableType !== 't' && <SitOutButton />}
          {!(playerStatus === 5 || playerStatus === 4) &&
            isSeated &&
            !isSatOut &&
            !isWaitingAndExit &&
            tableType !== 'm' &&
            tableType !== 't' && <StandUpButton />}
          {((isSeated && tableType !== 'm' && tableType !== 't') ||
            ((tableType === 'm' || tableType === 't') && (!isSeated || +noPlayers === 0))) && (
            <ExitTableButton />
          )}
        </div>
      </ThemeProvider>
    </>
  );
};

export default TableActions;

const theme = createTheme({
  components: {
    MuiFab: {
      styleOverrides: {
        root: {
          color: 'white',
          backgroundColor: '#37393b',
          textTransform: 'unset',
          fontWeight: '300',
          fontSize: '0.75rem',
          height: 'unset',
          width: 'unset',
          paddingTop: '0.25rem',
          paddingBottom: '0.25rem',
          '&:hover': {
            backgroundColor: '#5C5F63'
          },
          '&.active': {
            backgroundColor: '#508991'
          }
        }
      }
    }
  }
});
