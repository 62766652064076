import { useContext, useEffect, useState } from 'react';
import React from 'react';
import { Button, createTheme, Modal, ThemeProvider, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { GOT_TOURNAMENT_REBUY } from 'constants/eventConstants';
import GameContext from 'contexts/GameContext';
import { socket } from 'contexts/SocketContext';
import Decimal from 'decimal.js';
import { useAppSelector } from 'hooks';
import useGameService from 'services/GameService';
import { numberFormatter } from 'utils/functions';

const RebuyChipsModal = () => {
  const { tournamentRebuy } = useGameService();
  const { canRebuyChips } = useContext(GameContext);
  const rebuyPrice = useAppSelector((state) => state.currentTable.game.rebuyPrice);
  const rebuyChips = useAppSelector((state) => state.currentTable.game.rebuyChips);
  const seatId = useAppSelector((state) => state.currentTable.currentPlayer.seatId);
  const linkedId = useAppSelector((state) => state.currentTable.game.linkedId);
  const gameID = useAppSelector((state) => state.currentTable.game.gameID);
  const entryFee = useAppSelector((state) => state.currentTable.game.entryFee);
  const bountyAmount = useAppSelector((state) => state.currentTable.game.bountyAmount);
  const gameType = useAppSelector((state) => state.currentTable.game.gameType);
  const [msgTitle, setMsgTitle] = useState('');
  const [msg, setMsg] = useState('');
  const { openRebuyChips, setOpenRebuyChips } = useContext(GameContext);

  const handleRebuyChips = () => {
    setOpenRebuyChips(false);
    setMsgTitle('');
    setMsg('');
    tournamentRebuy(seatId, gameID, linkedId);
  };

  const cancelAddChips = () => {
    setMsgTitle('');
    setMsg('');
    setOpenRebuyChips(false);
  };

  useEffect(() => {
    if (!openRebuyChips) {
      setMsgTitle('');
      setMsg('');
    }
  }, [openRebuyChips]);

  useEffect(() => {
    socket.on(GOT_TOURNAMENT_REBUY, (data: any) => {
      if (data) {
        setOpenRebuyChips(true);
        setMsgTitle(data?.title);
        setMsg(data?.msg);
      } else {
        setMsgTitle('');
        setMsg('');
        setOpenRebuyChips(false);
      }
      return () => {
        socket.off(GOT_TOURNAMENT_REBUY);
      };
    });
  }, []);

  return (
    <Modal open={(canRebuyChips && openRebuyChips) || openRebuyChips}>
      <Box sx={modalStyle}>
        <Typography
          id='modal-modal-title'
          variant='h5'
          component='h2'
          sx={{ textAlign: 'center', marginBottom: '2rem' }}>
          {msgTitle !== '' ? msgTitle : 'Still want to play?'}
        </Typography>
        <Typography id='modal-modal-title' sx={{ textAlign: 'center', marginBottom: '2rem' }}>
          {msg !== ''
            ? msg
            : `You can still re-entry. Please note that proceeding with this action will incur a $${Decimal.sum(
                rebuyPrice === null ? 0 : rebuyPrice,
                entryFee === null ? 0 : entryFee,
                gameType === 'Bounty' ? bountyAmount : 0
              )
                .toDP(2)
                .toNumber()} charge, and you
          will receive ${numberFormatter(rebuyChips)} chips, and continue playing to the tournament.`}
        </Typography>

        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '2rem',
            gap: '2rem'
          }}>
          <ThemeProvider theme={CancelBtnStyle}>
            <Button
              sx={{ display: 'block', opacity: '.8' }}
              variant='contained'
              onClick={cancelAddChips}>
              {msgTitle.includes('Failed')
                ? 'Close'
                : msgTitle !== ''
                ? 'Take your seat, Partner'
                : 'Cancel'}
            </Button>
          </ThemeProvider>
          {msgTitle === '' && (
            <ThemeProvider theme={AddChipsBtnStyle}>
              <Button sx={{ display: 'block' }} variant='contained' onClick={handleRebuyChips}>
                Proceed
              </Button>
            </ThemeProvider>
          )}
        </div>
      </Box>
    </Modal>
  );
};

export default RebuyChipsModal;

const modalStyle = {
  position: 'absolute' as const,
  top: '40%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: '#012425',
  color: 'white',
  padding: '4rem 7rem',
  width: '40rem',
  borderRadius: '0.5rem'
};

const CancelBtnStyle = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          color: 'white',
          backgroundColor: '#5b6671',
          '&:hover': {
            color: 'white',
            backgroundColor: '#37393b'
          },
          '&:disabled': {
            color: 'white',
            opacity: '0.4'
          },
          fontSize: '1rem',
          fontWeight: '400',
          padding: '0.5rem 1rem',
          minWidth: 'unset',
          textTransform: 'none',
          width: '100%'
        }
      }
    }
  }
});

const AddChipsBtnStyle = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          color: 'white',
          backgroundColor: '#E98C26',
          '&:hover': {
            color: 'white',
            backgroundColor: '#A7651C'
          },
          '&:disabled': {
            color: 'white',
            backgroundColor: '#E98C26',
            opacity: '0.4'
          },
          fontSize: '1rem',
          fontWeight: '400',
          padding: '0.5rem 1rem',
          minWidth: 'unset',
          textTransform: 'none',
          width: '100%'
        }
      }
    }
  }
});
