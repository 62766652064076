import { useEffect, useState } from 'react';
import { useSpring, useSpringRef } from 'react-spring';
import { Box, CircularProgress } from '@mui/material';
import { audioPath } from 'constants/audioConstants';
import {
  SEAT_CONTAINER_HEIGHT,
  SEAT_CONTAINER_WIDTH,
  TIME_PROGRESS_BAR_THICKNESS
} from 'constants/gameConstants';
import { useAppSelector } from 'hooks';
import useSound from 'use-sound';
import { getPercentage } from 'utils/functions';

import { getProgressColor, TimerPropType } from './';

const SeatTimer = ({ children, start, current, target, isStartProgress, size }: TimerPropType) => {
  const [progress, setProgress] = useState(0);
  const [soundPlayed, setSoundPlayed] = useState(false);
  const springApi = useSpringRef();
  const [playPlayerOutOfTime] = useSound(audioPath.playerOutOfTime);
  const languageKey = useAppSelector<any>((state) => state.currentTable.game.languageKey);
  useSpring({
    ref: springApi,
    onChange({ value }) {
      setProgress(getPercentage(+value.progress, target));
    }
  });

  useEffect(() => {
    const initialProgress = Math.max(current - start, 0);
    springApi.start({
      from: {
        progress: initialProgress
      },
      to: {
        progress: target
      },
      config: {
        duration: Math.max((target - initialProgress) * 1000, 1)
      }
    });

    if (isStartProgress) {
      setSoundPlayed(false);
      springApi.start();
    } else {
      springApi.stop();
      if (!soundPlayed && languageKey === 'GAME_PLAYER_FOLDS') {
        playPlayerOutOfTime();
        setSoundPlayed(true);
      }
    }
  }, [start, springApi, current, target, isStartProgress, soundPlayed]);

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'inline-flex',
        height: SEAT_CONTAINER_HEIGHT,
        width: SEAT_CONTAINER_WIDTH
      }}>
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
        {children}
      </Box>
      {isStartProgress && (
        <>
          <CircularProgress
            variant='determinate'
            thickness={TIME_PROGRESS_BAR_THICKNESS}
            value={progress}
            size={size}
            sx={{
              position: 'absolute',
              top: `${-TIME_PROGRESS_BAR_THICKNESS * 2.25}px`,
              left: `${-TIME_PROGRESS_BAR_THICKNESS * 2.25}px`,
              color: getProgressColor(progress),
              transition: 'all 1s ease',
              borderRadius: '50%',
              zIndex: 1
            }}
          />
          <Box
            sx={{
              position: 'absolute',
              top: `${-TIME_PROGRESS_BAR_THICKNESS * 2.25}px`,
              left: `${-TIME_PROGRESS_BAR_THICKNESS * 2.25}px`,
              width: `${size}px`,
              height: `${size}px`,
              border: `${TIME_PROGRESS_BAR_THICKNESS * 2.25}px solid rgba(0,0,0,0.3)`,
              borderRadius: '50%',
              zIndex: 0
            }}></Box>
        </>
      )}
    </Box>
  );
};

export default SeatTimer;
