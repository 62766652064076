import { useContext } from 'react';
import { Button, createTheme, ThemeProvider } from '@mui/material';
import GameContext from 'contexts/GameContext';
import useGameService from 'services/GameService';

import 'components/UserActions/UserActions.scss';

const VoteNoAction = () => {
  const { multiRunVote } = useGameService();
  const { playerMultiRunVote } = useContext(GameContext);

  const DefaultBtn = createTheme({
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            color: 'white',
            backgroundColor: '#5b6671',
            '&.active': {
              backgroundColor: '#E98C26',
              '&:hover': {
                backgroundColor: '#A7651C'
              }
            },
            '&:hover': {
              color: 'white',
              backgroundColor: '#37393b'
            },
            '&:disabled': {
              color: 'white',
              opacity: '0.4'
            },
            fontSize: '1rem',
            fontWeight: '400',
            padding: '0.75rem 1rem',
            minWidth: 'unset',
            textTransform: 'none',
            width: '100%'
          }
        }
      }
    }
  });

  return (
    <ThemeProvider theme={DefaultBtn}>
      <Button
        className={playerMultiRunVote === '-2' ? 'active' : ''}
        size='small'
        onClick={() => multiRunVote('-2')}
      >
        No
      </Button>
    </ThemeProvider>
  );
};

export default VoteNoAction;
