import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { APP_DOMAIN, FRONT_OFFICE_LOCAL_URL } from 'utils/urls';

import styles from './AvatarDropdown.module.scss';
interface Props {
  userName: string;
  email: string;
  avatar: string;
}

const BasicMenu: React.FC<Props> = ({ userName, email, avatar }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const avatarMedia = `https://media.${APP_DOMAIN}/images/${avatar}`;
  const accountUrl =
    'https://account.' +
    window.location.hostname.split('.')[1] +
    '.' +
    window.location.hostname.split('.')[2];

  const goToAccount = () => {
    if (process.env.NODE_ENV === 'development') {
      setAnchorEl(null);
      window.location.href = `${FRONT_OFFICE_LOCAL_URL}/hijack/profile`;
      return;
    }

    setAnchorEl(null);
    window.location.href = `${accountUrl}/hijack/profile`;
  };

  const Logout = () => {
    if (process.env.NODE_ENV === 'development') {
      setAnchorEl(null);
      window.location.href = `${FRONT_OFFICE_LOCAL_URL}/logout`;
      return;
    }

    setAnchorEl(null);
    window.location.href = `${accountUrl}/logout`;
  };

  return (
    <div style={{ height: '100%' }}>
      <Button
        id='basic-button'
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        style={{ height: '100%', borderRadius: '0px' }}>
        <Avatar style={{ width: '42px', height: '42px' }} src={avatarMedia} />
      </Button>
      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        PaperProps={{
          style: {
            borderRadius: '0px',
            minWidth: '13rem'
          }
        }}>
        <div className={styles.profile_information}>
          <div className={styles.row}>
            <div className={styles.col}>
              <Avatar
                className={styles.player_avatar}
                style={{ width: '42px', height: '42px' }}
                src={avatarMedia}
              />
            </div>
            <div className={styles.col}>
              <div className={styles.player_name}>{userName}</div>
              <div className={styles.player_email}>{email}</div>
            </div>
          </div>
        </div>
        <div className={styles.divider}></div>
        <MenuItem
          onClick={goToAccount}
          style={{
            paddingLeft: '1.5rem',
            paddingRight: '1.5rem'
          }}>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            stroke='currentColor'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
            className={styles.feather}>
            <circle cx='12' cy='12' r='3'></circle>
            <path d='M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z'></path>
          </svg>
          Account
        </MenuItem>
        <MenuItem
          onClick={Logout}
          style={{
            paddingLeft: '1.5rem',
            paddingRight: '1.5rem'
          }}>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            stroke='currentColor'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
            className={styles.feather}>
            <path d='M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4'></path>
            <polyline points='16 17 21 12 16 7'></polyline>
            <line x1='21' y1='12' x2='9' y2='12'></line>
          </svg>
          Logout
        </MenuItem>
      </Menu>
    </div>
  );
};

export default React.memo(BasicMenu);
