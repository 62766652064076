import { CSSProperties } from 'react';
import { animated, useSpring } from 'react-spring';
import { Paper } from '@mui/material';
import { useAppSelector } from 'hooks';
import { toLocaleStringNumber } from 'utils/functions';

type AmountWonPropType = {
  amount?: number;
};

export const AmountWon = ({ amount }: AmountWonPropType) => {
  const showAmountWon = useSpring({
    from: { top: '0px', opacity: 0 },
    to: { top: `-70px`, opacity: 1 },
    config: { mass: 5, tension: 800, friction: 50 }
  });
  const tableType = useAppSelector<any>((state) => state.currentTable.game?.tabletype);

  return (
    <animated.div style={{ ...showAmountWon, ...msgContainer }}>
      <Paper sx={{ ...textStyle }} elevation={6}>
        + {tableType === 's' && <span style={dollarSignStyle}>$</span>}
        {toLocaleStringNumber(amount)}
      </Paper>
    </animated.div>
  );
};

const msgContainer: CSSProperties = {
  position: 'absolute',
  display: 'flex',
  justifyContent: 'center',
  width: '100%'
};

const textStyle: CSSProperties = {
  borderRadius: '3rem',
  textAlign: 'center',
  fontSize: '1rem',
  color: '#e1e1e2',
  padding: '0.5rem 1rem',
  whiteSpace: 'nowrap',
  fontWeight: '400',
  textTransform: 'uppercase',
  display: 'flex',
  backgroundColor: '#363D41'
};

const dollarSignStyle: CSSProperties = {
  color: '#E98C26',
  fontWeight: '800',
  marginLeft: '8px',
  marginRight: '2px'
};
