import { CSSProperties, useEffect } from 'react';
import { animated, useSpring } from 'react-spring';
import { Paper } from '@mui/material';
import { audioPath } from 'constants/audioConstants';
import { SEAT_CONTAINER_WIDTH } from 'constants/gameConstants';
import useSound from 'use-sound';
import { currencyFormatter } from 'utils/functions';

type BountyWinnerPropType = {
  totalHandBounty: number;
  backgroundColor?: string;
};

export const BountyWinner = ({
  totalHandBounty,
  backgroundColor = '#628B48'
}: BountyWinnerPropType) => {
  const showBountyWinner = useSpring({
    from: { top: '0px', opacity: 0, left: '0px' },
    to: {
      top: `${10 + SEAT_CONTAINER_WIDTH}px`,
      opacity: 1,
      left: `${50 + SEAT_CONTAINER_WIDTH}px`
    },
    config: { mass: 5, tension: 1000, friction: 50 }
  });
  const [playPlayerWin] = useSound(audioPath.playerWins);
  useEffect(() => {
    playPlayerWin();
  }, [playPlayerWin]);

  return (
    <animated.div style={{ ...showBountyWinner, ...msgContainer }}>
      <Paper sx={{ ...textStyle, backgroundColor }} elevation={6}>
        Bounty +{currencyFormatter(totalHandBounty)}
      </Paper>
    </animated.div>
  );
};

const msgContainer: CSSProperties = {
  position: 'absolute',
  display: 'flex',
  justifyContent: 'center',
  width: '100%'
};

const textStyle: CSSProperties = {
  borderRadius: '3rem',
  textAlign: 'center',
  fontSize: '1rem',
  color: '#e1e1e2',
  padding: '0.5rem 1rem',
  whiteSpace: 'nowrap',
  fontWeight: '800',
  textTransform: 'uppercase',
  display: 'flex'
};
