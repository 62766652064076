import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Menu from '@mui/material/Menu';
import Slider from '@mui/material/Slider';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import { hiJackColors } from 'constants/colorConstants';
import LobbyContext from 'contexts/LobbyContext';
import { useAppDispatch, useAppSelector } from 'hooks';
import { updateSelectedFilters } from 'reducers/lobby';

import { GameMode } from './LobbyTable';

const TableFiltersMenu: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const {
    stakeValue,
    setStakeValue,
    maxPLValue,
    setMaxPLValue,
    minBuyInValue,
    setMinBuyInValue,
    isHideEmptyGame,
    setIsHideEmptyGame
  } = useContext(LobbyContext);

  const dispatch = useAppDispatch();
  const { texasCashGames, omahaCashGames, selectedGameMode } = useAppSelector(
    (state) => state.lobby
  );
  const [cashGames, setCashGames] = useState<any[]>([]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsHideEmptyGame(event.target.checked);
  };
  const handleChangeStake = (event: Event) => {
    setStakeValue((event?.target as any).value);
  };
  const handleChangeMaxPL = (event: Event) => {
    setMaxPLValue((event?.target as any).value);
  };

  const handleChangeBuyIn = (event: Event) => {
    setMinBuyInValue((event?.target as any).value);
  };

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // Find the lowest sb value
  const lowestSBGame = cashGames.reduce((lowestGame, currentGame) => {
    const currentSBValue = parseFloat(currentGame?.sb);

    if (currentSBValue < parseFloat(lowestGame?.sb)) {
      return currentGame;
    } else {
      return lowestGame;
    }
  }, cashGames[0]);

  const lowestMinBuyIn = cashGames.reduce((highestGame, currentGame) => {
    const currentTableLowValue = parseFloat(currentGame?.tablelow);
    const highestTableLowValue = parseFloat(highestGame?.tablelow);

    return currentTableLowValue < highestTableLowValue ? currentGame : highestGame;
  }, cashGames[0]);

  const highestSBGame = cashGames.reduce((highestGame, currentGame) => {
    const currentSBValue = parseFloat(currentGame?.bb);

    if (currentSBValue > parseFloat(highestGame?.bb)) {
      return currentGame;
    } else {
      return highestGame;
    }
  }, cashGames[0]);

  const lowestMaxPlayer = cashGames.reduce((highestGame, currentGame) => {
    const currentSBValue = currentGame?.seats;

    if (currentSBValue < highestGame?.seats) {
      return currentGame;
    } else {
      return highestGame;
    }
  }, cashGames[0]);

  const highestMaxPlayer = cashGames.reduce((highestGame, currentGame) => {
    const currentSBValue = currentGame?.seats;

    if (currentSBValue > highestGame?.seats) {
      return currentGame;
    } else {
      return highestGame;
    }
  }, cashGames[0]);

  const highestMinBuyIn = cashGames.reduce((highestGame, currentGame) => {
    const currentTableLowValue = parseFloat(currentGame?.tablelow);
    const highestTableLowValue = parseFloat(highestGame?.tablelow);

    return currentTableLowValue > highestTableLowValue ? currentGame : highestGame;
  }, cashGames[0]);

  const lowestStake = cashGames.reduce((lowestGame, currentGame) => {
    const tableLowCG = currentGame.sb;
    const tableLowLG = lowestGame.sb;

    return tableLowCG < tableLowLG ? currentGame : lowestGame;
  }, cashGames[0]);

  const highestStake = cashGames.reduce((highestGame, currentGame) => {
    const tableLowCG = currentGame.bb;
    const tableLowHG = highestGame.bb;

    return tableLowCG > tableLowHG ? currentGame : highestGame;
  }, cashGames[0]);

  const sliderStepStake =
    Number.isInteger(lowestSBGame?.sb) && Number.isInteger(highestSBGame?.bb) ? 1 : 0.01;

  const handleApply = () => {
    const stakeExist = stakeValue[0] !== 0 && stakeValue[1] !== 0;
    const maxPlayerExist = maxPLValue[0] !== 0 && maxPLValue[1] !== 0;
    const minBuyInExist = minBuyInValue[0] !== 0 && minBuyInValue[1] !== 0;
    const data = {
      stake: stakeExist ? stakeValue : null,
      maxPlayer: maxPlayerExist ? maxPLValue : null,
      minBuyIn: minBuyInExist ? minBuyInValue : null,
      isHideEmptyGames: isHideEmptyGame
    };
    dispatch(updateSelectedFilters(data));
  };

  useEffect(() => {
    const cashGameList = selectedGameMode === GameMode.texas ? texasCashGames : omahaCashGames;
    setCashGames(cashGameList);
  }, [selectedGameMode, texasCashGames, omahaCashGames]);

  return (
    <MainFilterWrapper>
      <BtnFilter
        id='basic-button'
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}>
        <span className='g-font-outlined'>filter_list</span>
        Filters
      </BtnFilter>
      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        PaperProps={{
          style: {
            borderRadius: '8px',
            minWidth: '13rem',
            backgroundColor: hiJackColors.gunMetal
          }
        }}>
        <ItemWrapper>
          <Typography
            id='input-slider'
            gutterBottom
            style={{ color: hiJackColors.white, fontSize: 14 }}>
            Stakes
          </Typography>
          <LabelWrapper>
            <Typography gutterBottom style={{ color: hiJackColors.white, fontSize: 10 }}>
              {lowestStake?.stakes}
            </Typography>

            <Typography gutterBottom style={{ color: hiJackColors.white, fontSize: 10 }}>
              {highestStake?.stakes}
            </Typography>
          </LabelWrapper>
          <Grid container spacing={2} alignItems='center'>
            <Grid item xs>
              <HiJackSlider
                size='small'
                defaultValue={parseFloat(lowestStake?.sb)}
                value={stakeValue}
                min={parseFloat(lowestStake?.sb)}
                max={parseFloat(highestStake?.bb)}
                step={sliderStepStake}
                onChange={handleChangeStake}
                valueLabelDisplay='auto'
              />
            </Grid>
          </Grid>
        </ItemWrapper>
        <ItemWrapper>
          <Typography
            id='input-slider'
            gutterBottom
            style={{ color: hiJackColors.white, fontSize: 14 }}>
            Max no. of players/table
          </Typography>
          <LabelWrapper>
            <Typography gutterBottom style={{ color: hiJackColors.white, fontSize: 10 }}>
              {lowestMaxPlayer?.seats}
            </Typography>

            <Typography gutterBottom style={{ color: hiJackColors.white, fontSize: 10 }}>
              {highestMaxPlayer?.seats}
            </Typography>
          </LabelWrapper>
          <Grid container spacing={2} alignItems='center'>
            <Grid item xs>
              <HiJackSlider
                size='small'
                value={maxPLValue}
                defaultValue={lowestMaxPlayer?.seats}
                min={lowestMaxPlayer?.seats}
                max={highestMaxPlayer?.seats}
                onChange={handleChangeMaxPL}
                valueLabelDisplay='auto'
              />
            </Grid>
          </Grid>
        </ItemWrapper>
        <ItemWrapper>
          <Typography
            id='input-slider'
            gutterBottom
            style={{ color: hiJackColors.white, fontSize: 14 }}>
            Minimum buy-in
          </Typography>
          <LabelWrapper>
            <Typography gutterBottom style={{ color: hiJackColors.white, fontSize: 10 }}>
              {lowestMinBuyIn?.tablelowDisplay}
            </Typography>

            <Typography gutterBottom style={{ color: hiJackColors.white, fontSize: 10 }}>
              {highestMinBuyIn?.tablelowDisplay}
            </Typography>
          </LabelWrapper>
          <Grid container spacing={2} alignItems='center'>
            <Grid item xs>
              <HiJackSlider
                size='small'
                defaultValue={parseFloat(lowestMinBuyIn?.tablelow)}
                min={parseFloat(lowestMinBuyIn?.tablelow)}
                max={parseFloat(highestMinBuyIn?.tablelow)}
                value={minBuyInValue}
                onChange={handleChangeBuyIn}
                valueLabelDisplay='auto'
              />
            </Grid>
          </Grid>
        </ItemWrapper>
        <MSwitchWrapper>
          <Typography
            id='input-slider'
            gutterBottom
            style={{ color: hiJackColors.white, fontSize: 14 }}>
            Hide Empty Games
          </Typography>
          <SubSwitchWrapper>
            <HiJackSwitch
              checked={isHideEmptyGame}
              onChange={handleChange}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          </SubSwitchWrapper>
        </MSwitchWrapper>
        <BtnWrapper>
          <ApplyBtn onClick={handleApply}>Apply</ApplyBtn>
        </BtnWrapper>
      </Menu>
    </MainFilterWrapper>
  );
};

const MainFilterWrapper = styled(Box)({
  height: '100%',
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  justifyContent: 'center',
  alignItems: 'center'
});

const LabelWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  justifyContent: 'space-between',
  alignItems: 'center'
});

const BtnWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  justifyContent: 'flex-end',
  flexWrap: 'nowrap',
  padding: 8
});

const ApplyBtn = styled(Button)({
  width: 80,
  height: 35,
  color: hiJackColors.white,
  backgroundColor: hiJackColors.orange,
  fontSize: 12,
  fontWeight: 600,
  textTransform: 'none',
  '&:hover, &:focus': {
    backgroundColor: hiJackColors.midDarkOrange
  }
});

const BtnFilter = styled(Button)({
  height: 30,
  borderRadius: 8,
  backgroundColor: hiJackColors.cadetBlue,
  textTransform: 'none',
  color: hiJackColors.white
});

const ItemWrapper = styled(Box)({
  width: 250,
  padding: 15,
  height: 85
});

const MSwitchWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  justifyContent: 'space-around',
  alignItems: 'center'
});

const SubSwitchWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'row'
});

const HiJackSwitch = styled(Switch)({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: hiJackColors.white,
    '&:hover': {
      backgroundColor: hiJackColors.orange
    }
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: hiJackColors.orange
  }
});

const HiJackSlider = styled(Slider)({
  color: hiJackColors.orange,
  width: '100%',
  height: 5,
  '& .MuiSlider-track': {
    border: 'none'
  },
  '& .MuiSlider-thumb': {
    height: 15,
    width: 15,
    backgroundColor: '#B46D4A',
    border: '2px solid currentColor',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit'
    },
    '&:before': {
      display: 'none'
    }
  },
  '& .MuiSlider-valueLabel': {
    lineHeight: 1.2,
    fontSize: 12,
    background: 'unset',
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: '50% 50% 50% 0',
    backgroundColor: hiJackColors.orange,
    transformOrigin: 'bottom left',
    transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
    '&:before': { display: 'none' },
    '&.MuiSlider-valueLabelOpen': {
      transform: 'translate(50%, -100%) rotate(-45deg) scale(1)'
    },
    '& > *': {
      transform: 'rotate(45deg)'
    }
  }
});

export default React.memo(TableFiltersMenu);
