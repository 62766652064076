import React, { useContext } from 'react';
import GameContext from 'contexts/GameContext';
import { useAppSelector } from 'hooks';
import { getCardValue } from 'utils/functions';

import { SeatContainer } from 'components/Seat/SeatContainer';

import { Position } from './OvalTable';

type SeatsPropertyType = {
  guid: any;
};

export const getSeatPosition = (
  seatPositions: Position[],
  players: any,
  seatId: number,
  currentPlayerSeatId: number
) => {
  const appSeats = players?.slice(1);
  const seatCount = appSeats.length;

  const originalPositionIndex = currentPlayerSeatId < 1 ? seatId - 1 : seatId - currentPlayerSeatId;

  let positionIndex = originalPositionIndex;

  if (positionIndex < 0) {
    positionIndex = seatCount + positionIndex;
  }

  const seatPositionLength = ((seatPositions.length / seatCount) * positionIndex) | 0;
  return seatPositions[seatPositionLength];
};

const Seats = ({ guid }: SeatsPropertyType) => {
  const game = useAppSelector<any>((state) => state.currentTable.game);
  const players = useAppSelector<any>((state) => state.currentTable.players);
  const currentPlayerSeatId = useAppSelector((state) => state.currentTable.currentPlayer.seatId);

  const { seatPositions } = useContext(GameContext);
  // const { gameType } = useContext(GlobalContext);

  const appSeats = players?.slice(1);
  const seatCount = appSeats.length;

  return (
    <>
      {appSeats.map((seat: any, index: any) => {
        const seatId = index + 1;

        const originalPositionIndex =
          currentPlayerSeatId < 1 ? index : seatId - currentPlayerSeatId;

        let positionIndex = originalPositionIndex;
        if (positionIndex < 0) {
          positionIndex = seatCount + positionIndex;
        }

        const seatPositionLength = ((seatPositions.length / seatCount) * positionIndex) | 0;
        const originalSeatPositionLength =
          ((seatPositions.length / seatCount) * originalPositionIndex) | 0;
        const postion = seatPositions[seatPositionLength];

        const cards: any = [
          game[`p${seatId}card1`],
          game[`p${seatId}card2`],
          game[`p${seatId}card3`],
          game[`p${seatId}card4`],
          game[`p${seatId}card5`]
        ]
          .filter((f) => f)
          .sort((a, b) => {
            if (postion?.isLeft) {
              return getCardValue(a) - getCardValue(b);
            }

            return getCardValue(b) - getCardValue(a);
          });

        return (
          <SeatContainer
            index={index}
            position={postion}
            currentSeatPositionLength={originalSeatPositionLength}
            currentPlayerSeatId={currentPlayerSeatId}
            key={index}
            seat={seat as any}
            guid={guid}
            isFaceCardUp={seat.GUID === guid}
            seatId={seatId}
            cards={cards}
            seatPositionsLength={seatPositions.length}
          />
        );
      })}
    </>
  );
};

export default Seats;
