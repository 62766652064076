import React, { CSSProperties, useContext } from 'react';
import { BET_PREFIX, TABLE_TYPE_SITNGO, TABLE_TYPE_TOURNAMENT } from 'constants/gameConstants';
import ScaleContext from 'contexts/ScaleContext';
import Decimal from 'decimal.js';
import { useAppSelector } from 'hooks';
import { ZIndex } from 'styles';
import {
  currencyFormatter,
  getCardValue,
  parseOverBet,
  toLocaleStringNumber
} from 'utils/functions';

import XSmallCard from './Card/XSmallCard';

import 'App.scss';

const PlayerTables = () => {
  const playerGames = useAppSelector((state) => state.playerGames);
  const tableType = useAppSelector<any>((state) => state.currentTable.game?.tabletype);
  const { scale } = useContext(ScaleContext);

  return (
    <div style={{ ...BaseStyle, transform: `scale(${scale})`, transformOrigin: 'bottom right' }}>
      {playerGames.map((gameInfo: any, index: number) => {
        const cards = [
          gameInfo?.card1,
          gameInfo?.card2,
          gameInfo?.card3,
          gameInfo?.card4,
          gameInfo?.card5
        ]
          .filter((card) => card?.rank && card?.suit)
          .sort(
            (card1, card2) =>
              getCardValue(`${card2.rank}${card2.suit}`) -
              getCardValue(`${card1.rank}${card1.suit}`)
          );

        const tableOverBet = parseOverBet(gameInfo?.overbet);
        const totalPot = Decimal.sub(gameInfo?.pot ?? 0, tableOverBet.bet)
          .toDP(2)
          .toNumber();

        return (
          <div key={index} style={{ marginTop: '2%' }}>
            <div style={playerTableStyle}>
              <div style={{ width: '100%' }}>
                <div
                  style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    padding: '8px',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}>
                  <div
                    style={{
                      backgroundColor: '#383838',
                      height: '100%',
                      width: '225px',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignItems: 'center',
                      gap: '3px'
                    }}>
                    {cards.map((card, index) => (
                      <XSmallCard
                        key={`${card}-${index}`}
                        {...card}
                        width={40}
                        height={60}
                        fontSize={24}
                      />
                    ))}
                  </div>
                </div>
              </div>
              <div style={playerGamesInfoStyle}>
                <div style={{ fontSize: '14px' }}>
                  Pot:{' '}
                  {tableType === TABLE_TYPE_TOURNAMENT || tableType === TABLE_TYPE_SITNGO
                    ? toLocaleStringNumber(+totalPot)
                    : currencyFormatter(totalPot)}
                </div>
                <div style={yourTurnStyle}>
                  {gameInfo?.myTurn ? (
                    <div style={myTurnStyles}>Your turn!</div>
                  ) : gameInfo?.bet?.substring(0, 1) === BET_PREFIX.FOLD ? (
                    <div style={foldSitoutStyle}>Folded</div>
                  ) : gameInfo?.status === 1 ? (
                    <div style={foldSitoutStyle}>Sat out</div>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default PlayerTables;

const BaseStyle: CSSProperties = {
  position: 'fixed',
  bottom: '26px',
  right: '16px'
};

const myTurnStyles: React.CSSProperties = {
  boxShadow: '0px 0px 2.59854px #E98C26, 0px 0px 8.5912px #E98C26 ',
  background: '#E98C26',
  padding: '.2rem 0',
  borderRadius: '15px',
  height: '25px'
};

const foldSitoutStyle: React.CSSProperties = {
  background: '#8C3041',
  padding: '5px 0',
  borderRadius: '15px',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '25px'
};

const yourTurnStyle: React.CSSProperties = {
  background: '#383838',
  borderRadius: '15px',
  textAlign: 'center',
  fontSize: '0.75rem',
  minHeight: '25px',
  width: '100%'
};

const playerTableStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-evenly',
  gap: '1rem',
  marginBottom: '1rem',
  backgroundColor: '#20262d',
  borderRadius: '7.6087px',
  padding: '0.5rem 1rem',
  height: '100px',
  minWidth: '12rem',
  zIndex: ZIndex.action
};

const playerGamesInfoStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  width: '100%',
  alignItems: 'center',
  margin: '0.3rem 0'
};
