import * as React from 'react';
import styled from '@emotion/styled';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Logo from 'assets/images/hjlogo.png';
import { useAppSelector } from 'hooks';
import { currencyFormatter } from 'utils/functions';
import { FRONT_OFFICE_LOCAL_URL } from 'utils/urls';

import AvatarDropdown from 'components/AvatarDropdown/AvatarDropdown';
import NotificationDropdown from 'components/NotificationDropdown/NotificationDropdown';

import { hiJackColors } from './../../../constants/colorConstants';
import { hiJackLabels } from './../../../constants/LabelConstants';

interface StyledTabProps {
  label: string;
}

const HeaderAppBar = () => {
  const displayName = useAppSelector((state) => state.user.displayName);
  const userBalance = useAppSelector((state) => state.user.playerBankAmount);
  const userChargeBalance = useAppSelector((state) => state.user.playerChargeBalance);
  const avatar = useAppSelector((state) => state.user.playerAvatar);
  const email = useAppSelector((state) => state.user.playerEmail);

  const [value, setValue] = React.useState(0);
  const cashierPath = 'hijack/cashier/deposit';
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    const cashier =
      window.location.hostname === 'hijack.local'
        ? `${FRONT_OFFICE_LOCAL_URL}/${cashierPath}`
        : 'https://account.' +
          window.location.hostname.split('.')[1] +
          '.' +
          window.location.hostname.split('.')[2] +
          `/${cashierPath}`;

    setValue(newValue);
    if (newValue === 0) {
      window.location.href = '/';
    } else if (newValue === 1) {
      window.location.href = cashier;
    }
  };

  return (
    <MainAppBar position='static'>
      <MainWrapper>
        <MainToolBar disableGutters>
          <a href='' className='logo_link'>
            <img src={Logo} alt='' style={{ maxWidth: '100px' }} />
          </a>

          <AppActionBarWrapper>
            <MenuWrapper>
              <Box sx={{ maxWidth: { xs: 320, sm: 480 } }}>
                <StyledTabs
                  value={value}
                  onChange={handleChange}
                  variant='scrollable'
                  scrollButtons
                  allowScrollButtonsMobile>
                  <StyledTab label={hiJackLabels.lobby.label} />
                  <StyledTab label={hiJackLabels.lobby.cashier} />
                  <NotificationDropdown />
                </StyledTabs>
              </Box>
            </MenuWrapper>

            <DividerStyle orientation='vertical' flexItem />
            <BalanceWrapper>
              <BalanceContent>
                <label>Cash:</label>
                <label>
                  {currencyFormatter(userBalance)} |{' '}
                  <Tooltip title='The Promo balance is applicable solely for cash game charges'>
                    <StyleChargeBalance>
                      <label style={{ marginRight: '8px' }}>Promo:</label>{' '}
                      {currencyFormatter(userChargeBalance)}
                    </StyleChargeBalance>
                  </Tooltip>
                </label>
              </BalanceContent>
            </BalanceWrapper>
            <AvatarDropdown userName={displayName} email={email} avatar={avatar} />
          </AppActionBarWrapper>
        </MainToolBar>
      </MainWrapper>
    </MainAppBar>
  );
};

const DividerStyle = styled(Divider)({
  height: 35,
  alignSelf: 'center'
});

const MainWrapper = styled('div')({
  width: '100%',
  padding: '0rem 1rem'
});

const MainAppBar = styled(AppBar)({
  backgroundColor: hiJackColors.midnightBlue
});

const MainToolBar = styled(Toolbar)({
  width: '100%',
  placeContent: 'space-between'
});

const AppActionBarWrapper = styled(Box)({
  display: 'flex',
  flexGrow: 0
});

const BalanceWrapper = styled(Box)({
  textAlign: 'center',
  alignSelf: 'center',
  marginLeft: 20
});

const BalanceContent = styled(Box)({
  textAlign: 'center',
  alignSelf: 'center',
  display: 'flex',
  columnGap: 20,
  fontFamily: 'Outfit',
  fontSize: '14px'
});

const MenuWrapper = styled(Box)({
  textAlign: 'center',
  alignSelf: 'center'
});

const StyledTabs = styled(Tabs)({
  '& .MuiTabs-indicator': {
    backgroundColor: hiJackColors.orange
  }
});

const StyledTab = styled((props: StyledTabProps) => <Tab disableRipple {...props} />)(() => ({
  textTransform: 'none',
  color: 'rgba(255, 255, 255, 0.7)',
  '&.Mui-selected': {
    color: '#fff'
  },
  '&.Mui-focusVisible': {
    backgroundColor: 'rgba(100, 95, 228, 0.32)'
  },
  fontFamily: 'Outfit',
  fonSize: '12px !important'
}));

const StyleChargeBalance = styled('span')({
  color: 'orange'
});

export default HeaderAppBar;
