import { CSSProperties, useEffect } from 'react';
import { animated, useSpring } from 'react-spring';
import { audioPath } from 'constants/audioConstants';
import { SEAT_HEIGHT, SEAT_WIDTH } from 'constants/gameConstants';
import { SPOTLIGHT_HEIGHT, SPOTLIGHT_WIDTH } from 'constants/gameConstants';
import { useAppSelector } from 'hooks';
import useSound from 'use-sound';

type SpotlightType = {
  position: any;
  seatId: number;
};

const Spotlight = ({ position, seatId }: SpotlightType) => {
  const [playPlayersTurn] = useSound(audioPath.playersTurn);
  const currentPlayerSeatId = useAppSelector((state) => state.currentTable.currentPlayer.seatId);
  const spotlightHeight = SPOTLIGHT_HEIGHT;

  const spotlightAnimation = useSpring({
    from: {
      heigth: `${spotlightHeight - 100}px`,
      opacity: 0
    },
    to: {
      height: `${spotlightHeight}px`,
      opacity: 0.8
    },
    config: { mass: 5, tension: 1000, friction: 50, duration: 500 }
  });

  useEffect(() => {
    if (currentPlayerSeatId === seatId) {
      playPlayersTurn();
    }
  }, [playPlayersTurn]);

  return (
    <>
      {!!position && (
        <animated.div
          style={{
            ...spotlightAnimation,
            ...spotlightStyle,
            top: -(SPOTLIGHT_HEIGHT - SEAT_HEIGHT) / 2 + position.spotlightOffsetY,
            left: -(SPOTLIGHT_WIDTH - SEAT_WIDTH) / 2,
            transform: `perspective(6px) rotate(${position.angle - 90}deg)`
          }}
        ></animated.div>
      )}
    </>
  );
};

const spotlightStyle: CSSProperties = {
  position: 'absolute',
  width: `${SPOTLIGHT_WIDTH}px`,
  background: 'linear-gradient(0deg, rgba(255,255,255,0.0) 0%, rgba(255,255,255,1) 100%)',
  filter: 'blur(10px)',
  borderRadius: '400px',
  clipPath: 'polygon(30% 0%, 70% 0%, 100% 100%, 0% 100%)',
  zIndex: 0
};

export default Spotlight;
