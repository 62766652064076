import { CSSProperties, useContext } from 'react';
import { createTheme, ThemeProvider } from '@mui/material';
import ScaleContext from 'contexts/ScaleContext';
import { ZIndex } from 'styles';

const FreshDeskLink = () => {
  const { scale } = useContext(ScaleContext);

  return (
    <div
      id='BugButton'
      className='BugButton'
      style={{ ...bugButtonStyle, transform: `scale(${scale})`, transformOrigin: 'bottom right' }}
    >
      <ThemeProvider theme={theme}>
      </ThemeProvider>
    </div>
  );
};

export default FreshDeskLink;

const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '5px 5px 0px 0px',
          fontSize: '12px'
        }
      }
    }
  }
});

const bugButtonStyle: CSSProperties = {
  position: 'fixed',
  transition: '0.3s',
  bottom: '0px',
  right: '30px',
  zIndex: ZIndex.widget
};
