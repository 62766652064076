import React, { CSSProperties, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { animated, useSpring } from 'react-spring';
import { audioPath } from 'constants/audioConstants';
import { BET_HEIGHT, BET_WIDTH, TABLE_PADDING_X, TABLE_PADDING_Y } from 'constants/gameConstants';
import GameContext from 'contexts/GameContext';
import { useAppSelector } from 'hooks';
import _ from 'lodash';
import useSound from 'use-sound';
import { numberFormatter } from 'utils/functions';

import Chips, { CHIPS_DELAY } from './Chips';
import { DEALER_DELAY } from './DealerButton';
import { Position } from './OvalTable';

type BetPropType = {
  position: Position;
  seat: any;
  seatId: number;
};

const Bet = ({ position, seat, seatId }: BetPropType) => {
  const elementRef = useRef<HTMLDivElement>(null);
  const hand = useAppSelector((state) => state.currentTable.game.hand);
  const tableOverBet = useAppSelector((state) => state.currentTable.game.tableOverBet);
  const [isShow, setIsShow] = useState<boolean>(false);
  const game = useAppSelector<any>((state) => state.currentTable.game);
  const action = game[`p${seatId}action`];
  const [playBetChips] = useSound(audioPath.betChips);
  const { potPosition } = useContext(GameContext);
  const [playerBet, setPlayerBet] = useState(0);
  const [, setIsAnimated] = useState(false);
  const [isStartAnimate, setIsStartAnimate] = useState(false);
  const [playChipsToPot] = useSound(audioPath.chipsToPot);
  /* Animate the float number */
  const [newPlayerBet, setNewPlayerBet] = useState(playerBet);

  const tableType = useAppSelector<any>((state) => state.currentTable.game.tabletype);

  useEffect(() => {
    const betValue = +seat.betDisplay;

    if (seat.ID) {
      if (betValue > 0) {
        if (hand === '3' || hand === '4') {
          setTimeout(() => {
            setIsAnimated(false);
            setIsShow(true);
          }, DEALER_DELAY);
        } else {
          setIsAnimated(false);
          setIsShow(true);
        }
        setIsStartAnimate(false);
        setPlayerBet(betValue);
      } else {
        setIsAnimated((prev) => {
          if (!prev) {
            setIsStartAnimate(true);
            playChipsToPot();
            setTimeout(() => {
              setIsShow(false);
            }, CHIPS_DELAY + 300);
          } else {
            setIsStartAnimate(false);
          }
          return true;
        });
        setPlayerBet(0);
      }
    } else {
      setIsShow(false);
      setPlayerBet(0);
    }
  }, [hand, tableOverBet, seat, seat.ID, seat.lbet, seatId]);

  const [animatedStyle, setAnimatedStyle] = useSpring(() => ({
    boxShadow: `0px 0px 0px 0px rgba(98,139,72,0)`,
    reset: true,
    config: {
      duration: 500,
      mass: 1,
      tension: 170,
      friction: 26
    }
  }));

  useSpring({
    number: newPlayerBet,
    config: {
      mass: 1,
      tension: 170,
      friction: 26,
      steps: 1.0
    }
  });

  const resetPlayerBetStyle = useCallback(
    _.debounce(() => {
      setAnimatedStyle.start({ boxShadow: `0px 0px 0px 0px rgba(98,139,72,0)` });
    }, 1000),
    []
  );

  useEffect(() => {
    if (isShow) {
      playBetChips();
    }
    setNewPlayerBet(playerBet);
    setAnimatedStyle.start({ boxShadow: `0px 0px 5px 8px rgba(55,137,18,1)` });
    resetPlayerBetStyle();
  }, [playChipsToPot, playerBet, resetPlayerBetStyle, setAnimatedStyle, isShow]);

  return (
    <>
      {isShow && (
        <>
          <div ref={elementRef} style={{ ...BaseStyle, ...position }}>
            <div style={BetContainerStyle}>
              <animated.span
                style={{
                  ...TextStyle,
                  ...animatedStyle,
                  padding:
                    position.isRight || (position.isTop && position.isCenter)
                      ? `0 19.2px 0 8px`
                      : `0 8px 0 19.2px`,
                  right: position.isRight || (position.isTop && position.isCenter) ? 16 : 'unset',
                  left: position.isLeft || (position.isBottom && position.isCenter) ? 16 : 'unset',
                  fontWeight: action === 'straddle' ? 'bolder' : '',
                  fontSize: action === 'straddle' ? '1.3rem' : ''
                }}>
                {(tableType !== 'm' && tableType !== 't' ? '$' : '') +
                  numberFormatter(newPlayerBet)}
              </animated.span>
            </div>
          </div>
          <Chips
            from={position}
            to={{
              left: potPosition.left - BET_WIDTH / 2 - TABLE_PADDING_X,
              top: potPosition.top - BET_HEIGHT / 2 - TABLE_PADDING_Y
            }}
            isStartAnimate={isStartAnimate}
          />
        </>
      )}
    </>
  );
};

export default Bet;

const BaseStyle: CSSProperties = {
  position: 'absolute',
  display: 'flex',
  zIndex: 1
};

const BetContainerStyle: CSSProperties = {
  position: 'relative',
  height: BET_HEIGHT,
  width: BET_WIDTH,
  top: 0,
  left: 0,
  borderRadius: '1.5rem',
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
  columnGap: '15%',
  zIndex: 1
};

const TextStyle: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  top: 0,
  left: 0,
  backgroundColor: '#c09687',
  fontSize: '1rem',
  color: '#191311',
  fontWeight: 500,
  height: '100%',
  borderRadius: '1.5rem',
  padding: '0 .5rem 0 1rem',
  zIndex: 1
};
