import React, { CSSProperties, useContext, useEffect, useState } from 'react';
import { animated, config, useSpring } from 'react-spring';
import { Avatar, Paper } from '@mui/material';
import {
  GLOW_OFF_OTHER_WINNERS_AVATAR,
  GLOW_OFF_WINNER_AVATAR,
  GLOW_ON_OTHER_WINNERS_AVATAR,
  GLOW_ON_WINNER_AVATAR,
  INSET_OTHER_WINNERS_AVATAR,
  INSET_WINNER_AVATAR
} from 'constants/gameConstants';
import GameContext from 'contexts/GameContext';
import { useAppSelector } from 'hooks';
import { has } from 'lodash';

type PlayerPropType = {
  avatar: string;
  seatId: number;
  currentPlayerSeatId: number;
  isCurrentWinner: boolean;
};

const Player = ({ seatId, avatar, currentPlayerSeatId, isCurrentWinner }: PlayerPropType) => {
  const { setOpenAddChips, isSatOut, setIsTimerOff, bustTime, setBustTime } =
    useContext(GameContext);

  const game = useAppSelector<any>((state) => state.currentTable.game);

  const [status, setStatus] = useState<string>('');
  const [isShowStatus, setIsShowStatus] = useState<boolean>(false);

  const insetShadowColor = isCurrentWinner ? INSET_WINNER_AVATAR : INSET_OTHER_WINNERS_AVATAR;
  const shadowColorHide = isCurrentWinner ? GLOW_OFF_WINNER_AVATAR : GLOW_OFF_OTHER_WINNERS_AVATAR;
  const shadowColorShow = isCurrentWinner ? GLOW_ON_WINNER_AVATAR : GLOW_ON_OTHER_WINNERS_AVATAR;

  const glowingAnimation = useSpring({
    from: {
      boxShadow: `inset 0px 0px 0px 0px ${insetShadowColor}, 0px 0px 0px 0px ${shadowColorHide}`
    },
    to: isCurrentWinner
      ? {
          boxShadow: `inset 0px 0px 0px 5px ${insetShadowColor}, 0px 0px 10px 10px ${shadowColorShow}`
        }
      : {
          boxShadow: `inset 0px 0px 0px 0px ${insetShadowColor}, 0px 0px 0px 0px ${shadowColorHide}`
        },
    config: config.wobbly
  });

  useEffect(() => {
    /*
     *   Old reference https://github.com/Hijack-Poker/game/blob/main/texas/texasPlayer.js line 29
     *
     *   Player Status:
     *   0 = Seated
     *   1 = Sitout
     *   2 = Leave Game -- will kick the player after the game finishes
     *   3 = Show Cards
     *   4 = Join - wait for the button
     *   5 = Join - Buy the button
     */
    switch (game['p' + seatId + 'status']) {
      case 0:
        // Seated
        setIsShowStatus(false);
        setStatus('');
        break;
      case 1:
        // Sitout
        setIsShowStatus(true);
        setStatus('SITTING OUT');
        break;
      case 6:
        // Sitout
        setIsShowStatus(seatId === currentPlayerSeatId);
        setStatus('SITTING OUT');
        break;
      case 2:
        // Leave Game -- will kick the player after the game finishes
        setIsShowStatus(true);
        setStatus('STANDING UP');
        break;
      case 8:
        setIsShowStatus(true);
        setStatus('SITTING OUT');
        break;
      case 3:
        // Show Cards
        setStatus('');
        break;
      case 4:
        // Join - Buy the button
        setIsShowStatus(true);
        setStatus('JOINING');
        break;
      case 5:
        // Join - wait for the button
        setIsShowStatus(true);
        setStatus('JOINING');
        break;
      case 10:
      case 17:
      case 18:
        // Exit Table
        setIsShowStatus(true);
        setStatus('LEFT GAME');
        break;
      case 12:
        if (
          currentPlayerSeatId === seatId &&
          game['hand'] === '' &&
          game['languageKey'] === 'GAME_MSG_BUSTED_PAUSE' &&
          (game['bust']?.pauseStartTime > bustTime || !has(game['bust'], 'pauseStartTime'))
        ) {
          setOpenAddChips(true);
          if (has(game['bust'], 'pauseStartTime')) {
            setIsTimerOff(true);
            setBustTime(game['bust']['pauseStartTime']);
          }
        }
        break;
      default:
        // console.log(
        //   "game",
        //   "Unknown player status: " +
        //     game["p" + seatId + "status"] +
        //     "! This case has not yet been accounted for!"
        // );
        break;
    }
  }, [game, seatId, isSatOut]);

  return (
    <Paper
      style={{
        position: 'relative',
        height: '100%',
        width: '100%',
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
      className='avatar-container'>
      <Avatar sx={avatarStyle} src={avatar}></Avatar>
      <animated.div
        style={{
          position: 'absolute',
          borderRadius: '50%',
          width: '100%',
          height: '100%',
          ...glowingAnimation
        }}></animated.div>
      {isShowStatus && (
        <>
          <div style={coverStyle}></div>
          <div style={coverTextStyle}>{status}</div>
        </>
      )}
    </Paper>
  );
};

export default Player;

const baseStyle: CSSProperties = {
  position: 'absolute',
  height: '100%',
  width: '100%',
  borderRadius: '50%'
};

const avatarStyle: CSSProperties = { ...baseStyle };

const coverStyle: CSSProperties = {
  ...baseStyle,
  backgroundColor: 'black',
  opacity: 0.75,
  transition: 'all 1s ease'
};

const coverTextStyle: CSSProperties = {
  ...baseStyle,
  fontSize: '.70rem',
  // letterSpacing: "0.2em",
  color: '#FFF',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontWeight: 600,
  textAlign: 'center'
};
