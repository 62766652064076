import { SEAT_TIMER_SIZE } from 'constants/gameConstants';
import { useAppSelector } from 'hooks';
import { isHighWinner, isLowWinner, isWinner, playerPotWin } from 'utils/functions';
import { APP_DOMAIN } from 'utils/urls';

import SeatTimer from 'components/Timer/SeatTimer';

import { AmountWon } from './AmountWon';
import { BountyWinner } from './BountyWinner';
import { Cash } from './Cash';
import ChargeContainer from './ChargeContainer';
import Player from './Player';
import { WinnerMsg } from './WinnerMsg';

import 'components/Seat/PlayerCard.scss';
import 'components/Seat/BetBox.scss';
import 'components/Seat/SeatWithPlayer.scss';

type SeatWithPlayerPropType = {
  name: string;
  player: any;
  bet: any;
  stack: string;
  turn: boolean;
  avatar: string;
  seatId: number;
  currentPlayerSeatId: number;
  action: string;
  isFaceCardUp: boolean;
  totalBounty: number;
  totalHandBounty: number;
};

export const SeatWithPlayer = ({
  stack,
  avatar,
  seatId,
  currentPlayerSeatId,
  isFaceCardUp,
  // totalBounty,
  totalHandBounty
}: SeatWithPlayerPropType) => {
  const mediaUrl = `https://media.${APP_DOMAIN}/images/`;

  const game = useAppSelector<any>((state) => state.currentTable.game);
  const { pots, showdown } = game;
  const players = useAppSelector<any>((state) => state.currentTable.players);
  const startTurn = useAppSelector((state) => state.currentTable.gameState.startTurn);
  const chargedFlag = useAppSelector((state) => state.user.playerCharged);
  const hand = useAppSelector((state) => state.currentTable.game.hand);
  const isMultiRunActive = useAppSelector(
    (state) => state.currentTable.currentPlayer.isMultiRunActive
  );
  const isCurrentWinner = isWinner(pots, showdown, seatId);
  const isCurrentHighWinner = isHighWinner(pots, showdown, seatId);
  const isCurrentLowWinner = isLowWinner(pots, showdown, seatId);

  const timerSpeed = useAppSelector((state) => state.currentTable.game.speed);
  const time = useAppSelector((state) => state.currentTable.game.time);
  const lastmove = useAppSelector((state) => state.currentTable.game.lastmove);
  const languageKey = useAppSelector((state) => state.currentTable.game.languageKey);
  const isTurn = seatId.toString() === game.move && startTurn;
  const potWon = playerPotWin(pots, showdown, seatId);

  return (
    <div style={{ position: 'relative', zIndex: 1 }}>
      <Cash
        cash={+stack}
        player={players[seatId].displayName}
        playerGUID={players[seatId].GUID}
        isCharged={chargedFlag && isFaceCardUp}
        seatId={seatId}
      />
      {
        /*
         * Check if the seat number exists in winners and the win type of that player is "win" and hand is >= 13
         *
         *    playerWinners.includes(seatNumber) &&
         */
        isCurrentLowWinner && languageKey !== 'GAME_MSG_ALLFOLD' ? (
          <WinnerMsg backgroundColor={'#628B48'} message='Low Winner' />
        ) : isCurrentHighWinner ? (
          <WinnerMsg backgroundColor={'#628B48'} />
        ) : (
          <></>
        )
      }
      {false && <ChargeContainer />}

      {game.bustedPlayerCount > 0 &&
      game.guidWinner.includes(seatId) &&
      +hand > 13 &&
      game.gameType === 'Bounty' ? (
        <BountyWinner totalHandBounty={totalHandBounty} backgroundColor={'#628B48'} />
      ) : (
        ''
      )}

      {potWon > 0 && <AmountWon amount={potWon} />}

      <SeatTimer
        start={lastmove}
        current={time}
        target={timerSpeed}
        isStartProgress={isTurn && +hand < 12 && +hand > 4 && !isMultiRunActive}
        size={SEAT_TIMER_SIZE}>
        <Player
          currentPlayerSeatId={currentPlayerSeatId}
          seatId={seatId}
          avatar={mediaUrl.concat(avatar)}
          isCurrentWinner={isCurrentWinner}
        />
      </SeatTimer>
    </div>
  );
};
