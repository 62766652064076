import React from 'react';

import Chip from './Chip';

export const CHIPS_DELAY = 500;

type ChipsPropType = {
  from: {
    top: number;
    left: number;
  };
  to: {
    top: number;
    left: number;
  };
  chipsCount?: number;
  isStartAnimate?: boolean;
};

const Chips = ({ from, to, chipsCount = 3, isStartAnimate = false }: ChipsPropType) => {
  return (
    <>
      {from &&
        getDelays(chipsCount, CHIPS_DELAY).map((delay, i) => {
          const newFrom = {
            ...from,
            top: from.top + (chipsCount - i * 3)
          };

          return (
            <Chip key={i} delay={delay} from={newFrom} to={to} isStartAnimate={isStartAnimate} />
          );
        })}
    </>
  );
};

export default Chips;

const getDelays = (chipsCount: number, totalDelay: number) => {
  const delays: number[] = [];
  const delay = totalDelay / chipsCount;

  for (let i = chipsCount - 1; i > -1; i--) {
    delays.push(i * delay + delay);
  }

  return delays;
};
