import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import {
  createTheme,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ThemeProvider
} from '@mui/material';
import Button from '@mui/material/Button';
import NoDataFound from 'assets/images/no-data-found.svg';
import { hiJackColors } from 'constants/colorConstants';
import { useAppDispatch, useAppSelector } from 'hooks';
import {
  setSortingDirection,
  setSortingField,
  TableType,
  updateSelectedTournament
} from 'reducers/lobby';
import { currencyFormatter, getLocalTime } from 'utils/functions';

import { TTournament } from './types';

interface TournamentTableProps<T> {
  columns: TournamentColumn<T>[];
}

export interface TournamentColumn<T> {
  field: keyof T;
  headerName: string;
  render?: (value: T[keyof T], row: T) => React.ReactNode;
}

const TournamentTable = <T extends Record<string, any>>({ columns }: TournamentTableProps<T>) => {
  const {
    gameTypeFiltered: data,
    sortingDirection,
    sortingField,
    selectedTournament,
    tableTypeSelected
  } = useAppSelector((state) => state.lobby);
  const dispatch = useAppDispatch();
  const handleSort = (column: keyof T) => {
    if (sortingField === column) {
      dispatch(setSortingDirection(sortingDirection === 'asc' ? 'desc' : 'asc'));
    } else {
      dispatch(setSortingField(column));
      dispatch(setSortingDirection('asc'));
    }
  };

  const sortedData = useMemo(() => {
    // Ensure the data is sorted by startTimeLabel by default if no sortingField is provided
    const currentSortingField = sortingField || 'startTimeLabel';

    if (currentSortingField !== null) {
      if (
        currentSortingField === 'strRegistrationTime' &&
        tableTypeSelected !== TableType.Standard
      ) {
        return data.slice().sort((a, b) => {
          const aValue = a['status'];
          const bValue = b['status'];
          if (aValue < bValue) return sortingDirection === 'asc' ? -1 : 1;
          if (aValue > bValue) return sortingDirection === 'asc' ? 1 : -1;
          return 0;
        });
      } else {
        return data.slice().sort((a, b) => {
          let aValue = a[currentSortingField];
          let bValue = b[currentSortingField];

          // If the sorting field is 'startTimeLabel', parse the date for comparison
          if (currentSortingField === 'startTimeLabel') {
            aValue = new Date(aValue).getTime(); // Convert date to timestamp for easier comparison
            bValue = new Date(bValue).getTime();
          }

          if (aValue < bValue) return sortingDirection === 'asc' ? -1 : 1;
          if (aValue > bValue) return sortingDirection === 'asc' ? 1 : -1;
          return 0;
        });
      }
    }

    // If no sorting is needed, return the data as is
    return data;
  }, [data, sortingField, sortingDirection, tableTypeSelected]);

  if (data.length === 0) {
    return (
      <NoDataFoundWrapper>
        <img src={NoDataFound} alt='' style={{ width: 450, height: 250 }} />
        <h3>No Data Found</h3>
      </NoDataFoundWrapper>
    );
  }

  const handleActionButtonClick = (rowData: TTournament) => {
    // console.log('Clicked row:', rowData);

    if (rowData.gameID === selectedTournament?.gameID) {
      dispatch(updateSelectedTournament(null));
    } else {
      dispatch(updateSelectedTournament(rowData));
    }
  };

  return (
    <ThemeProvider theme={TournamentTheme}>
      <TableContainer component={Paper} style={{ marginTop: 8 }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {columns.map((column, index) => {
                return (
                  <TableCell
                    style={{
                      backgroundColor: hiJackColors.black,
                      lineHeight: '0.5rem',
                      cursor: 'pointer',
                      fontFamily: 'Outfit',
                      whiteSpace: 'nowrap'
                    }}
                    key={`${column.field.toString()}-${index}`}
                    onClick={() => {
                      handleSort(column.field);
                    }}>
                    {column.headerName}
                    {sortingField === column.field && (sortingDirection === 'asc' ? '▲' : '▼')}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData.map((row, index) => (
              <TableRow
                style={{
                  backgroundColor:
                    row['gameID'] === selectedTournament?.gameID
                      ? hiJackColors.orange
                      : hiJackColors.darkSlateGray
                }}
                key={index}>
                {columns.map((column, subIndex) => {
                  const renderData = () => {
                    switch (column.field) {
                      case 'buyIn':
                      case 'prizePool':
                        return currencyFormatter(row[column.field]);
                      case 'isMultiFlight':
                        if (row[column.field] === 1) {
                          return 'Yes';
                        } else {
                          return 'No';
                        }
                      case 'maxPlayers':
                        if (row[column.field] === 0) {
                          return 'No Limit';
                        } else {
                          return row[column.field];
                        }
                      case 'startTimeLabel':
                        return tableTypeSelected === TableType.Standard ? (
                          <span>{getLocalTime(row['startAtTime'])}</span>
                        ) : (
                          <span style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            Start Anytime
                          </span>
                        );
                      case 'strRegistrationTime':
                        if (tableTypeSelected === TableType.Standard) {
                          return row[column.field];
                        } else {
                          return row['status'] === 'P' ? 'In Progress' : 'Start Anytime';
                        }
                      case 'status':
                        if (row[column.field] === 'A') {
                          return (
                            <Button
                              style={{
                                borderRadius: 10,
                                backgroundColor: 'black',
                                fontSize: '12px',
                                borderColor: 'transparent',
                                textTransform: 'none',
                                color: 'white'
                              }}
                              variant='outlined'>
                              Announced
                            </Button>
                          );
                        } else if (
                          row[column.field] === 'R' ||
                          row['serverTime'] <= row['startAtTime']
                        ) {
                          return (
                            <Button
                              style={{
                                borderRadius: 10,
                                backgroundColor: '#e98c26',
                                fontSize: '12px',
                                borderColor: 'transparent',
                                textTransform: 'none',
                                color: 'white'
                              }}
                              variant='outlined'>
                              Registering
                            </Button>
                          );
                        } else if (row[column.field] === 'P') {
                          return (
                            <Button
                              style={{
                                borderRadius: 10,
                                backgroundColor: '#628B48',
                                fontSize: '12px',
                                borderColor: 'transparent',
                                textTransform: 'none',
                                color: 'white'
                              }}
                              variant='outlined'>
                              In Progress
                            </Button>
                          );
                        } else {
                          return (
                            <Button
                              style={{
                                borderRadius: 10,
                                backgroundColor: '#383838',
                                fontSize: '12px',
                                borderColor: 'transparent',
                                textTransform: 'none',
                                color: 'white'
                              }}
                              variant='outlined'>
                              Cancelled
                            </Button>
                          );
                        }
                      case 'gameTypeDisplayName':
                      case 'game':
                        if (row[column.field] === 'texas') {
                          return `Texas Hold'em No Limit`;
                        } else {
                          return '';
                        }
                      default:
                        return row[column.field];
                    }
                  };

                  return (
                    <TableCell
                      key={column.field.toString() + subIndex}
                      onClick={() => handleActionButtonClick(row)}>
                      {column.render ? column.render(renderData(), row) : renderData()}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </ThemeProvider>
  );
};

const TournamentTheme = createTheme({
  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          color: hiJackColors.white,
          border: `1px solid ${hiJackColors.darkGray}`,
          borderBottom: `0px`
        }
      }
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          backgroundColor: hiJackColors.darkSlateGray,
          '&:hover': {
            backgroundColor: hiJackColors.orange + '!important',
            cursor: 'pointer'
          }
        }
      }
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: hiJackColors.black,
          color: hiJackColors.white,
          padding: '8px',
          cursor: 'pointer',
          borderBottom: `0px`
        }
      }
    }
  }
});

// const PlayBtnOrange = styled(Button)({
//   border: `1px solid ${hiJackColors.orange}`,
//   backgroundColor: hiJackColors.white,
//   width: '25px',
//   height: '25px',
//   borderRadius: '100%',
//   position: 'relative',
//   minWidth: '10px',
//   '&:hover': {
//     backgroundColor: hiJackColors.white
//   }
// });

// const PlayBtnGreen = styled(Button)({
//   border: `1px solid ${hiJackColors.oliveDrab}`,
//   backgroundColor: hiJackColors.green,
//   width: '25px',
//   height: '25px',
//   borderRadius: '100%',
//   position: 'relative',
//   minWidth: '10px',
//   '&:hover': {
//     backgroundColor: hiJackColors.green
//   }
// });

// const PlayIcon = styled('span')({
//   position: 'absolute',
//   backgroundColor: hiJackColors.orange,
//   width: '10px',
//   height: '10px',
//   top: '50%',
//   left: '55%',
//   transform: 'translate(-50%, -50%)',
//   clipPath: 'polygon(100% 50%, 10% 0, 10% 100%)'
// });

// const PlayIconWhite = styled('span')({
//   position: 'absolute',
//   backgroundColor: hiJackColors.white,
//   width: '10px',
//   height: '10px',
//   top: '50%',
//   left: '55%',
//   transform: 'translate(-50%, -50%)',
//   clipPath: 'polygon(100% 50%, 10% 0, 10% 100%)'
// });

const NoDataFoundWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'nowrap',
  justifyContent: 'center',
  alignItems: 'center'
});

export default TournamentTable;
