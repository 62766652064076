import React, { useContext } from 'react';
import GameContext from 'contexts/GameContext';
import { useAppSelector } from 'hooks';

import Bet from './Bet';

const Bets = () => {
  const players = useAppSelector<any>((state) => state.currentTable.players);
  const seatId = useAppSelector((state) => state.currentTable.currentPlayer.seatId);

  const { betPositions } = useContext(GameContext);

  const appSeats = players?.slice(1);
  const seatCount = appSeats.length;

  return (
    <>
      {appSeats.map((seat: any, index: any) => {
        const seatNumber = index + 1;

        let positionIndex = seatId < 1 ? index : seatNumber - seatId;

        if (positionIndex < 0) {
          positionIndex = seatCount + positionIndex;
        }

        const betPositionLength = ((betPositions.length / seatCount) * positionIndex) | 0;

        return (
          <Bet
            seatId={seatNumber}
            key={index}
            position={betPositions[betPositionLength]}
            seat={seat as any}
          />
        );
      })}
    </>
  );
};

export default Bets;
