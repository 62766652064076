import { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Button, createTheme, Modal, ThemeProvider, Typography } from '@mui/material';
import { Box } from '@mui/system';
import GameContext from 'contexts/GameContext';
import { useAppSelector } from 'hooks';
import useGameService from 'services/GameService';

interface PlayerTournament {
  playerId: string;
  status: string;
  position: number;
  prize: number;
  splitPosition: string;
  splitPrize: number;
  finalHandPot: number;
  schedule: string;
  gameID: number;
  game: string;
  endtime: number;
  myRebuys: number;
  isMultiFlightBreak: number;
  startsIn: number;
  breakTime: number;
  tStatus: string;
  time: number;
  gameNo: number;
  noOfPlayer: number;
  isOnBreak: number;
  currentLevel: number;
  rebuyExpireMins: number;
  addOnExpireMins: number;
  isPostBalancing: number;
}

const TournamentScreenModal = () => {
  const tournamentStatus = useAppSelector((state) => state.currentTable.tournamentStatus);
  const [closeModal, setCloseModal] = useState(false);
  const [showWaitingScreen, setShowWaitingScreen] = useState(false);
  const location = useLocation();
  const [playerTournamentDetails, setPlayerTournamentDetails] = useState<PlayerTournament | null>(
    null
  );
  const { unregister } = useGameService();
  const [observerMode, setObserverMode] = useState(false);
  const { setCanRebuyChips, canRebuyChips, setOpenRebuyChips, openRebuyChips } =
    useContext(GameContext);
  const players = useAppSelector((state) => state.currentTable.players);
  const noOfPlayers = players.filter((player: any) => player !== null && player.GUID !== '').length;
  const guid = useAppSelector((state) => state.user.playerGUID);
  const [isBalancing, setIsBalancing] = useState(false);

  const [msg1, setMsg1] = useState('Please exit the table');
  const [msg2, setMsg2] = useState('');
  const [msg3, setMsg3] = useState('');

  const handlePlayerUnregister = () => {
    unregister(game?.tournamentId, game?.gameID);
    handleExitTable();
  };

  const handleExitTable = () => {
    window.close();

    // Just in case the player open the game manually using URL
    const currentUrl = location.pathname;
    window.location.href = currentUrl;
  };

  const handleCloseModal = () => {
    setShowWaitingScreen(false);
    setCloseModal(true);
  };

  const handleObserverMode = () => {
    setObserverMode(true);
    setShowWaitingScreen(false);
  };

  const handleRebuyChips = () => {
    setShowWaitingScreen(false);
    setOpenRebuyChips(true);
    setCloseModal(true);
  };

  const game = useAppSelector((state) => state.currentTable.game);
  const [gameID, setGameID] = useState(game?.gameID);

  useEffect(() => {
    const data = tournamentStatus;
    const filteredGame = data.filter(
      (d: any) =>
        d.playerId === (guid === '' ? localStorage.getItem('playerGUID') : guid) &&
        d.tournamentId === game?.tournamentId
    )[0] as any;

    if (filteredGame !== undefined) {
      const playerDetails: PlayerTournament = filteredGame;
      setMsg1(filteredGame.msg1);
      setMsg2(filteredGame.msg2);
      setMsg3(filteredGame.msg3);
      setPlayerTournamentDetails(playerDetails);

      if (
        gameID !== Number(playerDetails?.gameID) &&
        playerDetails !== undefined &&
        game?.schedule !== 'Rolling' &&
        playerDetails?.gameID !== -1 &&
        playerDetails?.tStatus === 'P' &&
        playerDetails?.isOnBreak === 0 &&
        playerDetails?.status === 'P' &&
        !openRebuyChips
      ) {
        setMsg1(
          playerDetails.noOfPlayer === 1
            ? 'New Table: Please wait. Opening a new table'
            : 'Balancing Tables: Being moved to another table'
        );
        setIsBalancing(true);
        setShowWaitingScreen(true);

        setTimeout(function () {
          window.location.href = `/game/${playerDetails?.gameID}/type/${playerDetails.game}?observe=no`;
          setGameID(playerDetails?.gameID);
          setShowWaitingScreen(false);
          setCanRebuyChips(false);
        }, 3000);
      } else {
        setIsBalancing(false);
      }

      if (playerDetails?.tStatus === 'P' && game?.schedule !== 'Rolling') {
        setCanRebuyChips(
          playerDetails?.rebuyExpireMins >= playerDetails?.currentLevel &&
            playerDetails?.isMultiFlightBreak !== 1 &&
            game?.isFinalMultiFlight !== 1 &&
            playerDetails?.tStatus === 'P' &&
            playerDetails?.status === 'F'
        );
      }
    } else {
      const filteredGame = data.filter((d: any) => d.tournamentId === game?.tournamentId)[0] as any;
      if (filteredGame?.tStatus === 'P') {
        setMsg1(filteredGame.msg1);
        setMsg2(filteredGame.msg2);
        setMsg3(filteredGame.msg3);
      } else if (filteredGame?.tStatus === 'F') {
        setMsg1('Tournament has finished');
        setMsg2('Please exit the table');
        setMsg3('');
      }
    }
  }, [tournamentStatus]);

  useEffect(() => {
    if (
      (playerTournamentDetails?.status === 'R' && playerTournamentDetails?.tStatus === 'R') ||
      playerTournamentDetails?.tStatus === 'F' ||
      playerTournamentDetails?.tStatus === 'R' ||
      (game?.isOnBreak &&
        (Number(game?.hand) <= 2 || Number(game?.hand) >= 14 || game?.hand === '') &&
        !closeModal &&
        !observerMode) ||
      (game?.isMultiFlightBreak &&
        (Number(game?.hand) <= 2 || Number(game?.hand) >= 14 || game?.hand === '') &&
        !closeModal) ||
      (noOfPlayers < 2 && !openRebuyChips && !closeModal) ||
      game?.isPostBalancing ||
      (playerTournamentDetails?.status === 'F' &&
        playerTournamentDetails?.tStatus === 'P' &&
        !observerMode &&
        !closeModal &&
        !openRebuyChips)
    ) {
      setShowWaitingScreen(true);
    }
  }, [
    canRebuyChips,
    playerTournamentDetails?.status,
    playerTournamentDetails?.tStatus,
    observerMode,
    game?.hand,
    noOfPlayers,
    playerTournamentDetails?.breakTime,
    game?.isPostBalancing
  ]);

  // Final Balancing
  useEffect(() => {
    if (game?.status === 'P' && !game?.isPostBalancing) {
      setShowWaitingScreen(false);
    }
  }, [game?.isPostBalancing]);

  // Number of Players
  useEffect(() => {
    if (
      game?.status === 'P' &&
      noOfPlayers > 1 &&
      game?.isOnBreak !== 1 &&
      playerTournamentDetails?.status !== 'F' &&
      !game?.isPostBalancing
    ) {
      setShowWaitingScreen(false);
    }
  }, [noOfPlayers]);

  useEffect(() => {
    if (playerTournamentDetails?.status === 'F' && canRebuyChips) {
      setShowWaitingScreen(true);
    }
  }, [canRebuyChips]);

  // Tournament Starts
  useEffect(() => {
    if (game?.status === 'P' && !canRebuyChips && !game?.isPostBalancing) {
      setShowWaitingScreen(false);
      setCloseModal(false);
      setObserverMode(false);
    }
  }, [game?.status]);

  // Break Time
  useEffect(() => {
    if (game?.status === 'C') {
      setMsg1(
        `Tournament - ${game?.title} has been cancelled. Your refund will be processed by our teams as soon as possible. Please notify the Hijack Support if you do not receive your refund in the next 24 hours.`
      );
      setMsg2('');
      setMsg3('');
      setShowWaitingScreen(true);
    }

    if (game?.status === 'P' && game?.isOnBreak === 1 && +(game?.hand ?? 0) <= 2) {
      setShowWaitingScreen(true);
    } else if (
      game?.status === 'P' &&
      (game?.isOnBreak === 0 || closeModal || observerMode) &&
      !game?.isPostBalancing
    ) {
      setShowWaitingScreen(false);

      if (game?.isOnBreak === 0) {
        setCloseModal(false);
        setObserverMode(false);
      }
    }

    // Force show when you are alone in the table
    if (noOfPlayers <= 1 && !closeModal) {
      setShowWaitingScreen(true);
    }
  }, [game?.status, game?.isOnBreak]);

  return (
    <Modal open={showWaitingScreen}>
      <Box sx={modalStyle}>
        <Typography
          id='modal-modal-title'
          sx={{ textAlign: 'center', marginBottom: '1rem', fontWeight: 'bold' }}>
          {msg1}
        </Typography>
        <Typography id='modal-modal-title' sx={{ textAlign: 'center', marginBottom: '1rem' }}>
          {msg2}
        </Typography>
        <Typography sx={{ textAlign: 'center', marginBottom: '1rem' }}>{msg3}</Typography>
        <div
          style={{
            justifyContent: 'center',
            marginTop: '2rem',
            gap: '2rem'
          }}>
          {playerTournamentDetails?.tStatus === 'R' &&
          playerTournamentDetails?.status === 'R' &&
          game?.isMultiFlightBreak !== 1 &&
          game?.isFinalMultiFlight !== 1 ? (
            <ThemeProvider theme={UnregisterBtnStyle}>
              <Button
                sx={{ display: 'block', opacity: '.8' }}
                variant='contained'
                onClick={handlePlayerUnregister}>
                {'UNREGISTER'}
              </Button>
            </ThemeProvider>
          ) : (!game?.isOnBreak || game?.isMultiFlightBreak) && !isBalancing ? (
            <ThemeProvider theme={UnregisterBtnStyle}>
              <Button
                sx={{ display: 'block', opacity: '.8' }}
                variant='contained'
                onClick={handleExitTable}>
                EXIT TABLE
              </Button>
            </ThemeProvider>
          ) : (
            <></>
          )}
          {playerTournamentDetails?.status === 'F' &&
            playerTournamentDetails?.tStatus !== 'F' &&
            game?.isMultiFlightBreak !== 1 &&
            game?.isOnBreak !== 1 &&
            noOfPlayers > 1 && (
              <ThemeProvider theme={UnregisterBtnStyle}>
                <Button
                  sx={{ display: 'block', opacity: '.8' }}
                  variant='contained'
                  onClick={handleObserverMode}>
                  OBSERVE GAME
                </Button>
              </ThemeProvider>
            )}
          {canRebuyChips &&
            playerTournamentDetails?.tStatus === 'P' &&
            playerTournamentDetails?.isMultiFlightBreak !== 1 && (
              <ThemeProvider theme={UnregisterBtnStyle}>
                <Button
                  sx={{ display: 'block', opacity: '.8' }}
                  variant='contained'
                  onClick={handleRebuyChips}>
                  RE-ENTRY
                </Button>
              </ThemeProvider>
            )}
          {playerTournamentDetails?.tStatus !== 'F' &&
            game?.isOnBreak === 1 &&
            game?.isMultiFlightBreak !== 1 && (
              <ThemeProvider theme={UnregisterBtnStyle}>
                <Button
                  sx={{ display: 'block', opacity: '.8' }}
                  variant='contained'
                  onClick={handleCloseModal}>
                  CLOSE
                </Button>
              </ThemeProvider>
            )}
        </div>
      </Box>
    </Modal>
  );
};

export default TournamentScreenModal;

const modalStyle = {
  position: 'absolute' as const,
  top: '40%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: '#012425',
  color: 'white',
  padding: '4rem 7rem',
  width: '40rem',
  borderRadius: '0.5rem'
};

const UnregisterBtnStyle = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          color: 'white',
          backgroundColor: '#5b6671',
          '&:hover': {
            color: 'white',
            backgroundColor: '#37393b'
          },
          '&:disabled': {
            color: 'white',
            opacity: '0.4'
          },
          fontSize: '1rem',
          fontWeight: '400',
          padding: '0.5rem 1rem',
          minWidth: 'unset',
          textTransform: 'none',
          width: '100%',
          marginTop: '10px'
        }
      }
    }
  }
});
