import React, { CSSProperties, useCallback, useContext } from 'react';
import { Paper } from '@mui/material';
import { SEAT_CONTAINER_HEIGHT, SEAT_CONTAINER_WIDTH } from 'constants/gameConstants';
import GlobalContext from 'contexts/GlobalContext';
import { useAppSelector } from 'hooks';

type OpenSeatPropType = {
  seatId: number;
};

const OpenSeat = ({ seatId }: OpenSeatPropType) => {
  const isSeated = useAppSelector((state) => state.currentTable.currentPlayer.isSeated);
  const { setOpenAskBuyin, setManuallyAssignedSeatId } = useContext(GlobalContext);
  const isShowdown = useAppSelector((state) => state.currentTable.gameState.isShowdown);

  const internalJoinTable = useCallback(() => {
    if (!isSeated) {
      setOpenAskBuyin(true);
      setManuallyAssignedSeatId(seatId);
    }
  }, [isSeated, seatId, setManuallyAssignedSeatId, setOpenAskBuyin]);

  return (
    <Paper
      elevation={1}
      style={{
        ...OpenSeatStyle,
        cursor: isSeated ? 'default' : 'pointer',
        zIndex: 5,
        position: 'relative',
        ...(isShowdown ? OpenSeatOverlayed : OpenSeatDefault)
      }}
      onClick={() => internalJoinTable()}
    >
      <span style={{ ...TextStyle, userSelect: isSeated ? 'none' : 'inherit' }}></span>
    </Paper>
  );
};

export default OpenSeat;

const OpenSeatStyle: CSSProperties = {
  display: 'flex',
  width: SEAT_CONTAINER_WIDTH,
  height: SEAT_CONTAINER_HEIGHT,
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '100%',
  padding: '1rem'
};

const OpenSeatDefault: CSSProperties = {
  opacity: 1,
  backgroundColor: '#794533'
};

const OpenSeatOverlayed: CSSProperties = {
  opacity: 0.8,
  backgroundColor: '#000000'
};

const TextStyle: CSSProperties = {
  fontFamily: 'Outfit',
  fontSize: '0.75rem',
  letterSpacing: '0.4px',
  width: '50px',
  textAlign: 'center',
  color: '#FFFFFF',
  fontWeight: 400
};
