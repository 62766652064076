import { useContext, useMemo } from 'react';
import GameContext from 'contexts/GameContext';
import GlobalContext from 'contexts/GlobalContext';
import ScaleContext from 'contexts/ScaleContext';
import { useAppSelector } from 'hooks';
import _ from 'lodash';
import { ZIndex } from 'styles';

import JoinActions from './JoinActions';
import JoinButton from './JoinButton';
import MultiRunActions from './MultiRunActions';
import PokerActions from './PokerActions';
import TopAction from './TopAction';

import './UserActions.scss';

const UserActions = () => {
  const { isJoining } = useContext(GlobalContext);

  const game = useAppSelector<any>((state) => state.currentTable.game);
  const { hand, runs, lastAllInPlayer, seats, languageKey } = game;
  const tableType = useAppSelector<any>((state) => state.currentTable.game.tabletype);

  const noPlayers = useAppSelector((state) => state.currentTable.gameState.noPlayers);
  const ingamePlayers = useAppSelector((state) => state.currentTable.gameState.ingamePlayers);
  const nextBBPlayer = useAppSelector((state) => state.currentTable.gameState.nextBBPlayer);

  const isSeated = useAppSelector((state) => state.currentTable.currentPlayer.isSeated);
  const seatId = useAppSelector((state) => state.currentTable.currentPlayer.seatId);
  const isPlayerInactive = useAppSelector(
    (state) => state.currentTable.currentPlayer.isPlayerInactive
  );
  const isPlayerFolded = _.isUndefined(game['p' + seatId + 'bet'])
    ? false
    : game['p' + seatId + 'bet'].substring(0, 1) === 'F';
  const playerStatus = useAppSelector<number>(
    (state) => state.currentTable.players[seatId]?.status
  );
  const isJoined = useAppSelector((state) => state.currentTable.currentPlayer.isJoined);
  const isRejoining = useAppSelector((state) => state.currentTable.currentPlayer.isRejoining);
  const isWaiting = useAppSelector((state) => state.currentTable.currentPlayer.isWaiting);
  const isWaitingAndExit = useAppSelector(
    (state) => state.currentTable.currentPlayer.isWaitingAndExit
  );

  const players = useAppSelector<any>((state) => state.currentTable.players);
  const isMaintenance = useAppSelector((state) => state.maintenanceSchedule.isMaintenance);
  const { scale } = useContext(ScaleContext);
  const { setPreselectedAction } = useContext(GameContext);

  const content = useMemo(() => {
    if (playerStatus === 4) setPreselectedAction('postblind');
    if (playerStatus === 5) setPreselectedAction('waitforbb');

    if (languageKey === 'GAME_MSG_BUSTED_PAUSE' && ingamePlayers > 1) {
      return <></>;
    }

    if (
      (!isSeated &&
        !isMaintenance &&
        tableType !== 'm' &&
        tableType !== 't' &&
        tableType !== '' &&
        noPlayers !== seats) ||
      isWaitingAndExit ||
      isRejoining
    ) {
      return <JoinButton />;
    }

    if (
      nextBBPlayer !== seatId &&
      ingamePlayers > 2 &&
      isWaiting &&
      (hand === '' || +hand > 3) &&
      tableType !== 'm' &&
      tableType !== 't'
    ) {
      return <JoinActions />;
    }

    const vote = runs.split('/')[1];
    if (
      runs.includes('v') &&
      noPlayers > 1 &&
      isSeated &&
      !isWaiting &&
      (seatId === +lastAllInPlayer || vote !== '0') &&
      !isPlayerFolded
    ) {
      return (
        <MultiRunActions
          runs={runs}
          isLastPlayer={seatId === +lastAllInPlayer}
          lastPlayerName={players[+lastAllInPlayer].displayName}
        />
      );
    }

    if (isSeated && !isWaiting) {
      return <PokerActions />;
    }
  }, [
    isJoining,
    isSeated,
    isMaintenance,
    nextBBPlayer,
    seatId,
    ingamePlayers,
    isWaiting,
    hand,
    isPlayerInactive,
    isWaitingAndExit,
    runs,
    noPlayers,
    isJoined,
    playerStatus
  ]);

  return (
    <>
      <TopAction />
      <div
        style={{
          position: 'fixed',
          bottom: 0,
          width: '100%',
          zIndex: ZIndex.action,
          transform: `scale(${scale})`,
          transformOrigin: 'bottom center'
        }}>
        {content}
      </div>
    </>
  );
};

export default UserActions;
