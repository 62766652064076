import styled from '@emotion/styled';
import Box from '@mui/material/Box';
import { hiJackColors } from 'constants/colorConstants';
import { getCardValue } from 'utils/functions';

import { Card } from 'components/Card';
import XSmallCard from 'components/Card/XSmallCard';

type TDoubleCardProps = {
  cards: Card[];
  bet: string;
};

export const isEmptyCard = (card: Card | any) =>
  (card?.rank === '' || card?.rank === undefined || card?.rank === null) &&
  (card?.suit === '' || card?.suit === undefined || card?.suit === null);

const JoinedTableCards = (props: TDoubleCardProps) => {
  const { cards, bet } = props;
  const sortedCards = cards
    .filter((card) => card)
    .sort(
      (card1: Card, card2: Card) =>
        getCardValue(`${card2?.rank}${card2?.suit}`) - getCardValue(`${card1?.rank}${card1?.suit}`)
    );
  return (
    <MainWrapper>
      {sortedCards.map((card: Card, index: number) => (
        <XSmallCard
          key={`${card?.rank}-${card?.suit}-${index}`}
          {...card}
          isEmptyCard={!!card && isEmptyCard(card)}
          enableScale
          isFaceDown={bet?.substring(0, 1) === 'F'}
        />
      ))}
    </MainWrapper>
  );
};

const MainWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  width: 138,
  gap: '2px',
  background: hiJackColors.darkGray,
  justifyContent: 'center',
  padding: '5px'
});

export default JoinedTableCards;
