import React, { PropsWithChildren } from 'react';
import { useEffect, useState } from 'react';
import { useSpring, useSpringRef } from 'react-spring';
import { Box, CircularProgress } from '@mui/material';
import { AWAIT_BUY_IN_PROGRESS_BAR_THICKNESS } from 'constants/gameConstants';
import { useAppSelector } from 'hooks';
import { getPercentage } from 'utils/functions';

import { getProgressColor } from './';

type TimerType = PropsWithChildren & {
  isStartProgress: boolean;
  size: number;
};

type CountDownTimerPropType = TimerType & {
  size: number;
  onComplete?: () => void;
};

const CountDownTimer = ({
  isStartProgress,
  size,
  onComplete = () => {}
}: CountDownTimerPropType) => {
  const { sBustPause, bust } = useAppSelector((state) => state.currentTable.game);
  const [progress, setProgress] = useState(0);
  const [countDown, setCountDown] = useState(+sBustPause);
  const springApi = useSpringRef();

  useSpring({
    ref: springApi,
    onChange({ value }) {
      const countDownValue = +sBustPause - Math.floor(+value.progress);
      setProgress(getPercentage(+value.progress, +sBustPause));
      setCountDown(countDownValue);
      if (countDownValue <= 0) {
        onComplete();
      }
    }
  });

  useEffect(() => {
    if (isStartProgress && bust) {
      springApi({
        from: {
          progress: bust?.elapsedTime
        },
        to: {
          progress: +sBustPause
        },
        config: {
          duration: bust?.duration,
          tension: 20,
          friction: 20,
          precision: 0.1
        }
      });
      springApi.start();
    } else {
      springApi.stop();
    }
  }, [springApi, isStartProgress, bust]);

  return (
    <Box sx={{ position: 'relative', display: 'inline-flex', height: size, width: size }}>
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          padding: `${AWAIT_BUY_IN_PROGRESS_BAR_THICKNESS * 2.25}px`,
          fontSize: '3.5rem',
          borderRadius: '100%',
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center'
        }}>
        {countDown}
      </Box>
      {isStartProgress && (
        <CircularProgress
          variant='determinate'
          value={Math.max(100 - progress, 0)}
          thickness={AWAIT_BUY_IN_PROGRESS_BAR_THICKNESS}
          size={size}
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            color: getProgressColor(progress),
            transition: 'all 1s ease'
          }}
        />
      )}
    </Box>
  );
};

export default CountDownTimer;
