import { CSSProperties, useEffect } from 'react';
import { animated, useSpring } from 'react-spring';
import { Paper } from '@mui/material';
import { audioPath } from 'constants/audioConstants';
import { SEAT_CONTAINER_WIDTH } from 'constants/gameConstants';
import useSound from 'use-sound';

type WinnerMsgPropType = {
  backgroundColor?: string;
  message?: string;
};

export const WinnerMsg = ({
  backgroundColor = '#628B48',
  message = 'Winner'
}: WinnerMsgPropType) => {
  const showWinnerMsg = useSpring({
    from: { top: '0px', opacity: 0 },
    to: { top: `${80 + SEAT_CONTAINER_WIDTH}px`, opacity: 1 },
    config: { mass: 5, tension: 1000, friction: 50 }
  });
  const [playPlayerWin] = useSound(audioPath.playerWins);
  useEffect(() => {
    playPlayerWin();
  }, [playPlayerWin]);

  return (
    <animated.div style={{ ...showWinnerMsg, ...msgContainer }}>
      <Paper sx={{ ...textStyle, backgroundColor }} elevation={6}>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          height='24px'
          viewBox='0 0 24 24'
          width='24px'
          fill='#e1e1e2'
          style={{ marginRight: '0.5rem' }}>
          <rect fill='none' height='24' width='24' />
          <path d='M19,5h-2V4c0-0.55-0.45-1-1-1H8C7.45,3,7,3.45,7,4v1H5C3.9,5,3,5.9,3,7v1c0,2.55,1.92,4.63,4.39,4.94 c0.63,1.5,1.98,2.63,3.61,2.96V19H8c-0.55,0-1,0.45-1,1v0c0,0.55,0.45,1,1,1h8c0.55,0,1-0.45,1-1v0c0-0.55-0.45-1-1-1h-3v-3.1 c1.63-0.33,2.98-1.46,3.61-2.96C19.08,12.63,21,10.55,21,8V7C21,5.9,20.1,5,19,5z M5,8V7h2v3.82C5.84,10.4,5,9.3,5,8z M19,8 c0,1.3-0.84,2.4-2,2.82V7h2V8z' />
        </svg>
        {message}
      </Paper>
    </animated.div>
  );
};

const msgContainer: CSSProperties = {
  position: 'absolute',
  display: 'flex',
  justifyContent: 'center',
  width: '100%'
};

const textStyle: CSSProperties = {
  borderRadius: '3rem',
  textAlign: 'center',
  fontSize: '1rem',
  color: '#e1e1e2',
  padding: '0.5rem 1rem',
  whiteSpace: 'nowrap',
  fontWeight: '800',
  textTransform: 'uppercase',
  display: 'flex'
};
