import { createContext, useCallback, useState } from 'react';

type TexasChatContextType = {
  isOpen: boolean;
  messages?: any[];
  toggle: () => void;
  setIsOpen: (flag: boolean) => void;
};

export const TexasChatContext = createContext<TexasChatContextType>({
  isOpen: false,
  messages: [],
  toggle: () => {},
  setIsOpen: (_: boolean) => {}
});

export const TexasChatContextProvider = ({ children }: any) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = useCallback(() => {
    setIsOpen((prevState) => !prevState);
  }, []);

  return (
    <TexasChatContext.Provider value={{ isOpen, setIsOpen, toggle }}>
      {children}
    </TexasChatContext.Provider>
  );
};

export default TexasChatContext;
