import { useEffect, useMemo, useState } from 'react';
import { a, useSpring } from 'react-spring';
import {
  MULTI_RUN_VOTING_PREFIX,
  PLAYER_PRE_ACTION,
  TABLE_TYPE_SITNGO,
  TABLE_TYPE_TOURNAMENT
} from 'constants/gameConstants';
import { useAppSelector } from 'hooks';
import useGameService from 'services/GameService';
import { currencyFormatter, toLocaleStringNumber } from 'utils/functions';

import HandRank from 'components/HandRank/HandRank';
import PlayerCards from 'components/UserActions/PlayerCards';
import StraddleButton from 'components/UserActions/TableActions/StraddleButton';

import CallAction from './CallAction';
import CheckAction from './CheckAction';
import CheckboxAction from './CheckboxAction';
import Fold from './FoldAction';
import MuckCard from './MuckCard';
import RaiseAction from './RaiseAction';
import ShowCard from './ShowCard';

import 'components/UserActions/UserActions.scss';
import './PokerActions.scss';

const PokerActions = () => {
  const { currentPlayer, players, game } = useAppSelector((state) => state.currentTable);

  const { isFolded: isCurrentUserFolded, seatId } = currentPlayer;

  const { hand, runs } = game;
  const {
    allIn,
    bet,
    call,
    check,
    preCall,
    preCallAny,
    raise,
    isMyTurn,
    muckCard,
    playerCallAmount,
    showAction,
    showCard,
    showStraddle
  } = useAppSelector((state) => state.currentTable.gameState.pokerActions);
  const [preselectedAction, setPreselectedAction] = useState((game as any)[`p${seatId}preAction`]);
  const playerAction = (game as any)[`p${seatId}action`];

  useEffect(() => {
    setPreselectedAction((game as any)[`p${seatId}preAction`]);
  }, [
    game.p1preAction,
    game.p2preAction,
    game.p3preAction,
    game.p4preAction,
    game.p5preAction,
    game.p6preAction,
    game.p7preAction,
    game.p8preAction,
    game.p9preAction
  ]);

  const { gameEnding, notFoldedPlayers } = useAppSelector((state) => state.currentTable.gameState);
  const playerStatus = players[+seatId]?.status;
  const playerStack = players[+seatId]?.stack;
  const gameHand = +hand;

  const { playerTotalChips, playersStack } = useAppSelector(
    (state) => state.currentTable.gameState.pokerActions.betMultiplier
  );

  const { preselectAction } = useGameService();

  const tableType = useAppSelector<any>((state) => state.currentTable.game.tabletype);
  const [isActionDisabled, setIsActionDisabled] = useState(false);

  const onClickPreSelectAction = (action: string) => {
    preselectAction(action);
    setPreselectedAction(action);
  };

  const [showHideActions, api] = useSpring(() => ({
    from: { bottom: '-10rem' },
    to: { bottom: '0rem' },
    onResolve: () => {
      api.start({ reset: false });
    }
  }));

  useEffect(() => {
    setIsActionDisabled(false);
  }, [playerAction, hand]);

  useEffect(() => {
    api.start({ reset: true });
  }, [api, isMyTurn]);

  const content = useMemo(() => {
    if (showStraddle) {
      return <StraddleButton />;
    }

    if (showCard) {
      return <ShowCard />;
    }

    if (muckCard) {
      return <MuckCard />;
    }

    if (!showAction || playerStack <= 0) {
      return null;
    }

    if (isMyTurn) {
      return (
        <>
          <Fold disabled={isActionDisabled} setIsActionDisabled={setIsActionDisabled} />
          {call ? (
            <CallAction disabled={isActionDisabled} setIsActionDisabled={setIsActionDisabled} />
          ) : (
            check && (
              <CheckAction disabled={isActionDisabled} setIsActionDisabled={setIsActionDisabled} />
            )
          )}
          {(bet || raise) && !game.closedBetting && (
            <RaiseAction disabled={isActionDisabled} setIsActionDisabled={setIsActionDisabled} />
          )}
        </>
      );
    } else {
      return (
        <>
          {preCall ? (
            <>
              <CheckboxAction
                label='Fold'
                value={PLAYER_PRE_ACTION.FOLD}
                onChange={onClickPreSelectAction}
                checked={
                  preselectedAction === PLAYER_PRE_ACTION.FOLD ||
                  preselectedAction === PLAYER_PRE_ACTION.CHECK_OR_FOLD
                }
              />
              {allIn ? (
                <CheckboxAction
                  label={`All In ${
                    tableType === TABLE_TYPE_TOURNAMENT || tableType === TABLE_TYPE_SITNGO
                      ? toLocaleStringNumber(playerStack)
                      : currencyFormatter(playerStack)
                  }`}
                  value={PLAYER_PRE_ACTION.ALLIN}
                  onChange={onClickPreSelectAction}
                  checked={preselectedAction === PLAYER_PRE_ACTION.ALLIN}
                />
              ) : (
                playerCallAmount > 0 && (
                  <CheckboxAction
                    label={`Call ${
                      tableType === TABLE_TYPE_TOURNAMENT || tableType === TABLE_TYPE_SITNGO
                        ? toLocaleStringNumber(playerCallAmount)
                        : currencyFormatter(playerCallAmount)
                    }`}
                    value={PLAYER_PRE_ACTION.CALL}
                    onChange={onClickPreSelectAction}
                    checked={preselectedAction === PLAYER_PRE_ACTION.CALL}
                  />
                )
              )}
            </>
          ) : (
            <>
              <CheckboxAction
                label='Check/Fold'
                value={PLAYER_PRE_ACTION.CHECK_OR_FOLD}
                onChange={onClickPreSelectAction}
                checked={preselectedAction === PLAYER_PRE_ACTION.CHECK_OR_FOLD}
              />
              <CheckboxAction
                label='Check'
                value={PLAYER_PRE_ACTION.CHECK}
                onChange={onClickPreSelectAction}
                checked={preselectedAction === PLAYER_PRE_ACTION.CHECK}
              />
            </>
          )}
          {preCallAny && (
            <CheckboxAction
              label='Call Any'
              value={PLAYER_PRE_ACTION.CALL_ANY}
              onChange={onClickPreSelectAction}
              checked={preselectedAction === PLAYER_PRE_ACTION.CALL_ANY}
            />
          )}
        </>
      );
    }
  }, [
    gameHand,
    playersStack,
    gameEnding,
    playerStatus,
    isMyTurn,
    showAction,
    showCard,
    muckCard,
    playerCallAmount,
    preselectedAction,
    playerTotalChips,
    players,
    seatId,
    preselectAction,
    notFoldedPlayers,
    showStraddle
  ]);

  return (
    <>
      <div className='player-actions'>
        <PlayerCards />
        <a.div className='poker-actions' style={{ ...showHideActions, zIndex: 1 }}>
          {!game.isOmaha && <HandRank />}
          {!!content &&
            !isCurrentUserFolded &&
            !gameEnding &&
            !runs.includes(MULTI_RUN_VOTING_PREFIX) && (
              <div
                style={{
                  position: 'relative',
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '1rem',
                  width: '100%',
                  paddingTop: '1rem',
                  paddingBottom: '1rem',
                  justifyContent: 'center',
                  zIndex: 3
                }}>
                {content}
              </div>
            )}
        </a.div>
      </div>
    </>
  );
};

export default PokerActions;
