import { useContext, useEffect, useState } from 'react';
import GameContext from 'contexts/GameContext';
import { useAppSelector } from 'hooks';
import useGameService from 'services/GameService';

import CheckboxAction from 'components/UserActions/PokerActions/CheckboxAction';

import 'components/UserActions/UserActions.scss';

const PostBlind = () => {
  const canBuyTheButton = useAppSelector<any>(
    (state) => state.currentTable.gameState.canBuyTheButton
  );
  const { postBlind } = useGameService();
  const { preselectedAction, setPreselectedAction } = useContext(GameContext);

  const [internalDisabled, setInternalDisabled] = useState(false);

  const changeEvent = function () {
    setPreselectedAction('postblind');
    postBlind();
  };

  useEffect(() => {
    setInternalDisabled(!canBuyTheButton);
  }, [canBuyTheButton]);

  return (
    <>
      <CheckboxAction
        label='Post Blind'
        value='postblind'
        onChange={changeEvent}
        checked={preselectedAction === 'postblind'}
        disabled={internalDisabled}
      />
    </>
  );
};

export default PostBlind;
