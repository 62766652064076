import { useCallback, useContext, useEffect, useRef } from 'react';
import { CREATE_PLAYER, GET_HEARTBEAT, LOGIN_PLAYER, ONLINE } from 'constants/eventConstants';
import { SocketContext } from 'contexts/SocketContext';
import { useAppSelector } from 'hooks';
import Cookies from 'js-cookie';
import {
  extractAccessTokenFromDescopeCookie,
  extractIDTokenFromDescopeCookie,
  extractRefreshTokenFromDescopeCookie
} from 'utils/functions';
import { DESCOPE_COOKIE, FRONT_OFFICE_LOCAL_URL } from 'utils/urls';

// interface decodedIDObject {
//   email: string;
//   exp: number;
//   name: string;
//   sub: string;
//   avatar: string;
// }

const useAuthService = () => {
  const socket = useContext(SocketContext);
  const displayName = useAppSelector((state) => state.user.displayName);
  const heartBeatIntervalIdRef = useRef<any>(null);
  const locationSegments = window.location.hostname.split('.');
  const loginRedirectRef = useRef(
    `https://account.${locationSegments[1]}.${locationSegments[2]}/login`
  );

  useEffect(() => {
    if (window.location.hostname === 'hijack.local') {
      loginRedirectRef.current = `${FRONT_OFFICE_LOCAL_URL}/login`;
    }
  }, []);

  const getToken = useCallback(() => {
    const descopeCookie = Cookies.get(DESCOPE_COOKIE as string);
    if (!descopeCookie || typeof descopeCookie !== 'string') {
      window.location.href = loginRedirectRef.current;
      return {
        idToken: null,
        descopeSession: null,
        descopeRefresh: null
      };
    }

    return {
      idToken: extractIDTokenFromDescopeCookie(descopeCookie),
      descopeSession: extractAccessTokenFromDescopeCookie(descopeCookie),
      descopeRefresh: extractRefreshTokenFromDescopeCookie(descopeCookie)
    };
  }, []);

  const createPlayer = useCallback(() => {
    const { descopeSession } = getToken();

    if (!descopeSession) {
      window.location.href = loginRedirectRef.current;
      return;
    }

    //Attempt to create account assuming first visit
    socket.emit(CREATE_PLAYER, {
      jwt: descopeSession,
      key: 'AUEEW891WL',
      language: 'en'
    });
  }, [getToken, socket]);

  const syncAvatar = useCallback(
    // eslint-disable-next-line unused-imports/no-unused-vars
    (currentAvatar: string) => {
      // const { idToken } = getToken();
      // if (!idToken) {
      //   window.location.href = loginRedirectRef.current;
      //   return;
      // }
      // const jwtPayload: decodedIDObject = jwt_decode(idToken);
      // const domain =
      //   process.env.NODE_ENV === 'development'
      //     ? 'hijackpoker-test.online'
      //     : window.location.hostname.split('.')[1] + '.' + window.location.hostname.split('.')[2];
      // let avatarUrl = `https://media.${domain}/images/004-poker+cards.png`;
      // if (jwtPayload.avatar) {
      //   avatarUrl = `https://media.${domain}/images/${jwtPayload.avatar}`;
      // }
      // if (currentAvatar !== avatarUrl) {
      //   socket.emit(AVATAR_CHANGE, {
      //     data: `&avatar=${avatarUrl}`,
      //     guid: jwtPayload?.sub
      //   });
      // }
    },
    [getToken, socket]
  );

  const loginPlayer = useCallback(
    (data: any) => {
      const { descopeSession } = getToken();

      // Attempt to register user from token
      if (data?.response?.error?.startsWith('Email already in use')) {
        // User already exists, Attempt to login
        if (!displayName) {
          socket.emit(LOGIN_PLAYER, {
            jwt: descopeSession,
            deviceID:
              localStorage.getItem('deviceID') === null ? '' : localStorage.getItem('deviceID'),
            language: 'en',
            key: 'AUEEW891WL' // Key should not be passed here, store as eNV on engine side instead
          });
        }
      } else if (data?.response?.playerName !== 'NOUSER') {
        socket.emit(LOGIN_PLAYER, {
          jwt: descopeSession,
          deviceID:
            localStorage.getItem('deviceID') === null ? '' : localStorage.getItem('deviceID'),
          language: 'en',
          key: 'AUEEW891WL' //Key should not be passed here, store as eNV on engine side instead
        });
      }
    },
    [displayName, getToken, socket]
  );

  const online = useCallback(
    (playerGUID: string) => {
      socket.emit(ONLINE, {
        playerGUID
      });
    },
    [socket]
  );

  // Auto disconnect is at 5mins, so send heartbeat every 4mins
  // NO LONGER NEEDED
  const autoNotifyHeartBeat = useCallback(
    (playerGUID: string, intervalInMiliseconds = 180000) => {
      if (heartBeatIntervalIdRef.current) {
        clearInterval(heartBeatIntervalIdRef.current);
      }

      heartBeatIntervalIdRef.current = setInterval(() => {
        if (playerGUID) {
          socket.emit(GET_HEARTBEAT, { playerGUID: playerGUID });
        }
      }, intervalInMiliseconds);
    },
    [socket]
  );

  return {
    createPlayer,
    loginPlayer,
    syncAvatar,
    online,
    autoNotifyHeartBeat
  };
};

export default useAuthService;
