import { useContext } from 'react';
import { Button, createTheme, Modal, ThemeProvider, Typography } from '@mui/material';
import { Box } from '@mui/system';
import GlobalContext from 'contexts/GlobalContext';
import { FRONT_OFFICE_LOCAL_URL } from 'utils/urls';

const InvalidLocationModal = () => {
  const { isLocationInvalid, invalidLocationMsg } = useContext(GlobalContext);
  const cashierPath = 'hijack/cashier/deposit';

  const goToCashier = () => {
    window.location.href =
      window.location.hostname === 'hijack.local'
        ? `${FRONT_OFFICE_LOCAL_URL}/${cashierPath}`
        : 'https://account.' +
          window.location.hostname.split('.')[1] +
          '.' +
          window.location.hostname.split('.')[2] +
          `/${cashierPath}`;
  };

  const goToProfile = () => {
    window.location.href = `${FRONT_OFFICE_LOCAL_URL}/hijack/profile`;
  };

  return (
    <Modal open={isLocationInvalid}>
      <Box sx={modalStyle}>
        <Typography
          id='modal-modal-title'
          variant='h5'
          component='h2'
          sx={{ textAlign: 'center', marginBottom: '2rem' }}>
          Sorry Partner!
        </Typography>
        {invalidLocationMsg === '' ? (
          <Typography id='modal-modal-title' sx={{ textAlign: 'center', marginBottom: '2rem' }}>
            Unfortunately, you are outside
            <br />
            of the approved regions to PLAY.
          </Typography>
        ) : (
          <Typography id='modal-modal-title' sx={{ textAlign: 'center', marginBottom: '2rem' }}>
            {invalidLocationMsg}
          </Typography>
        )}
        <Typography id='modal-modal-title' sx={{ textAlign: 'center', marginBottom: '2rem' }}>
          You can still:
        </Typography>

        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '2rem',
            gap: '2rem'
          }}>
          <ThemeProvider theme={AddChipsBtnStyle}>
            <Button sx={{ display: 'block' }} variant='contained' onClick={goToProfile}>
              Manage Your Profile
            </Button>
          </ThemeProvider>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '2rem',
            gap: '2rem'
          }}>
          OR
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '2rem',
            gap: '2rem'
          }}>
          <ThemeProvider theme={AddChipsBtnStyle}>
            <Button sx={{ display: 'block' }} variant='contained' onClick={goToCashier}>
              Go to Cashier
            </Button>
          </ThemeProvider>
        </div>
      </Box>
    </Modal>
  );
};

export default InvalidLocationModal;

const modalStyle = {
  position: 'absolute' as const,
  top: '40%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: '#012425',
  color: 'white',
  padding: '4rem 7rem',
  width: '40rem',
  borderRadius: '0.5rem'
};

const AddChipsBtnStyle = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          color: 'white',
          backgroundColor: '#E98C26',
          '&:hover': {
            color: 'white',
            backgroundColor: '#A7651C'
          },
          '&:disabled': {
            color: 'white',
            backgroundColor: '#E98C26',
            opacity: '0.4'
          },
          fontSize: '1rem',
          fontWeight: '400',
          padding: '0.5rem 1rem',
          minWidth: 'unset',
          textTransform: 'none',
          width: '100%'
        }
      }
    }
  }
});
