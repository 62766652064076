import React, { useEffect, useState } from 'react';
import { Box, Button, createTheme, Modal, ThemeProvider } from '@mui/material';
import { useAppSelector } from 'hooks';
import useGameService from 'services/GameService';

const AutoFoldMessageModal = () => {
  const { tabletype, move, moveMsg } = useAppSelector((state) => state.currentTable.game);
  const currentPlayerSeatId = useAppSelector((state) => state.currentTable.currentPlayer.seatId);
  const { resetMttInactivity } = useGameService();

  const [msg, setMsg] = useState('');
  const [isShow, setIsShow] = useState(false);

  const handleClose = () => {
    setIsShow(false);
    resetMttInactivity();
  };

  useEffect(() => {
    if (currentPlayerSeatId === +move && moveMsg.length > 0) {
      setMsg(moveMsg);
      setIsShow(true);
    }
  }, [move, moveMsg]);

  return (
    <>
      {tabletype === 'm' && (
        <Modal open={isShow}>
          <Box
            sx={{
              ...modalStyle,
              boxShadow: 10,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '2rem'
            }}>
            <div style={{ fontSize: '1.5rem', textAlign: 'center' }}>{msg}</div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                gap: '2rem',
                width: '100%'
              }}>
              <ThemeProvider theme={CancelBtnStyle}>
                <Button
                  sx={{ display: 'block', opacity: '.8', textTransform: 'uppercase' }}
                  variant='contained'
                  onClick={handleClose}>
                  Ok
                </Button>
              </ThemeProvider>
            </div>
          </Box>
        </Modal>
      )}
    </>
  );
};

export default AutoFoldMessageModal;

const modalStyle = {
  position: 'absolute' as const,
  top: '40%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: '#012425',
  color: 'white',
  padding: '4rem 7rem',
  width: '40rem',
  borderRadius: '0.5rem'
};

const CancelBtnStyle = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          color: 'white',
          backgroundColor: '#5b6671',
          '&:hover': {
            color: 'white',
            backgroundColor: '#37393b'
          },
          '&:disabled': {
            color: 'white',
            opacity: '0.4'
          },
          fontSize: '1rem',
          fontWeight: '400',
          padding: '0.5rem 1rem',
          minWidth: 'unset',
          textTransform: 'none',
          width: '100%'
        }
      }
    }
  }
});
