import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { withStyles } from '@material-ui/styles';
import ArrowDownwardRoundedIcon from '@mui/icons-material/ArrowDownwardRounded';
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import ArrowUpwardRoundedIcon from '@mui/icons-material/ArrowUpwardRounded';
import { Chip, createTheme, Grid, IconButton, Menu, MenuItem, ThemeProvider } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import NoDataFound from 'assets/images/no-data-found.svg';
import { hiJackColors } from 'constants/colorConstants';
import LobbyContext from 'contexts/LobbyContext';
import { useAppDispatch, useAppSelector } from 'hooks';
import {
  GameView,
  setSortingDirection,
  setSortingField,
  TableType,
  TSelectedFilters,
  updateGameTypeFilter,
  updateSelectedFilters,
  updateSelectedTournament,
  updateTableType,
  updateTableView
} from 'reducers/lobby';
import { currencyFormatter, getLocalTime, sortData } from 'utils/functions';

import TournamentTable, { TournamentColumn } from 'components/TournamentTable/TournamentTable';

import TableFiltersMenu from './TableFiltersMenu';

type TableHeader = {
  id?: string;
  label: string;
};

enum observeMode {
  yes = 'yes',
  no = 'no'
}

export enum GameModeLabel {
  texas = 'Texas Hold’em',
  omaha = 'Omaha'
}

export enum GameMode {
  texas = 'texas',
  omaha = 'omaha'
}

interface CustomTableProps {
  headers: TableHeader[];
}

const TableHeaderComponent = (thData: TableHeader[]) => {
  return (
    <>
      {thData &&
        thData?.map((item, index) => {
          return <TableHeadItem key={index}>{item.label}</TableHeadItem>;
        })}
    </>
  );
};

const filterData = (data: any[], selectedFilters: TSelectedFilters): any[] => {
  const applyFilter = (item: any): boolean => {
    const tableLow = parseFloat(item?.tablelow);
    const sb = parseFloat(item?.sb);
    const bb = parseFloat(item?.bb);
    if (selectedFilters.stake !== null) {
      const isStakeValid =
        (sb >= selectedFilters.stake[0] && sb <= selectedFilters.stake[1]) ||
        (bb >= selectedFilters.stake[0] && bb <= selectedFilters.stake[1]);
      if (!isStakeValid) return false;
    }

    if (selectedFilters.maxPlayer !== null) {
      const isMaxPlayerValid =
        item.seats >= selectedFilters.maxPlayer[0] && item.seats <= selectedFilters.maxPlayer[1];
      if (!isMaxPlayerValid) return false;
    }

    if (selectedFilters.minBuyIn !== null) {
      const isMinBuyInValid =
        tableLow >= selectedFilters.minBuyIn[0] && tableLow <= selectedFilters.minBuyIn[1];
      if (!isMinBuyInValid) return false;
    }

    if (selectedFilters.isHideEmptyGames !== undefined && selectedFilters.isHideEmptyGames) {
      if (item.empty && item.seatsTaken <= 0) return false;
    }

    return true;
  };

  return data.filter(applyFilter);
};

const CommingSoonTooltip = withStyles({
  tooltip: {
    color: hiJackColors.white,
    backgroundColor: hiJackColors.red + '!important'
  }
})(Tooltip);

const LobbyTable = ({ headers }: CustomTableProps) => {
  const dispatch = useAppDispatch();
  const isMaintenance = useAppSelector((state) => state.maintenanceSchedule.isMaintenance);
  const { setStakeValue, setMaxPLValue, setMinBuyInValue, setIsHideEmptyGame } =
    useContext(LobbyContext);
  const {
    texasCashGames,
    omahaCashGames,
    tournament,
    tableTypeSelected,
    gameTypeFiltered,
    tableViewSelected,
    selectedFilters,
    selectedGameMode,
    selectedTournament
  } = useAppSelector((state) => state.lobby);

  const [cashGames, setCashGames] = useState<any[]>([]);
  const [tournaments, setTournaments] = useState<any>([]);
  const [sitNGos, setSitAndGos] = useState<any>([]);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [cashGamesSortingField, setCashGamesSortingField] = useState<string>('stakes');
  const [cashGamesSortingDirection, setCashGamesSortingDirection] = useState<string>('asc');

  // Sorting function for cash games only
  const handleCashGamesSort = () => {
    const newDirection = cashGamesSortingDirection === 'asc' ? 'desc' : 'asc';

    // Update sorting field and direction
    setCashGamesSortingField(cashGamesSortingField);
    setCashGamesSortingDirection(newDirection);

    // Sort the cash games data using the updated field and direction
    const sortedData = sortData(gameTypeFiltered, cashGamesSortingField, newDirection);
    dispatch(updateGameTypeFilter(sortedData));
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleFieldSelection = (field: string) => {
    setAnchorEl(null);
    setCashGamesSortingField(field);
  };

  const getSortingFieldLabel = (sortingField: string) => {
    switch (sortingField) {
      case 'gameID':
        return 'Game ID';
      case 'stakes':
        return 'Stakes';
      case 'tablelimit':
        return 'Limit';
      case 'seats':
        return 'Table Capacity';
      case 'seatsTakenDisplay':
        return 'Seats taken';
      case 'tablelow':
        return 'Minimum buy-in';
      default:
        return 'Select';
    }
  };

  const CheckFilter = (selectedFilters: any, cashGames: any, tournaments: any, sitNGos: any) => {
    let filteredData;
    switch (tableTypeSelected) {
      case TableType.CashGames:
        if (selectedFilters) {
          filteredData = filterData(cashGames, selectedFilters).filter(
            (row) => row.tabletype === 's'
          );
        } else {
          filteredData = cashGames.filter((row: any) => row.tabletype === 's');
        }
        break;
      case TableType.Standard:
        if (selectedFilters) {
          filteredData = filterData(tournaments, selectedFilters);
        } else {
          filteredData = tournaments;
        }
        break;
      case TableType.SitNGo:
        if (selectedFilters) {
          filteredData = filterData(sitNGos, selectedFilters);
        } else {
          filteredData = sitNGos;
        }
        break;
      default:
        break;
    }

    return filteredData;
  };

  const handleGameType = (tableType: string) => {
    dispatch(updateTableType(tableType));
    dispatch(updateSelectedFilters(null));
    dispatch(updateSelectedTournament(null));
    dispatch(setSortingField(null));
    dispatch(setSortingDirection('asc'));

    setCashGamesSortingField('stakes');
    setCashGamesSortingDirection('asc');

    let filteredData;
    switch (tableType) {
      case TableType.CashGames:
        if (selectedFilters) {
          filteredData = filterData(cashGames, selectedFilters).filter(
            (row) => row.tabletype === 's'
          );
        } else {
          filteredData = cashGames.filter((row: any) => row.tabletype === 's');
        }
        break;
      case TableType.Standard:
        if (selectedFilters) {
          filteredData = filterData(tournaments, selectedFilters);
        } else {
          filteredData = tournaments;
        }
        break;
      case TableType.SitNGo:
        if (selectedFilters) {
          filteredData = filterData(sitNGos, selectedFilters);
        } else {
          filteredData = sitNGos;
        }
        break;
      default:
        break;
    }

    if (filteredData) {
      dispatch(updateGameTypeFilter(filteredData));
    }
  };

  const handleSetDataFilter = () => {
    let filteredData;
    switch (tableTypeSelected) {
      case TableType.CashGames:
        filteredData = cashGames.filter((row: any) => row.tabletype === tableTypeSelected);
        break;
      case TableType.Standard:
        filteredData = cashGames.filter((row: any) => row.tabletype === tableTypeSelected);
        break;
      default:
        break;
    }

    if (filteredData) {
      dispatch(updateGameTypeFilter(filteredData));
    }
  };

  const handleView = (gameView: number) => {
    dispatch(setSortingField(null));
    dispatch(setSortingDirection('asc'));
    dispatch(updateTableView(gameView));
  };

  const handleClearValueFilter = (filterType: string) => {
    switch (filterType) {
      case 'stake':
        setStakeValue([0, 0]);
        break;
      case 'maxPlayer':
        setMaxPLValue([0, 0]);
        break;
      case 'minBuyIn':
        setMinBuyInValue([0, 0]);
        break;
      case 'isHideEmptyGames':
        setIsHideEmptyGame(false);
        break;
      case 'all':
        setStakeValue([0, 0]);
        setMaxPLValue([0, 0]);
        setMinBuyInValue([0, 0]);
        setIsHideEmptyGame(false);
        break;
      default:
        break;
    }
  };

  const handleClearFilter = (filter: keyof TSelectedFilters, isClearAll: boolean) => {
    let data: any = { ...selectedFilters };
    if (!Object.values(data).some((filter) => !!filter)) return;
    if (isClearAll) {
      data = {
        stake: null,
        maxPlayer: null,
        minBuyIn: null,
        isHideEmptyGames: false
      };
      dispatch(updateSelectedFilters(data));
      handleClearValueFilter('all');
      handleSetDataFilter();
    } else {
      data[filter] = filter === 'isHideEmptyGames' ? false : null;
      dispatch(updateSelectedFilters(data));
      handleClearValueFilter(filter);
      CheckFilter(data, cashGames, tournaments, sitNGos);
    }
  };

  useEffect(() => {
    const cashGameList = selectedGameMode === GameMode.texas ? texasCashGames : omahaCashGames;
    const tournamentList = selectedGameMode === GameMode.texas ? tournament.tournamentList : [];
    const sitNGoList = selectedGameMode === GameMode.texas ? tournament.sitAndGoList : [];

    // Set initial data
    setCashGames(cashGameList);
    setTournaments(tournamentList);
    setSitAndGos(sitNGoList);

    // Get the filtered data
    const filteredData = CheckFilter(selectedFilters, cashGameList, tournamentList, sitNGoList);

    // Apply sorting after filtering
    let finalData = filteredData;
    if (cashGamesSortingField && cashGamesSortingDirection) {
      finalData = sortData(filteredData, cashGamesSortingField, cashGamesSortingDirection);
    }

    dispatch(updateGameTypeFilter(finalData));
  }, [
    selectedFilters,
    texasCashGames,
    omahaCashGames,
    tournament,
    cashGamesSortingField,
    cashGamesSortingDirection
  ]);

  const handleActionButtonClick = (rowData: any) => {
    if (tableTypeSelected === TableType.CashGames) {
      return;
    }

    if (rowData.gameID === selectedTournament?.gameID) {
      dispatch(updateSelectedTournament(null));
    } else {
      dispatch(updateSelectedTournament(rowData));
    }
  };
  const columns: TournamentColumn<any>[] = [
    { field: 'tablename', headerName: 'Tournament' },
    { field: 'startTimeLabel', headerName: 'Schedule' },
    { field: 'gameTypeDisplayName', headerName: 'Game' },
    { field: 'buyIn', headerName: 'BuyIn' },
    { field: 'registeredPlayers', headerName: 'Registered' },
    { field: 'prizePool', headerName: 'Current Prize Pool' },
    { field: 'maxPlayers', headerName: 'Max Players' },
    { field: 'isMultiFlight', headerName: 'Multi-Flight' },
    { field: 'maxSeat', headerName: 'Max Seat' },
    { field: 'status', headerName: 'State' }
  ];

  const filteredColumns =
    tableTypeSelected !== TableType.Standard
      ? columns.filter((column) => column.field !== 'isMultiFlight' && column.field !== 'maxSeat')
      : columns;

  const cellStyles = {
    default: {
      display: 'block',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      justifyContent: tableTypeSelected === TableType.Standard ? 'flex-start' : 'flex-end'
    },
    flex: {
      display: 'flex',
      justifyContent: 'flex-end'
    }
  };

  const TableViewRender = () => {
    const isCashGamesTable = tableTypeSelected === TableType.CashGames;

    switch (tableViewSelected) {
      case GameView.List:
        if (isCashGamesTable) {
          return (
            <>
              <TableHeadWrapper>
                {TableHeaderComponent(headers)}
                <TableHeadItem> </TableHeadItem>
              </TableHeadWrapper>
              <ListWrapper>
                {gameTypeFiltered.length !== 0 ? (
                  gameTypeFiltered.map((row: any, mIndex: number) => (
                    <TableRow
                      key={mIndex}
                      style={{
                        borderRadius:
                          gameTypeFiltered.length === 1
                            ? '8px'
                            : mIndex === 0
                            ? '8px 8px 0 0'
                            : mIndex === gameTypeFiltered.length - 1
                            ? '0 0 8px 8px'
                            : '0',
                        borderLeft:
                          row?.seatsTaken >= 1
                            ? `solid 5px ${hiJackColors.orange}`
                            : `solid 5px ${hiJackColors.darkSlateGray}`,
                        backgroundColor:
                          row?.seatsTaken >= 1 ? '#161B1F' : hiJackColors.darkSlateGray
                      }}>
                      {headers.map((header: any, subIndex: number) => (
                        <TableCell
                          style={{
                            color: subIndex === 0 ? hiJackColors.orange : hiJackColors.white,
                            paddingLeft: subIndex === 0 ? 8 : 0
                          }}
                          key={header.id}>
                          {row[header.id]}
                        </TableCell>
                      ))}
                      <TableCell>
                        <Link
                          style={{ textDecoration: 'none', color: '#FFFFFF' }}
                          to={
                            isMaintenance
                              ? '#'
                              : `/game/${row?.gameID}/type/${row?.game}?observe=${observeMode.no}`
                          }
                          target={isMaintenance ? '' : '_blank'}
                          rel='noopener'>
                          <BtnJoin
                            onClick={() => handleActionButtonClick(row)}
                            style={{ opacity: isMaintenance ? '.4' : '1' }}
                            disabled={isMaintenance}>
                            Join
                          </BtnJoin>
                        </Link>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <NoDataFoundWrapper>
                    <img src={NoDataFound} alt='' style={{ width: 450, height: 250 }} />
                    <h3>No Data Found</h3>
                  </NoDataFoundWrapper>
                )}
              </ListWrapper>
            </>
          );
        } else {
          return <TournamentTable columns={filteredColumns} />;
        }
      case GameView.Grid:
        if (isCashGamesTable) {
          return gameTypeFiltered.length !== 0 ? (
            <GridContainer>
              {gameTypeFiltered.map((row: any) => (
                <GridRow
                  key={`gridRow-${row.gameID}`}
                  style={{
                    border: row?.seatsTaken >= 1 ? `1px inset ${hiJackColors.orange}` : 'none',
                    boxShadow: row?.seatsTaken >= 1 ? `0 0 2px 2px ${hiJackColors.orange}` : 'none'
                  }}>
                  {headers.map((header: any, subIndex: number) => {
                    const colorCondition =
                      subIndex === 0 ? hiJackColors.orange : hiJackColors.white;
                    const fontCondition = subIndex === 0 ? 16 : 12;
                    const marginTopCondition = subIndex === 0 ? -10 : 0;

                    const labelCondition =
                      subIndex === 0 ? (
                        <GridCellWrapper
                          key={`label-${row.gameID}-${header.id}`}
                          style={{
                            placeContent: 'center',
                            cursor: 'text',
                            marginTop: subIndex === 0 ? 8 : 0
                          }}
                          onClick={() => handleActionButtonClick(row)}>
                          {GameModeLabel[row[header.id] as keyof typeof GameModeLabel]}
                        </GridCellWrapper>
                      ) : (
                        <>
                          <GridCellWrapper
                            key={`label-${row.gameID}-${subIndex}-start`}
                            style={{ justifyContent: 'flex-start', color: hiJackColors.slateGray }}>
                            {header.label + ': '}
                          </GridCellWrapper>
                          <GridCellWrapper
                            key={`label-${row.gameID}-${subIndex}-end`}
                            style={{ justifyContent: 'flex-end' }}>
                            {row[header.id]}
                          </GridCellWrapper>
                        </>
                      );

                    return (
                      <GridCell
                        style={{
                          color: colorCondition,
                          fontSize: fontCondition,
                          marginTop: marginTopCondition
                        }}
                        key={`gridCell-${row.gameID}-${subIndex}-${header.id}`}>
                        {labelCondition}
                      </GridCell>
                    );
                  })}

                  <FooterActionWrapper>
                    <GridCellWrapper>
                      <GridCell style={{ justifyContent: 'flex-start' }}>
                        {/* <Link
                          style={{ textDecoration: 'none', color: '#FFFFFF' }}
                          to={
                            isMaintenance ? '#' : `/game/${row?.gameID}?observe=${observeMode.yes}`
                          }
                          target={isMaintenance ? '' : '_blank'}
                          rel='noopener'>
                          <BtnObserve> Observe </BtnObserve>
                        </Link> */}
                        <CommingSoonTooltip title='Coming Soon!' followCursor>
                          <BtnObserveCommingSoon
                            sx={{ bgcolor: 'text.disabled', color: 'background.paper', p: 2 }}>
                            Observe
                          </BtnObserveCommingSoon>
                        </CommingSoonTooltip>
                      </GridCell>
                    </GridCellWrapper>
                    <GridCellWrapper>
                      <GridCell style={{ justifyContent: 'flex-end' }}>
                        <Link
                          style={{ textDecoration: 'none', color: '#FFFFFF' }}
                          to={
                            isMaintenance
                              ? '#'
                              : `/game/${row?.gameID}/type/${row?.game}?observe=${observeMode.no}`
                          }
                          target={isMaintenance ? '' : '_blank'}
                          rel='noopener'>
                          <BtnJoin
                            onClick={() => handleActionButtonClick(row)}
                            style={{ opacity: isMaintenance ? '.4' : '1' }}
                            disabled={isMaintenance}>
                            Join
                          </BtnJoin>
                        </Link>
                      </GridCell>
                    </GridCellWrapper>
                  </FooterActionWrapper>
                </GridRow>
              ))}
            </GridContainer>
          ) : (
            <NoDataFoundWrapper>
              <img src={NoDataFound} alt='' style={{ width: 450, height: 250 }} />
              <h3>No Data Found</h3>
            </NoDataFoundWrapper>
          );
        } else {
          return gameTypeFiltered.length !== 0 ? (
            <GridContainer>
              {gameTypeFiltered.map((row: any, index: number) => (
                <GridRow
                  key={index}
                  style={{
                    boxShadow:
                      row['gameID'] === selectedTournament?.gameID
                        ? `0px 1px 8px ${hiJackColors.darkOrange}`
                        : `0px 0px 0px ${hiJackColors.black}`
                  }}>
                  {filteredColumns.map((column, subIndex) => {
                    const renderData = () => {
                      switch (column.field) {
                        case 'buyIn':
                        case 'prizePool':
                          return currencyFormatter(row[column.field]);
                        case 'tablename':
                          return (
                            <CustomGridCellWrapper onClick={() => handleActionButtonClick(row)}>
                              {row[column.field]}
                            </CustomGridCellWrapper>
                          );
                        case 'game':
                          if (row[column.field] === 'texas') {
                            return `Texas Hold'em No Limit`;
                          } else {
                            return '';
                          }
                        case 'startTimeLabel':
                          return tableTypeSelected === TableType.Standard ? (
                            <span>{getLocalTime(row['startAtTime'])}</span>
                          ) : (
                            <span style={{ display: 'flex', justifyContent: 'flex-end' }}>
                              Start Anytime
                            </span>
                          );
                        case 'strRegistrationTime':
                          return tableTypeSelected === TableType.Standard ? (
                            <Tooltip title={row[column.field]}>
                              <span>{row[column.field]}</span>
                            </Tooltip>
                          ) : (
                            <span style={{ display: 'flex', justifyContent: 'flex-end' }}>
                              Start Anytime
                            </span>
                          );
                        case 'maxPlayers':
                          if (row[column.field] === 0) {
                            return tableTypeSelected === TableType.Standard
                              ? 'No Limit'
                              : row[column.field];
                          } else {
                            return row[column.field];
                          }
                        case 'isMultiFlight':
                          return row[column.field] === 0 ? 'No' : 'Yes';
                        case 'maxSeat':
                          if (row[column.field] === 0) {
                            return tableTypeSelected === TableType.Standard
                              ? 'No Limit'
                              : row[column.field];
                          } else {
                            return row[column.field];
                          }
                        case 'status':
                          if (row[column.field] === 'A') {
                            return (
                              <Button
                                style={{
                                  borderRadius: 10,
                                  backgroundColor: 'black',
                                  fontSize: '8px',
                                  borderColor: 'transparent',
                                  textTransform: 'none',
                                  color: 'white'
                                }}
                                variant='outlined'>
                                Announced
                              </Button>
                            );
                          } else if (row[column.field] === 'R') {
                            return (
                              <Button
                                style={{
                                  borderRadius: 10,
                                  backgroundColor: '#e98c26',
                                  fontSize: '8px',
                                  borderColor: 'transparent',
                                  textTransform: 'none',
                                  color: 'white'
                                }}
                                variant='outlined'>
                                Registering
                              </Button>
                            );
                          } else if (row[column.field] === 'P') {
                            return (
                              <Button
                                style={{
                                  borderRadius: 10,
                                  backgroundColor: '#628B48',
                                  fontSize: '8px',
                                  borderColor: 'transparent',
                                  textTransform: 'none',
                                  color: 'white'
                                }}
                                variant='outlined'>
                                In Progress
                              </Button>
                            );
                          } else {
                            return (
                              <Button
                                style={{
                                  borderRadius: 10,
                                  backgroundColor: '#383838',
                                  fontSize: '8px',
                                  borderColor: 'transparent',
                                  textTransform: 'none',
                                  color: 'white'
                                }}
                                variant='outlined'>
                                Cancelled
                              </Button>
                            );
                          }
                        default:
                          return row[column.field];
                      }
                    };

                    return (
                      <GridCell key={`gridCell-${index}-${subIndex}`}>
                        {column.headerName !== 'Tournament' && (
                          <GridCellWrapper
                            style={{ justifyContent: 'flex-start', color: hiJackColors.slateGray }}>
                            {column.headerName + ': '}
                          </GridCellWrapper>
                        )}
                        <GridCellWrapper
                          style={
                            column.headerName !== 'Tournament' &&
                            column.headerName !== 'Schedule' &&
                            column.headerName !== 'Game'
                              ? cellStyles.flex
                              : cellStyles.default
                          }>
                          {renderData()}
                        </GridCellWrapper>
                      </GridCell>
                    );
                  })}

                  <FooterActionWrapper>
                    <GridCellWrapper>
                      <GridCell style={{ justifyContent: 'flex-end' }}>
                        <BtnTournamentDetails onClick={() => handleActionButtonClick(row)}>
                          Show Details
                        </BtnTournamentDetails>
                      </GridCell>
                    </GridCellWrapper>
                  </FooterActionWrapper>
                </GridRow>
              ))}
            </GridContainer>
          ) : (
            <NoDataFoundWrapper>
              <img src={NoDataFound} alt='' style={{ width: 450, height: 250 }} />
              <h3>No Data Found</h3>
            </NoDataFoundWrapper>
          );
        }
      default:
        return null;
    }
  };

  return (
    <MainWrapper>
      <HeaderActionWrapper>
        <Grid container rowSpacing={1}>
          <Grid
            container
            item
            rowSpacing={1}
            columnSpacing={1}
            sx={{
              justifyContent: 'space-between',
              alignItems: 'center'
            }}>
            <Grid container item xs='auto'>
              <Grid item>
                {cashGames?.length > 0 && (
                  <BtnGameType
                    style={{
                      backgroundColor:
                        tableTypeSelected === TableType.CashGames ? hiJackColors.orange : 'initial',
                      padding: 0
                    }}
                    onClick={() => handleGameType(TableType.CashGames)}>
                    Cash Games
                  </BtnGameType>
                )}
              </Grid>
              <Grid item>
                {tournaments?.length > 0 && (
                  <BtnGameType
                    style={{
                      backgroundColor:
                        tableTypeSelected === TableType.Standard ? hiJackColors.orange : 'initial'
                    }}
                    onClick={() => handleGameType(TableType.Standard)}>
                    Tournaments
                  </BtnGameType>
                )}
              </Grid>
              <Grid item>
                {sitNGos?.length > 0 && (
                  <BtnGameType
                    style={{
                      backgroundColor:
                        tableTypeSelected === TableType.SitNGo ? hiJackColors.orange : 'initial'
                    }}
                    onClick={() => handleGameType(TableType.SitNGo)}>
                    Sit and Go
                  </BtnGameType>
                )}
              </Grid>
              <Grid item>
                <HeaderActionSubWrapper>
                  <DividerStyle orientation='vertical' />
                  <ViewActionWrapper>
                    <label>View: </label>
                    <BtnView
                      title={'Grid View'}
                      style={{
                        backgroundColor:
                          tableViewSelected === GameView.Grid ? hiJackColors.orange : 'transparent'
                      }}
                      onClick={() => handleView(GameView.Grid)}>
                      <span style={{ color: hiJackColors.white }} className='g-font-outlined'>
                        grid_view
                      </span>
                    </BtnView>
                    <BtnView
                      title={'List View'}
                      style={{
                        backgroundColor:
                          tableViewSelected === GameView.List ? hiJackColors.orange : 'transparent'
                      }}
                      onClick={() => handleView(GameView.List)}>
                      <span style={{ color: hiJackColors.white }} className='g-font-outlined'>
                        view_list
                      </span>
                    </BtnView>
                  </ViewActionWrapper>
                </HeaderActionSubWrapper>
              </Grid>
            </Grid>
            <Grid container item xs='auto' columnSpacing={1}>
              <Grid item>
                {tableTypeSelected === TableType.CashGames && (
                  <>
                    {/* Sorting Button and Direction Toggle */}
                    <ThemeProvider theme={darkTheme}>
                      <IconButton size='small' onClick={() => handleCashGamesSort()}>
                        {cashGamesSortingDirection === 'asc' ? (
                          <ArrowDownwardRoundedIcon fontSize='inherit' />
                        ) : (
                          <ArrowUpwardRoundedIcon fontSize='inherit' />
                        )}
                      </IconButton>
                    </ThemeProvider>

                    {/* Sorting Field Dropdown Menu */}
                    <ThemeProvider theme={defaultBtn}>
                      <Button
                        id='basic-button'
                        variant='outlined'
                        size='small'
                        endIcon={<ArrowDropDownRoundedIcon />}
                        onClick={handleMenuClick}>
                        {getSortingFieldLabel(cashGamesSortingField)}
                      </Button>
                    </ThemeProvider>

                    {/* Sorting Field Menu */}
                    <Menu
                      id='basic-menu'
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleMenuClose}
                      MenuListProps={{
                        'aria-labelledby': 'basic-button'
                      }}>
                      <MenuItem onClick={() => handleFieldSelection('gameID')}>Game ID</MenuItem>
                      <MenuItem onClick={() => handleFieldSelection('stakes')}>Stakes</MenuItem>
                      <MenuItem onClick={() => handleFieldSelection('tablelimit')}>Limit</MenuItem>
                      <MenuItem onClick={() => handleFieldSelection('seats')}>
                        Table capacity
                      </MenuItem>
                      <MenuItem onClick={() => handleFieldSelection('seatsTakenDisplay')}>
                        Seats taken
                      </MenuItem>
                      <MenuItem onClick={() => handleFieldSelection('tablelow')}>
                        Minimum buy-in
                      </MenuItem>
                    </Menu>
                  </>
                )}
              </Grid>
              <Grid item>{tableTypeSelected === TableType.CashGames && <TableFiltersMenu />}</Grid>
            </Grid>
          </Grid>
          {tableTypeSelected === TableType.CashGames &&
            (selectedFilters?.stake ||
              selectedFilters?.maxPlayer ||
              selectedFilters?.minBuyIn ||
              selectedFilters?.isHideEmptyGames) && (
              <Grid
                container
                item
                sx={{
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}>
                <Grid item></Grid>
                <Grid item>
                  {tableTypeSelected === TableType.CashGames && (
                    <ThemeProvider theme={darkTheme}>
                      {selectedFilters?.stake && (
                        <Chip
                          label='Stakes'
                          variant='outlined'
                          size='small'
                          onClick={() => handleClearFilter('stake', false)}
                          onDelete={() => handleClearFilter('stake', false)}
                        />
                      )}
                      {selectedFilters?.maxPlayer && (
                        <Chip
                          sx={{ marginLeft: '0.5rem' }}
                          label='No of Players'
                          variant='outlined'
                          size='small'
                          onClick={() => handleClearFilter('maxPlayer', false)}
                          onDelete={() => handleClearFilter('maxPlayer', false)}
                        />
                      )}
                      {selectedFilters?.minBuyIn && (
                        <Chip
                          sx={{ marginLeft: '0.5rem' }}
                          label='Buy-In'
                          variant='outlined'
                          size='small'
                          onClick={() => handleClearFilter('minBuyIn', false)}
                          onDelete={() => handleClearFilter('minBuyIn', false)}
                        />
                      )}
                      {selectedFilters?.isHideEmptyGames && (
                        <Chip
                          sx={{ marginLeft: '0.5rem' }}
                          label='Hide empty tables'
                          variant='outlined'
                          size='small'
                          onClick={() => handleClearFilter('isHideEmptyGames', false)}
                          onDelete={() => handleClearFilter('isHideEmptyGames', false)}
                        />
                      )}
                    </ThemeProvider>
                  )}
                  {(selectedFilters?.stake ||
                    selectedFilters?.maxPlayer ||
                    selectedFilters?.minBuyIn ||
                    selectedFilters?.isHideEmptyGames) && (
                    <BtnClearAll
                      sx={{ marginLeft: '0.5rem' }}
                      variant='text'
                      size='small'
                      onClick={() => handleClearFilter('stake', true)}>
                      Clear
                    </BtnClearAll>
                  )}
                </Grid>
              </Grid>
            )}
        </Grid>
      </HeaderActionWrapper>
      {TableViewRender()}
    </MainWrapper>
  );
};

const darkTheme = createTheme({
  palette: {
    mode: 'dark'
  }
});

const NoDataFoundWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'nowrap',
  justifyContent: 'center',
  alignItems: 'center'
});

// const PlayBtnOrange = styled(Button)({
//   border: `1px solid ${hiJackColors.orange}`,
//   backgroundColor: hiJackColors.white,
//   width: '25px',
//   height: '25px',
//   borderRadius: '100%',
//   position: 'relative',
//   minWidth: '10px',
//   '&:hover': {
//     backgroundColor: hiJackColors.white
//   }
// });

// const PlayBtnGreen = styled(Button)({
//   border: `1px solid ${hiJackColors.oliveDrab}`,
//   backgroundColor: hiJackColors.green,
//   width: '25px',
//   height: '25px',
//   borderRadius: '100%',
//   position: 'relative',
//   minWidth: '10px',
//   '&:hover': {
//     backgroundColor: hiJackColors.green
//   }
// });

// const PlayIcon = styled('span')({
//   position: 'absolute',
//   backgroundColor: hiJackColors.orange,
//   width: '10px',
//   height: '10px',
//   top: '50%',
//   left: '55%',
//   transform: 'translate(-50%, -50%)',
//   clipPath: 'polygon(100% 50%, 10% 0, 10% 100%)'
// });

// const PlayIconWhite = styled('span')({
//   position: 'absolute',
//   backgroundColor: hiJackColors.white,
//   width: '10px',
//   height: '10px',
//   top: '50%',
//   left: '55%',
//   transform: 'translate(-50%, -50%)',
//   clipPath: 'polygon(100% 50%, 10% 0, 10% 100%)'
// });

const FooterActionWrapper = styled(Box)({
  display: 'flex',
  paddingTop: 8
});

const DividerStyle = styled(Divider)({
  height: 25,
  alignSelf: 'center',
  backgroundColor: hiJackColors.charcoal
});

const GridContainer = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'flex-start',
  alignContent: 'flex-start',
  width: '100%',
  height: '100%',
  overflow: 'auto',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  marginTop: '8px'
});

const GridRow = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'nowrap',
  width: '185px',
  margin: 5,
  padding: 7,
  borderRadius: 8,
  backgroundColor: hiJackColors.darkSlateGray
});

const GridCell = styled(Box)({
  width: '100%',
  fontSize: 12,
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  fontFamily: 'Outfit'
});

const GridCellWrapper = styled(Box)({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap'
});

const CustomGridCellWrapper = styled(Box)({
  width: '100%',
  cursor: 'pointer',
  fontSize: '16px',
  color: hiJackColors.orange,
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textAlign: 'center',
  marginBottom: '8px',
  fontFamily: 'Outfit'
});

const ViewActionWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  paddingLeft: 7,
  columnGap: 5
});

const HeaderActionWrapper = styled(Box)({
  display: 'block',
  width: '100%',
  borderRadius: 8,
  backgroundColor: hiJackColors.midnightBlue,
  padding: '8px'
});

const HeaderActionSubWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  width: '50%',
  alignItems: 'center',
  justifyContent: 'flex-start'
});

const MainWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'nowrap',
  width: '100%',
  height: '100%',
  marginTop: 8
});

const TableHeadWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  width: '100%',
  height: 40,
  alignItems: 'center',
  marginTop: 8,
  marginBottom: 8,
  borderRadius: 8,
  backgroundColor: hiJackColors.black
});

const TableHeadItem = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  width: '100%',
  fontSize: '0.9rem',
  placeContent: 'center',
  fontFamily: 'Outfit',
  whiteSpace: 'nowrap'
});

const ListWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column'
});

const TableRow = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  width: '100%',
  textAlign: 'center',
  height: 46,
  marginBottom: 2,
  alignItems: 'center',
  backgroundColor: hiJackColors.darkSlateGray
});

const TableCell = styled(Box)({
  width: '100%',
  fontSize: '0.9rem',
  height: 40,
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  justifyContent: 'center',
  alignItems: 'center',
  fontFamily: 'Outfit',
  fontFeatureSettings: 'clig off, liga off'
});

const BtnJoin = styled(Button)({
  width: 80,
  height: 35,
  color: hiJackColors.white,
  backgroundColor: hiJackColors.orange,
  fontSize: 12,
  fontFamily: 'Outfit',
  fontWeight: 600,
  textTransform: 'none',
  '&:hover, &:focus': {
    backgroundColor: hiJackColors.midDarkOrange
  }
});

const BtnTournamentDetails = styled(Button)({
  width: '100%',
  height: 35,
  color: hiJackColors.white,
  backgroundColor: hiJackColors.orange,
  fontSize: 12,
  fontFamily: 'Outfit',
  fontWeight: 600,
  textTransform: 'none',
  '&:hover, &:focus': {
    backgroundColor: hiJackColors.midDarkOrange
  }
});

// const BtnObserve = styled(Button)({
//   width: 80,
//   height: 35,
//   color: hiJackColors.white,
//   backgroundColor: hiJackColors.cadetBlue,
//   fontSize: 12,
//   fontWeight: 600,
//   textTransform: 'none',
//   '&:hover, &:focus': {
//     backgroundColor: hiJackColors.midDarkOrange
//   }
// });

const BtnObserveCommingSoon = styled(Box)({
  width: 80,
  height: 35,
  color: hiJackColors.mediumLightGray,
  backgroundColor: hiJackColors.darkGray,
  fontSize: '12px',
  fontWeight: 600,
  fontFamily: 'Outfit',
  textTransform: 'none',
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'nowrap',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '4px',
  '&:hover, &:focus': {
    backgroundColor: hiJackColors.darkGray,
    cursor: 'not-allowed'
  },
  opacity: '1'
});

const BtnGameType = styled(Button)({
  width: 105,
  height: 30,
  color: hiJackColors.white,
  fontSize: 14,
  fontFamily: 'Outfit',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: hiJackColors.orange
  }
});

const BtnView = styled(Button)({
  fontSize: '1rem',
  fontFamily: 'Outfit',
  minWidth: 0,
  '&:hover': {
    backgroundColor: hiJackColors.orange
  }
});

const BtnClearAll = styled(Button)({
  color: hiJackColors.white,
  fontSize: 14,
  fontFamily: 'Outfit',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: hiJackColors.orange
  }
});

const defaultBtn = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          color: 'white',
          backgroundColor: '#D08E34',
          '&.active': {
            backgroundColor: '#D08E34',
            '&:hover': {
              backgroundColor: '#BE7E27'
            }
          },
          '&:hover': {
            color: 'white',
            backgroundColor: '#BE7E27'
          },
          '&:disabled': {
            color: 'white',
            opacity: '0.4'
          },
          textTransform: 'none',
          borderRadius: '50px'
        },
        outlined: {
          color: '#FFFFFF',
          borderColor: '#616161',
          backgroundColor: 'transparent',
          '&:hover': {
            color: '#FFFFFF',
            borderColor: '#616161',
            backgroundColor: '#FFFFFF14'
          },
          '&:disabled': {
            color: '#FFFFFF',
            opacity: '0.4',
            borderColor: '#616161'
          }
        }
      }
    }
  }
});

export default LobbyTable;
