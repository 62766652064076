import { useContext, useEffect, useRef, useState } from 'react';
import { Button, createTheme, ThemeProvider } from '@mui/material';
import GlobalContext from 'contexts/GlobalContext';
import { Decimal } from 'decimal.js';
import { useAppSelector } from 'hooks';
import useGameService from 'services/GameService';
import { convertToNumber, currencyFormatter, toLocaleStringNumber } from 'utils/functions';

import RaiseActionExpanded from './RaiseActionExpanded';

import 'components/UserActions/UserActions.scss';

interface RaiseProps {
  disabled: boolean;
  setIsActionDisabled: (value: boolean) => void;
}

const RaiseAction = ({disabled, setIsActionDisabled}: RaiseProps) => {
  const { move } = useAppSelector<any>((state) => state.currentTable.game);
  const playersBetDisplay = useAppSelector(
    (state) => state.currentTable.players[+move]['betDisplay']
  );
  const tableType = useAppSelector<any>((state) => state.currentTable.game.tabletype);
  const [toggleRaise, setToggleRaise] = useState(false);
  const [lastAction, setLastAction] = useState('');
  const { raise } = useGameService();
  const [amount, setAmount] = useState<any>(null);
  const { playSound } = useContext(GlobalContext);
  const [isDisableRaiseButton, setIsDisableRaiseButton] = useState(false);
  const raiseButtonRef = useRef<HTMLButtonElement>(null);
  const { min, playersStack, isAllIn, hasPotLimit, fullPot } = useAppSelector(
    (state) => state.currentTable.gameState.pokerActions.betMultiplier
  );
  const highestBetPlayer = useAppSelector((state) => state.currentTable.game.highestBetPlayer);
  const highestPlayerLBet = convertToNumber(highestBetPlayer?.betDisplay);

  useEffect(() => {
    if (
      (lastAction !== 'RAISE_SLIDER' && (toggleRaise || lastAction === 'ALL_IN')) ||
      lastAction === undefined ||
      lastAction === ''
    ) {
      return;
    }
    if (toggleRaise === false && (amount >= min || +amount === playersStack)) {
      const raiseAmount = Decimal.sum(amount, playersBetDisplay);
      setIsActionDisabled(true);
      raise(raiseAmount);
      setLastAction('');
    }

    setIsDisableRaiseButton(
      (amount < min && amount !== playersStack) || amount > playersStack || amount === '.'
    );
  }, [toggleRaise, amount, lastAction, min, raise]);

  const showPanel = () => {
    if (isAllIn) {
      setIsActionDisabled(true);
      raise(Decimal.sum(playersStack, playersBetDisplay));
    } else {
      playSound('notify');
      setToggleRaise(!toggleRaise);
      if (lastAction !== 'RAISE_SLIDER') {
        if (toggleRaise) setLastAction('RAISE_SLIDER');

        setAmount(min);
      }
    }
  };

  const hidePanel = (lastAction: string) => {
    setLastAction(lastAction);
    setToggleRaise(false);
  };

  const DefaultBtn = createTheme({
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            color: 'white',
            backgroundColor: '#008b00',
            '&.active': {
              backgroundColor: '#008b00',
              '&:hover': {
                backgroundColor: '#007200'
              }
            },
            '&:hover': {
              color: 'white',
              backgroundColor: '#007200'
            },
            '&:disabled': {
              color: 'white',
              opacity: '0.4'
            },
            fontSize: '1rem',
            fontWeight: '400',
            padding: '0.75rem 1rem',
            minWidth: 'unset',
            textTransform: 'none',
            width: '100%'
          }
        }
      }
    }
  });

  return (
    <>
      <ThemeProvider theme={DefaultBtn}>
        <Button
          ref={raiseButtonRef}
          size='small'
          onClick={showPanel}
          disabled={isDisableRaiseButton || disabled}
          className={`${toggleRaise && 'active'}`}>
          {toggleRaise
            ? highestPlayerLBet === 0
              ? `Bet ${
                  tableType !== 's'
                    ? toLocaleStringNumber(
                        convertToNumber(amount > playersStack ? playersStack : amount)
                      )
                    : currencyFormatter(amount > playersStack ? playersStack : amount)
                }`
              : `Raise: ${
                  tableType !== 's'
                    ? toLocaleStringNumber(
                        convertToNumber(amount > playersStack ? playersStack : amount)
                      )
                    : currencyFormatter(hasPotLimit ? (amount > fullPot ? fullPot : amount) : (amount > playersStack ? playersStack : amount))
                }`
            : highestPlayerLBet === 0
            ? 'Bet'
            : isAllIn
            ? `All In ${
                tableType !== 's'
                  ? toLocaleStringNumber(playersStack)
                  : currencyFormatter(playersStack)
              }`
            : 'Raise'}
        </Button>
      </ThemeProvider>
      {toggleRaise && !isAllIn && (
        <RaiseActionExpanded
          onBet={hidePanel}
          setAmount={(val: string) => {
            setLastAction('RAISE_SLIDER');
            setAmount(val);
          }}
          amount={amount}
          raiseButtonRef={raiseButtonRef}
        />
      )}
    </>
  );
};

export default RaiseAction;
