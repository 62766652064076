import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  serverTime: 0,
  featureFlags: {}
};

export const serverDetailsSlice = createSlice({
  name: 'serverDetails',
  initialState,
  reducers: {
    setServerDetails: (state, action) => {
      state.serverTime = action.payload;
    },
    setFeatureFlags: (state, action) => {
      state.featureFlags = action.payload;
    }
  }
});

export const { setServerDetails, setFeatureFlags } = serverDetailsSlice.actions;

export default serverDetailsSlice.reducer;
