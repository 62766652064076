import { CSSProperties } from 'react';
import React from 'react';
import { useAppSelector } from 'hooks';

const TableDetails = () => {
  const blindLevels = useAppSelector<any>((state) => state.currentTable.game.blindLevels);
  return <div style={TableDetailsContainerStyle}>{blindLevels}</div>;
};

export default TableDetails;

const TableDetailsContainerStyle: CSSProperties = {
  position: 'absolute',
  bottom: `4rem`,
  textAlign: 'center',
  fontFamily: 'Outfit',
  fontWeight: 600,
  fontStyle: 'normal',
  fontSize: `1.5rem`,
  lineHeight: `1.5rem`,
  color: 'rgba(255, 255, 255, 0.6)',
  zIndex: 2
};
