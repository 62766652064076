import React, { createContext, useState } from 'react';

type LobbyContextType = {
  selectedTournamentGameId: any;
  setSelectedTournamentGameId: (value: any) => void;
  stakeValue: number[];
  setStakeValue: (value: number[]) => void;
  maxPLValue: number[];
  setMaxPLValue: (value: any) => void;
  minBuyInValue: number[];
  setMinBuyInValue: (value: any) => void;
  isHideEmptyGame: boolean;
  setIsHideEmptyGame: (value: any) => void;
  isLogged: boolean;
  setIsLogged: (value: any) => void;
};

const LobbyContext = createContext<LobbyContextType>({
  selectedTournamentGameId: {},
  setSelectedTournamentGameId: () => {},
  stakeValue: [0, 0],
  setStakeValue: () => {},
  maxPLValue: [0, 0],
  setMaxPLValue: () => {},
  minBuyInValue: [0, 0],
  setMinBuyInValue: () => {},
  isHideEmptyGame: false,
  setIsHideEmptyGame: () => {},
  isLogged: false,
  setIsLogged: () => {}
});

export const LobbyProvider = ({ children }: any) => {
  const [selectedTournamentGameId, setSelectedTournamentGameId] = useState(null);
  const [stakeValue, setStakeValue] = useState<number[]>([0, 0]);
  const [maxPLValue, setMaxPLValue] = useState<number[]>([0, 0]);
  const [minBuyInValue, setMinBuyInValue] = useState<number[]>([0, 0]);
  const [isHideEmptyGame, setIsHideEmptyGame] = useState(false);
  const [isLogged, setIsLogged] = useState(false);
  return (
    <LobbyContext.Provider
      value={{
        selectedTournamentGameId,
        setSelectedTournamentGameId,
        stakeValue,
        setStakeValue,
        maxPLValue,
        setMaxPLValue,
        minBuyInValue,
        setMinBuyInValue,
        isHideEmptyGame,
        setIsHideEmptyGame,
        isLogged,
        setIsLogged
      }}>
      {children}
    </LobbyContext.Provider>
  );
};

export default LobbyContext;
