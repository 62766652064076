import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import Fab from '@mui/material/Fab';
import { useAppSelector } from 'hooks';
import useGameService from 'services/GameService';

const ExitTableButton = () => {
  const game = useAppSelector<any>((state) => state.currentTable.game);
  const chargeFrequency = useAppSelector((state) => state.currentTable.game.chargeFrequency);
  const seatId = useAppSelector<any>((state) => state.currentTable.currentPlayer.seatId);

  const isWaiting = true;
  // const isWaiting = useAppSelector<any>((state) => state.currentTable.currentPlayer.isWaiting);
  const playerStatus = game[`p${seatId}status`];
  const { leaveTable } = useGameService();
  const [openModal, setOpenModal] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const exitTable = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleLeave = () => {
    setOpenModal(false);
    leaveTable(true, +playerStatus === 4 || +playerStatus === 5 ? true : false);
    navigate('/');
    // setTimeout(() => window.close(), 1000);

    // Just in case the player open the game manually using URL
    const currentUrl = location.pathname;
    window.location.href = currentUrl;
  };

  return (
    <>
      <Fab variant='extended' onClick={exitTable}>
        Exit Table
      </Fab>
      <Dialog
        open={openModal}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'>
        <DialogTitle id='alert-dialog-title'>Are you sure?</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            {isWaiting
              ? `You are about to leave the table. Please confirm`
              : `You are about to leave the table. You will have a dead hand and ongoing ${chargeFrequency}-min charge timer will be forfeited. Please confirm`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleLeave}>Leave</Button>
          <Button onClick={handleClose} autoFocus>
            Stay
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ExitTableButton;
