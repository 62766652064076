import { useEffect, useState } from 'react';
import { a, useTransition } from 'react-spring';
import { useAppSelector } from 'hooks';

import { CardPosition } from './SeatWithPlayer/SeatCards';

export const VALID_ACTIONS = [
  'check',
  'call',
  'allin',
  'fold',
  'bet',
  'raise',
  'straddle',
  'No',
  'x2',
  'x3'
];

type PropType = {
  action: string;
  cardPostion: CardPosition;
};

const SeatAction = ({ action, cardPostion }: PropType) => {
  const timerSpeed = useAppSelector((state) => state.currentTable.game.speed);
  const [items, setItems] = useState<any[]>([]);

  const transition = useTransition(items, {
    from: { opacity: 0, bottom: 0 },
    enter: { opacity: 1, bottom: 100 },
    leave: { opacity: 0 },
    onRest: () => {
      setTimeout(() => {
        setItems([]);
      }, timerSpeed * 0.8 * 1000);
    },
    exitBeforeEnter: true
  });

  useEffect(() => {
    if (VALID_ACTIONS.includes(action)) {
      setItems([{}]);
    } else {
      setItems([]);
    }
  }, [action]);

  if (!VALID_ACTIONS.includes(action)) {
    return <></>;
  }

  return transition((props) => {
    const style = {
      ...props,
      zIndex: 2,
      background: action === 'straddle' ? 'green' : '#E98C26',
      bottom: props.bottom.to((x) => (!x ? 'unset' : `${x}px`)),
      left: cardPostion === CardPosition.Left ? '50%' : 'unset',
      right: cardPostion === CardPosition.Right ? '50%' : 'unset'
    };

    return (
      <a.div style={style} className='action-cell mdc-elevation--z6'>
        <span style={{ textTransform: 'uppercase' }} className='text'>
          {action}
        </span>
      </a.div>
    );
  });
};

export default SeatAction;
