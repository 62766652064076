import { FACEDOWN } from 'constants/gameConstants';

export type CardSuit = 'c' | 'd' | 'h' | 's';

export type Card =
  | {
      rank?: string;
      suit?: CardSuit;
    }
  | null
  | undefined;

export const isFaceDownCard = (card: string) => {
  return card === FACEDOWN;
};

export const isValidCard = (card: string) => {
  return card !== null && /^([jqka]|[2-9]|(10))([dhcs])$/gi.test(card);
};

export const mapToCard = (card: any): Card => {
  if (!isValidCard(card)) {
    return null as Card;
  }

  return {
    card,
    suit: card.charAt(card.length - 1).toLowerCase(),
    rank: card.substring(0, card.length - 1)
  } as Card;
};
