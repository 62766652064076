import { a, useSpring } from 'react-spring';
import ChipImage from 'assets/images/chip.svg';
import { BET_HEIGHT, BET_WIDTH } from 'constants/gameConstants';

type ChipType = {
  delay: number;
  from: {
    top: number;
    left: number;
  };
  to: {
    top: number;
    left: number;
  };
  isStartAnimate?: boolean;
};

const Chip = ({ delay, from, to, isStartAnimate = false }: ChipType) => {
  const { opacity, ...props } = useSpring({
    from: { ...from, opacity: 1 },
    to: isStartAnimate ? { ...to, opacity: 0 } : { ...from, opacity: 1 },
    delay: delay
  });

  return (
    <a.div
      style={{
        position: 'absolute',
        zIndex: 2,
        ...props,
        opacity: opacity.to((i) => (i < 0.05 ? i : 1))
      }}>
      <img src={ChipImage} alt='' style={{ width: BET_WIDTH, height: BET_HEIGHT, zIndex: 2 }} />
    </a.div>
  );
};

export default Chip;
