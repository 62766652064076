import styled from '@emotion/styled';
import Button from '@mui/material/Button';
import { hiJackColors } from 'constants/colorConstants';
import { useAppDispatch, useAppSelector } from 'hooks';
import { TableType, updateGameMode, updateTableType } from 'reducers/lobby';

import { GameMode, GameModeLabel } from 'components/LobbyTable/LobbyTable';
// const CommingSoonTooltip = withStyles({
//   tooltip: {
//     color: hiJackColors.white,
//     backgroundColor: hiJackColors.red + '!important'
//   }
// })(Tooltip);
const LobbyHeaderAction = () => {
  const dispatch = useAppDispatch();
  const { selectedGameMode } = useAppSelector((state) => state.lobby);

  const handleGameSelected = (lobbyType: string) => {
    dispatch(updateGameMode(lobbyType));
    dispatch(updateTableType(TableType.CashGames));
  };

  return (
    <MainWrapper>
      <LeftWrapper>
        <BtnGameMode
          style={{
            backgroundColor:
              selectedGameMode === GameMode.texas ? hiJackColors.orange : hiJackColors.lightDarkGray
          }}
          onClick={() => handleGameSelected(GameMode.texas)}>
          {GameModeLabel.texas}
        </BtnGameMode>
        <BtnGameMode
          style={{
            backgroundColor:
              selectedGameMode === GameMode.omaha ? hiJackColors.orange : hiJackColors.lightDarkGray
          }}
          onClick={() => handleGameSelected(GameMode.omaha)}>
          {GameModeLabel.omaha}
        </BtnGameMode>
        {/* <CommingSoonTooltip title='Coming Soon!' followCursor>
          <BtnOmahaCommingSoon sx={{ bgcolor: 'text.disabled', color: 'background.paper', p: 2 }}>
            {GameModeLabel.omaha}
          </BtnOmahaCommingSoon>
        </CommingSoonTooltip> */}
      </LeftWrapper>
      {/* <RightWrapper>
        <Typography gutterBottom style={{ color: hiJackColors.white, fontSize: 14 }}>
          <span style={{ fontSize: 14, color: hiJackColors.oliveDrab }} className='g-font-outlined'>
            lock
          </span>
          Private Tables:
        </Typography>
        <BtnCreateJoin>Create</BtnCreateJoin>
        <BtnCreateJoin>Join</BtnCreateJoin>
        <CommingSoonTooltip title='Coming Soon!' followCursor>
          <BtnCreateJoinCommingSoon
            sx={{ bgcolor: 'text.disabled', color: 'background.paper', p: 2 }}>
            Create
          </BtnCreateJoinCommingSoon>
        </CommingSoonTooltip>
        <CommingSoonTooltip title='Coming Soon!' followCursor>
          <BtnCreateJoinCommingSoon
            sx={{ bgcolor: 'text.disabled', color: 'background.paper', p: 2 }}>
            Join
          </BtnCreateJoinCommingSoon>
        </CommingSoonTooltip>
      </RightWrapper> */}
    </MainWrapper>
  );
};

// const BtnOmahaCommingSoon = styled(Box)({
//   width: '150px',
//   height: '50px',
//   color: hiJackColors.mediumLightGray,
//   backgroundColor: hiJackColors.darkGray,
//   fontSize: '14px',
//   fontWeight: 600,
//   fontFamily: 'Outfit',
//   textTransform: 'none',
//   display: 'flex',
//   flexDirection: 'column',
//   flexWrap: 'nowrap',
//   alignItems: 'center',
//   justifyContent: 'center',
//   borderRadius: '4px',
//   '&:hover, &:focus': {
//     backgroundColor: hiJackColors.darkGray,
//     cursor: 'not-allowed'
//   },
//   opacity: '1'
// });

// const BtnCreateJoinCommingSoon = styled(Box)({
//   width: '100px',
//   height: '50px',
//   color: hiJackColors.mediumLightGray,
//   backgroundColor: hiJackColors.darkGray,
//   fontSize: '14px',
//   fontWeight: 600,
//   fontFamily: 'Outfit',
//   textTransform: 'none',
//   display: 'flex',
//   flexDirection: 'column',
//   flexWrap: 'nowrap',
//   alignItems: 'center',
//   justifyContent: 'center',
//   borderRadius: '4px',
//   '&:hover, &:focus': {
//     backgroundColor: hiJackColors.darkGray,
//     cursor: 'not-allowed'
//   },
//   opacity: '1'
// });

const MainWrapper = styled('div')({
  width: '100%',
  display: 'flex',
  flexDirection: 'row'
});

const LeftWrapper = styled('div')({
  width: '50%',
  display: 'flex',
  flexDirection: 'row',
  columnGap: '12px',
  justifyContent: 'flex-start'
});

// const RightWrapper = styled('div')({
//   width: '50%',
//   display: 'flex',
//   flexDirection: 'row',
//   columnGap: '12px',
//   justifyContent: 'flex-end',
//   alignItems: 'center'
// });

const BtnGameMode = styled(Button)({
  width: '150px',
  height: '50px',
  color: hiJackColors.white,
  fontSize: '14px',
  fontFamily: 'Outfit',
  textTransform: 'none',
  backgroundColor: hiJackColors.cadetBlue,
  '&:hover': {
    backgroundColor: hiJackColors.orange
  }
});

// const BtnCreateJoin = styled(Button)({
//   width: '100px',
//   height: '50px',
//   color: hiJackColors.white,
//   fontSize: '14px',
//   textTransform: 'none',
//   backgroundColor: hiJackColors.cadetBlue,
//   '&:hover': {
//     backgroundColor: hiJackColors.orange
//   }
// });

export default LobbyHeaderAction;
