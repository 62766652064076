import { CSSProperties, useContext, useEffect, useState } from 'react';
import { a, useSpring } from 'react-spring';
import GameContext from 'contexts/GameContext';
import { useAppSelector } from 'hooks';
import { getPlayerStatus } from 'utils/functions';

import { Position } from 'components/Table/OvalTable';

import { CardPosition, SeatCards } from './SeatWithPlayer/SeatCards';
import OpenSeat from './OpenSeat';
import SeatAction from './SeatAction';
import { SeatWithPlayer } from './SeatWithPlayer';
import Spotlight from './Spotlight';

type SeatType = {
  seat: any;
  index: number;
  guid: any;
  isFaceCardUp: boolean;
  position: Position;
  currentSeatPositionLength: number;
  currentPlayerSeatId: number;
  seatId: number;
  cards: any;
  seatPositionsLength: number;
};

export const SeatContainer = ({
  seat,
  isFaceCardUp,
  position,
  currentSeatPositionLength,
  currentPlayerSeatId,
  seatId,
  cards,
  seatPositionsLength
}: SeatType) => {
  const game = useAppSelector<any>((state) => state.currentTable.game);
  const playerStatus = useAppSelector<number>(
    (state) => (state.currentTable.game as any)[`p${seatId}status`]
  );
  const { isWaiting } = getPlayerStatus(game, seatId);
  const { seatPositions, setIsPlayerReady } = useContext(GameContext);

  const isShowSpotlight = seat?.isShowSpotlight ?? false;

  const { positionLength } = useSpring({
    positionLength: currentSeatPositionLength,
    onResolve() {
      setIsPlayerReady(true);
    }
  });

  const [cardPosition, setCardPosition] = useState<CardPosition>(CardPosition.Right);

  useEffect(() => {
    if (position?.isLeft || (position?.isCenter && position?.isTop)) {
      setCardPosition(CardPosition.Left);
    } else {
      setCardPosition(CardPosition.Right);
    }
  }, [position]);

  //TODO: Refactor Status Check
  return (
    <>
      {!isWaiting &&
        (playerStatus < 4 ||
          playerStatus === 6 ||
          playerStatus === 8 ||
          playerStatus === 10 ||
          playerStatus === 11 ||
          playerStatus === 12 ||
          +playerStatus > 18) && (
          <SeatCards
            seatId={seatId}
            cards={cards}
            position={position}
            cardPosition={cardPosition}
          />
        )}
      <a.div
        style={{
          ...SeatContainerStyle,
          left: positionLength.to(
            (t) => seatPositions[(t < 0 ? seatPositionsLength + t : t) | 0]?.left
          ),
          top: positionLength.to(
            (t) => seatPositions[(t < 0 ? seatPositionsLength + t : t) | 0]?.top
          )
        }}>
        {seat.ID ? (
          <>
            {playerStatus !== 4 && (
              <SeatAction action={game[`p${seatId}action`]} cardPostion={cardPosition} />
            )}
            <SeatWithPlayer
              {...(seat as any)}
              currentPlayerSeatId={currentPlayerSeatId}
              seatId={seatId}
              action={game[`p${seatId}action`]}
              isFaceCardUp={isFaceCardUp}
              cardPosition={cardPosition}
            />
          </>
        ) : (
          <OpenSeat seatId={seatId} />
        )}

        {isShowSpotlight && <Spotlight seatId={seatId} position={position} />}
      </a.div>
    </>
  );
};

const SeatContainerStyle: CSSProperties = {
  position: 'absolute'
};
