import { createSlice } from '@reduxjs/toolkit';

import { GameMode } from 'components/LobbyTable/LobbyTable';
import { TTournament } from 'components/TournamentTable/types';

export enum GameType {
  All = 'all',
  Texas = 'texas',
  Omaha = 'omaha'
}

export enum TableType {
  CashGames = 'cashGames',
  Standard = 'Standard',
  SitNGo = 'SitAndGo',
  Bounty = 'Bounty',
  FreezeOut = 'Freezeout',
  Satellite = 'Satellite'
}

export enum GameView {
  List = 0,
  Grid = 1
}

export type TSelectedFilters = {
  stake: any[] | null;
  maxPlayer: any[] | null;
  minBuyIn: any[] | null;
  isHideEmptyGames: boolean;
};

interface IState {
  groupedCashGames: any[];
  texasCashGames: any[];
  groupedOmahaCashGames: any[];
  omahaCashGames: any[];
  hasFilter: boolean;
  sortingField: string | null;
  sortingDirection: string | null;
  tableViewSelected: GameView;
  selectedFilters: TSelectedFilters | null | undefined;
  tableTypeSelected: TableType;
  selectedGameMode: GameMode;
  selectedTournament: TTournament | null | undefined;
  gameTypeFiltered: any[];
  //tournament
  tournament: {
    tournamentList: any[];
    sitAndGoList: any[];
  };
}

const initialState: IState = {
  //cash games
  groupedCashGames: [],
  texasCashGames: [],
  groupedOmahaCashGames: [],
  omahaCashGames: [],
  hasFilter: false,
  sortingField: null,
  sortingDirection: 'asc',
  tableViewSelected: GameView.List,
  selectedFilters: null,
  tableTypeSelected: TableType.CashGames,
  gameTypeFiltered: [],
  selectedGameMode: GameMode.texas,
  //tournament
  tournament: {
    tournamentList: [],
    sitAndGoList: []
  },
  selectedTournament: null
};

export const lobbySlice = createSlice({
  name: 'lobby',
  initialState,
  reducers: {
    updateTableView: (state, action) => {
      state.tableViewSelected = action.payload;
    },
    updateTableType: (state, action) => {
      state.tableTypeSelected = action.payload;
    },
    updateGameTypeFilter: (state, action) => {
      state.gameTypeFiltered = action.payload;
    },
    updateSelectedFilters: (state, action) => {
      state.selectedFilters = action.payload;
    },
    updateGroupedCashGames: (state, action) => {
      state.groupedCashGames = action.payload;
    },
    updateTexasCashGames: (state, action) => {
      state.texasCashGames = action.payload;
    },
    updateGroupedOmahaCashGames: (state, action) => {
      state.groupedOmahaCashGames = action.payload;
    },
    updateOmahaCashGames: (state, action) => {
      state.omahaCashGames = action.payload;
    },
    updateFilterState: (state, action) => {
      state.hasFilter = action.payload;
    },
    updateGameMode: (state, action) => {
      state.selectedGameMode = action.payload;
    },
    updateSelectedTournament: (state, action) => {
      state.selectedTournament = action.payload;
    },
    setSortingField: (state, action) => {
      state.sortingField = action.payload;
    },
    setSortingDirection: (state, action) => {
      state.sortingDirection = action.payload;
    },
    setTournamentList: (state, action) => {
      state.tournament.tournamentList = action.payload;
    },
    setSitAndGoList: (state, action) => {
      state.tournament.sitAndGoList = action.payload;
    }
  }
});

export const {
  updateTableView,
  updateTableType,
  updateGameTypeFilter,
  updateSelectedFilters,
  updateGroupedCashGames,
  updateTexasCashGames,
  updateGroupedOmahaCashGames,
  updateOmahaCashGames,
  updateFilterState,
  updateGameMode,
  updateSelectedTournament,
  setSortingField,
  setSortingDirection,
  setTournamentList,
  setSitAndGoList
} = lobbySlice.actions;

export default lobbySlice.reducer;
