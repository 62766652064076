import { useCallback, useEffect, useState } from 'react';
import { Button, createTheme, ThemeProvider } from '@mui/material';
import { useAppSelector } from 'hooks';
import useGameService from 'services/GameService';

import 'components/UserActions/UserActions.scss';

const StraddleButton = () => {
  const { straddle } = useGameService();
  const seatId = useAppSelector<any>((state) => state.currentTable.currentPlayer.seatId);
  const game = useAppSelector<any>((state) => state.currentTable.game);
  const hasStraddle = useAppSelector<any>((state) => state.currentTable.game?.hasStraddle);
  const straddleValue = game.bb * 2;

  const [isPlayerStraddled, setIsPlayerStraddled] = useState<boolean>();

  useEffect(() => {
    const hasStraddleArr = hasStraddle?.split('|');
    const isCurrentPlayerStraddled = hasStraddleArr?.length > 1 && +hasStraddleArr[1] === seatId;
    setIsPlayerStraddled(isCurrentPlayerStraddled);
  }, [hasStraddle, seatId]);

  const internalOnClick = useCallback(() => {
    straddle(isPlayerStraddled ? 0 : straddleValue);
    setIsPlayerStraddled((prev) => !prev);
  }, [isPlayerStraddled, straddle, straddleValue]);

  return (
    <ThemeProvider theme={isPlayerStraddled ? SelectedBtn : DefaultBtn}>
      <Button size='small' onClick={internalOnClick}>
        Straddle
      </Button>
    </ThemeProvider>
  );
};

const DefaultBtn = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          color: 'white',
          backgroundColor: '#5b6671',
          '&:hover': {
            color: 'white',
            backgroundColor: '#37393b'
          },
          '&:disabled': {
            color: 'white',
            opacity: '0.4'
          },
          fontSize: '1rem',
          fontWeight: '400',
          padding: '0.75rem 1rem',
          minWidth: 'unset',
          textTransform: 'none',
          width: '100%'
        }
      }
    }
  }
});

const SelectedBtn = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          color: 'white',
          backgroundColor: '#008000',
          '&:hover': {
            color: 'white',
            backgroundColor: '#005800'
          },
          '&:disabled': {
            color: 'white',
            opacity: '0.4'
          },
          fontSize: '1rem',
          fontWeight: '400',
          padding: '0.75rem 1rem',
          minWidth: 'unset',
          textTransform: 'none',
          width: '100%'
        }
      }
    }
  }
});

export default StraddleButton;
