import React from 'react';
import styled from '@emotion/styled';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Box from '@mui/material/Box';
import { hiJackColors } from 'constants/colorConstants';
import { numberFormatter } from 'utils/functions';
export type TGameDetailProps = {
  data: TGameDetail;
};

export type TGameDetail = {
  gameID: number | string;
  game: string;
  isPrivate: boolean;
  tableType: string;
  totalPot: number;
};

const GameDetails = (props: TGameDetailProps) => {
  const { data } = props;
  return (
    <MainGameDetail>
      <div>Game ID:</div>
      <ValueWrapper>
        {data.isPrivate && (
          <FontAwesomeIcon icon={faLock} style={{ fontSize: 12, color: hiJackColors.oliveDrab }} />
        )}{' '}
        {data.gameID}
      </ValueWrapper>

      <div>Pot:</div>
      <ValueWrapper>
        {data.tableType === 'm' || data.tableType === 't'
          ? numberFormatter(+data?.totalPot)
          : '$' + numberFormatter(data?.totalPot)}
      </ValueWrapper>
    </MainGameDetail>
  );
};

const MainGameDetail = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  color: hiJackColors.white,
  fontSize: '10px'
});

const ValueWrapper = styled(Box)({
  background: hiJackColors.darkGray,
  padding: '0px 3px'
});

export default GameDetails;
