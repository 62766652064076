import React, { CSSProperties, useContext, useEffect } from 'react';
import Fab from '@mui/material/Fab';
import GameContext from 'contexts/GameContext';
import { useAppSelector } from 'hooks';
import useGameService from 'services/GameService';

const SitOutButton = () => {
  const game = useAppSelector<any>((state) => state.currentTable.game);
  const hand = useAppSelector((state) => state.currentTable.game.hand);
  const seatId = useAppSelector<any>((state) => state.currentTable.currentPlayer.seatId);

  const { sitOut, imBack } = useGameService();
  const { isSatOut, setIsSatOut } = useContext(GameContext);

  const playerStatus = game[`p${seatId}status`];

  const comeBack = () => {
    if (hand === '' || +hand > 3) {
      imBack(seatId);
      setIsSatOut(false);
    }
  };

  const triggerSatOut = () => {
    if (isSatOut) {
      comeBack();
    } else {
      if (playerStatus === 2) {
        imBack(seatId);
      }
      setIsSatOut(true);
      sitOut();
    }
  };

  useEffect(() => {
    if (+game[`p${seatId}status`] === 0) {
      setIsSatOut(false);
    }
  }, [game[`p${seatId}status`]]);

  return (
    <>
      {playerStatus === 1 ? (
        <Fab onClick={comeBack} variant='extended'>
          I'm Back
        </Fab>
      ) : (
        <Fab
          onClick={triggerSatOut}
          variant='extended'
          style={isSatOut ? { ...clickedButton } : { ...buttonStyle }}
        >
          {isSatOut ? "I'm Back" : 'Sit Out Next Hand'}
        </Fab>
      )}
    </>
  );
};

const buttonStyle: CSSProperties = {
  marginLeft: '1.5rem'
};
const clickedButton: CSSProperties = {
  backgroundColor: 'green',
  marginLeft: '1.5rem'
};

export default SitOutButton;
