export const hiJackColors = {
  orange: '#e98c26',
  darkOrange: '#cc6b47',
  midDarkOrange: '#c87214',
  midnightBlue: '#161b1f',
  cadetBlue: '#5A6771',
  darkSlateGray: '#24292E',
  slateGray: '#8C9AA6',
  darkGray: '#383838',
  lightDarkGray: '#3C4348',
  mediumGray: '#888',
  mediumLightGray: '#555',
  gunMetal: '#3D454B',
  charcoal: '#2D3135',
  black: '#000000',
  white: '#ffffff',
  tealBlue: '#508991',
  tealBlueLight: '#508999',
  deepMaroon: '#8C3041',
  red: '#C42021',
  oliveDrab: '#628B48',
  green: '#4fc853',
  darkJungleGreen: '#012425'
};
