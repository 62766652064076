import React, { useCallback, useContext, useState } from 'react';
import { Button, createTheme, ThemeProvider } from '@mui/material';
import GameContext from 'contexts/GameContext';
import { useAppSelector } from 'hooks';
import _ from 'lodash';
import { Player } from 'reducers/currentTable';
import useGameService from 'services/GameService';
import { convertToNumber } from 'utils/functions';

import FoldConfirmationModal from 'components/UserActions/FoldConfirmationModal';

import 'components/UserActions/UserActions.scss';

interface FoldProps {
  disabled: boolean;
  setIsActionDisabled: (value: boolean) => void;
}

const Fold = ({ disabled, setIsActionDisabled }: FoldProps) => {
  const { dontAskAgain } = useContext(GameContext);
  const { hand, highestBetPlayer } = useAppSelector((state) => state.currentTable.game);
  const { fold, check } = useGameService();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const players = useAppSelector((state) => state.currentTable.players);
  const seatId = useAppSelector((state) => state.currentTable.currentPlayer.seatId);
  const playersLBet = convertToNumber(players[+seatId]?.betDisplay);
  const highestPlayerLBet = convertToNumber(highestBetPlayer?.betDisplay);
  const gameHand = +hand;

  const isBB = useAppSelector((state) => state.currentTable.currentPlayer.isBB);

  const isPostedBlind = useAppSelector((state) => state.currentTable.currentPlayer.isPostedBlind);
  const isStraddle = useAppSelector((state) => state.currentTable.currentPlayer.isStraddle);
  const isCannotCheck = useCallback(() => {
    return playersLBet < highestPlayerLBet && highestPlayerLBet > 0;
  }, [highestPlayerLBet, playersLBet]);
  const someRaise = (players: Player[]) => {
    return _.some(players ?? [], (player) =>
      _.includes(['raise', 'allin', 'straddle'], player?.action?.toLowerCase())
    );
  };
  const someBet = (players: Player[]) => {
    return _.some(players ?? [], (player) => _.includes(['bet'], player?.action?.toLowerCase()));
  };

  const isCall = useCallback(() => {
    if (
      playersLBet !== highestPlayerLBet &&
      gameHand === 5 &&
      ((!isBB && !isPostedBlind && !isStraddle) ||
        (isBB && someRaise(players) && highestPlayerLBet > playersLBet) ||
        (isPostedBlind && isCannotCheck()))
    ) {
      return true;
    }

    if (gameHand > 5 && (someRaise(players) || someBet(players))) {
      return true;
    }

    return false;
  }, [gameHand, isBB, isCannotCheck, isPostedBlind, isStraddle, players]);

  const confirmation = () => {
    if (isCall() || dontAskAgain) {
      setIsActionDisabled(true);
      fold();
    } else {
      setShowConfirmationModal(true);
    }
  };

  const confirmationAnswer = (answer: any) => {
    switch (answer) {
      case 1:
        setIsActionDisabled(true);
        fold();
        break;
      case 2:
        setIsActionDisabled(true);
        check();
        break;
    }

    setShowConfirmationModal(false);
  };

  const DefaultBtn = createTheme({
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            color: 'white',
            backgroundColor: '#d80000',
            '&:hover': {
              color: 'white',
              backgroundColor: '#8b0000'
            },
            '&:disabled': {
              color: 'white',
              opacity: '0.4'
            },
            fontSize: '1rem',
            fontWeight: '400',
            padding: '0.75rem 1rem',
            minWidth: 'unset',
            textTransform: 'none',
            width: '100%'
          }
        }
      }
    }
  });

  return (
    // <div onClick={fold} className="poker-action-btn">
    //   <div className="poker-action-content">
    //     <span className="text">Fold</span>
    //   </div>
    // </div>
    <>
      <ThemeProvider theme={DefaultBtn}>
        <Button size='small' onClick={confirmation} disabled={disabled}>
          Fold
        </Button>
      </ThemeProvider>
      {showConfirmationModal && <FoldConfirmationModal confirmationAnswer={confirmationAnswer} />}
    </>
  );
};

export default Fold;
