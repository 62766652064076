import VoteNo from './VoteNoAction';
import VoteThrice from './VoteThriceAction';
import VoteTwice from './VoteTwiceAction';

import 'components/UserActions/UserActions.scss';
import 'components/UserActions/PokerActions/PokerActions.scss';

type MultiRunPropType = {
  runs: string;
  isLastPlayer: boolean;
  lastPlayerName: string;
};
const MultiRunActions = ({ runs, isLastPlayer, lastPlayerName }: MultiRunPropType) => {
  const vote = runs.split('/')[1];
  const initialVote = vote === '0';
  const runTwice = vote === '2';
  const runThrice = vote === '3';

  const challengeMsg = isLastPlayer
    ? `Waiting on Other Players...`
    : `${lastPlayerName} invited you to Run-It-${runTwice ? 'Twice' : 'Thrice'}.`;
  const initialVoteMsg = `Multi-Run?`;
  const message = initialVote ? initialVoteMsg : challengeMsg;

  return (
    <div className='player-actions'>
      <div className='poker-actions'>
        <div
          style={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'row',
            gap: '1rem',
            width: '100%',
            paddingTop: '1rem',
            paddingBottom: '1rem',
            justifyContent: 'center',
            zIndex: 3
          }}>
          {message}
          <VoteNo />
          {(initialVote || runTwice) && <VoteTwice />}
          {(initialVote || runThrice) && <VoteThrice />}
        </div>
      </div>
    </div>
  );
};

export default MultiRunActions;
