import { useContext } from 'react';
import React from 'react';
import { Button, createTheme, Modal, ThemeProvider, Typography } from '@mui/material';
import { Box } from '@mui/system';
import GlobalContext from 'contexts/GlobalContext';
import { useAppSelector } from 'hooks';
import { FRONT_OFFICE_LOCAL_URL } from 'utils/urls';

const AddFundsModal = () => {
  const { openAddFunds, setOpenAddFunds } = useContext(GlobalContext);

  const addFundsMsg = useAppSelector<any>((state) => state.currentTable.game.addFundsMsg);
  // const errorMsg = useAppSelector((state) => state.user.errorMsg);
  // const [msg, setMsg] = useState('');

  let cashierLink =
    'https://account.' +
    window.location.hostname.split('.')[1] +
    '.' +
    window.location.hostname.split('.')[2] +
    '/hijack/cashier/deposit';
  if (window.location.hostname === 'hijack.local') {
    cashierLink = `${FRONT_OFFICE_LOCAL_URL}/hijack/cashier/deposit`;
  }

  const handleAddFunds = () => {
    window.open(cashierLink);
    setTimeout(() => window.location.reload(), 100);
  };

  const standUp = () => {
    setOpenAddFunds(false);
  };

  const exitTable = () => {
    setOpenAddFunds(false);
    setTimeout(() => window.close(), 100);
  };

  return (
    <Modal open={openAddFunds}>
      <Box sx={modalStyle}>
        <Typography
          id='modal-modal-title'
          variant='h5'
          component='h2'
          sx={{ textAlign: 'center', marginBottom: '2rem' }}>
          ADD FUNDS
        </Typography>

        <div
          style={{
            fontSize: '1.5rem',
            textAlign: 'center',
            marginBottom: '2rem'
          }}>
          {addFundsMsg}
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '2rem',
            gap: '2rem'
          }}>
          <ThemeProvider theme={CancelBtnStyle}>
            <Button
              sx={{ display: 'block', opacity: '.8' }}
              variant='contained'
              onClick={exitTable}>
              EXIT TABLE
            </Button>
          </ThemeProvider>
          <ThemeProvider theme={AddFundsBtnStyle}>
            <Button sx={{ display: 'block' }} variant='contained' onClick={handleAddFunds}>
              GO TO CASHIER
            </Button>
          </ThemeProvider>
          <ThemeProvider theme={CancelBtnStyle}>
            <Button sx={{ display: 'block', opacity: '.8' }} variant='contained' onClick={standUp}>
              OBSERVE
            </Button>
          </ThemeProvider>
        </div>
      </Box>
    </Modal>
  );
};

export default AddFundsModal;

const modalStyle = {
  position: 'absolute' as const,
  top: '40%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: '#012425',
  color: 'white',
  padding: '4rem 7rem',
  width: '40rem',
  borderRadius: '0.5rem'
};

const CancelBtnStyle = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          color: 'white',
          backgroundColor: '#5b6671',
          '&:hover': {
            color: 'white',
            backgroundColor: '#37393b'
          },
          '&:disabled': {
            color: 'white',
            opacity: '0.4'
          },
          fontSize: '1rem',
          fontWeight: '400',
          padding: '0.5rem 1rem',
          minWidth: 'unset',
          textTransform: 'none',
          width: '100%'
        }
      }
    }
  }
});

const AddFundsBtnStyle = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          color: 'white',
          backgroundColor: '#E98C26',
          '&:hover': {
            color: 'white',
            backgroundColor: '#A7651C'
          },
          '&:disabled': {
            color: 'white',
            backgroundColor: '#E98C26',
            opacity: '0.4'
          },
          fontSize: '1rem',
          fontWeight: '400',
          padding: '0.5rem 1rem',
          minWidth: 'unset',
          textTransform: 'none',
          width: '100%'
        }
      }
    }
  }
});
