import './MaintenanceScheduleNotification.scss';

const MaintenanceScheduleNotification = ({ children }: any) => {
  return (
    <div className='marquee'>
      <div className='marquee__content'>{children}</div>
    </div>
  );
};

export default MaintenanceScheduleNotification;
