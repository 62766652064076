import React, { useContext, useEffect } from 'react';
import { Badge, BadgeProps, styled } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ScaleContext from 'contexts/ScaleContext';
import TableHistoryContext from 'contexts/TableHistoryContext';
import TexasChatContext from 'contexts/TexasChatContext';
import { useAppSelector } from 'hooks';
import useGameService from 'services/GameService';
import { ZIndex } from 'styles';

import styles from './GameCommsMenu.module.scss';

const GameComms: React.FC = () => {
  const { isOpen: isOpenTableHistory, setIsOpen: setIsOpenTableHistory } =
    useContext(TableHistoryContext);
  const { isOpen: isOpenTexasChat, setIsOpen: setIsOpenTexasChat } = useContext(TexasChatContext);
  const { scale } = useContext(ScaleContext);
  const guid = useAppSelector((state) => state.user.playerGUID);
  const { game: gameTable, gameID } = useAppSelector((state) => state.currentTable.game);
  const liveChatNotifications = useAppSelector((state) => state.liveChatNotifications);
  const unreadMessageCount = isOpenTexasChat
    ? 0
    : liveChatNotifications.find((f) => f.gameID === gameID)?.unreadMessageCount ?? 0;
  const { clearUnreadMessage } = useGameService();

  const toggleMenu = (type: 'history' | 'chat') => {
    (setIsOpenTableHistory as any)((prev: boolean) => type === 'history' && !prev);
    (setIsOpenTexasChat as any)((prev: boolean) => type === 'chat' && !prev);
  };

  const theme = createTheme({
    components: {
      MuiToggleButtonGroup: {
        styleOverrides: {
          root: {
            fontSize: '1rem'
          },
          grouped: {
            border: 0,
            '&:not(:first-of-type)': {
              borderRadius: '0.25rem'
            },
            '&:first-of-type': {
              borderRadius: '0.25rem'
            }
          }
        }
      },
      MuiToggleButton: {
        styleOverrides: {
          root: {
            color: 'white',
            fontWeight: 'normal',
            backgroundColor: 'black',
            textTransform: 'none',
            '&:hover': {
              backgroundColor: '#1a1a1a'
            },
            marginRight: '0.5rem',
            ':last-child': {
              marginRight: '0rem'
            },
            '&.Mui-selected': {
              color: 'white',
              backgroundColor: '#E98C26',
              '&:hover': {
                backgroundColor: '#A7651C'
              }
            }
          }
        }
      }
    }
  });

  const toggleButtonStyle = { paddingLeft: '1rem', paddingRight: '1rem' };

  useEffect(() => {
    clearUnreadMessage(gameTable, gameID, guid);
  }, [guid, gameID]);

  return (
    <div
      className={styles.gamecomms_menu}
      style={{
        height: '60px',
        paddingLeft: '20px',
        paddingRight: '20px',
        zIndex: ZIndex.widget,
        transform: `scale(${scale})`,
        transformOrigin: 'bottom left'
      }}>
      <ThemeProvider theme={theme}>
        <ToggleButtonGroup
          color='primary'
          value={isOpenTableHistory ? 'history' : isOpenTexasChat ? 'chat' : ''}
          exclusive
          aria-label='Platform'
          size='small'>
          <ToggleButton
            value='history'
            style={toggleButtonStyle}
            onClick={() => toggleMenu('history')}>
            Table History
          </ToggleButton>
          <ToggleButton value='chat' style={toggleButtonStyle} onClick={() => toggleMenu('chat')}>
            <StyledBadge color='error' badgeContent={unreadMessageCount}>
              Chat
            </StyledBadge>
          </ToggleButton>
        </ToggleButtonGroup>
      </ThemeProvider>
    </div>
  );
};

export default GameComms;

const StyledBadge = styled(Badge)<BadgeProps>(() => ({
  '& .MuiBadge-badge': {
    top: 11,
    right: -13,
    padding: '0 4px'
  }
}));
