import React, { CSSProperties, useContext } from 'react';
import { a, useSpring } from 'react-spring';
import { Paper } from '@mui/material';
import club from 'assets/cards/club.svg';
import diamond from 'assets/cards/diamond.svg';
import heart from 'assets/cards/heart.svg';
import spade from 'assets/cards/spade.svg';
import hjLogo from 'assets/hj_logo.svg';
import { SMALL_CARD_HEIGHT, SMALL_CARD_WIDTH } from 'constants/gameConstants';
import ScaleContext from 'contexts/ScaleContext';

import { Card, CardSuit } from '.';

type CardPropType = Card & {
  isFaceDown?: boolean;
  elevation?: number;
  enableScale?: boolean;
};

const getSuitImage = (suit: CardSuit) => {
  switch (suit) {
    case 'c':
      return club;
    case 'd':
      return diamond;
    case 'h':
      return heart;
    case 's':
      return spade;
    default:
      return '';
  }
};

const getCardColor = (suit: CardSuit) => {
  switch (suit) {
    case 'c':
      return '#378912';
    case 'd':
      return '#1659A8';
    case 'h':
      return '#c42021';
    case 's':
      return '#000000';
    default:
      return 'unset';
  }
};

const PaperAnimated = a(Paper);

const SmallCard = ({
  suit,
  rank,
  isFaceDown = false,
  elevation = 0,
  enableScale = false
}: CardPropType) => {
  const { scale: globalScale } = useContext(ScaleContext);
  const scale = enableScale ? globalScale : 1;

  const { transform } = useSpring({
    transform: `rotateY(${isFaceDown ? 0 : 180}deg)`,
    config: { mass: 5, tension: 500, friction: 80 }
  });

  const BaseStyle: CSSProperties = {
    position: 'relative',
    width: SMALL_CARD_WIDTH * scale,
    height: SMALL_CARD_HEIGHT * scale,
    perspective: '100vw'
  };

  const FrontTextStyle: CSSProperties = {
    position: 'absolute',
    fontSize: `calc(2rem * ${scale})`,
    fontWeight: 'bold',
    padding: 0,
    margin: 0,
    top: '10%',
    color: 'inherit',
    lineHeight: 1,
    textTransform: 'uppercase'
  };

  return (
    <div style={BaseStyle}>
      {suit && rank && (
        <PaperAnimated
          elevation={elevation}
          style={{ ...FrontStyle, transform, rotateY: '180deg' }}>
          <div style={{ ...FrontTextStyle, color: getCardColor(suit) }}>{rank}</div>
          <img src={getSuitImage(suit)} style={FrontImageStyle} alt={suit} />
        </PaperAnimated>
      )}
      <PaperAnimated elevation={elevation} style={{ ...BackStyle, transform }}></PaperAnimated>
    </div>
  );
};

export default SmallCard;

const CardStyle: CSSProperties = {
  position: 'absolute',
  top: 0,
  left: 0,
  height: '100%',
  width: '100%',
  backfaceVisibility: 'hidden'
};

const FrontStyle: CSSProperties = {
  ...CardStyle,
  background: '#FFFFFF',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column'
};

const FrontImageStyle: CSSProperties = {
  position: 'absolute',
  width: '60%',
  bottom: '10%'
};

const BackStyle: CSSProperties = {
  ...CardStyle,
  backgroundColor: '#633520',
  backgroundImage: `url(${hjLogo})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  backgroundSize: '50%',
  border: `0.25rem solid #7e594a`
};
