import React from 'react';
import { CircularProgress } from '@material-ui/core';

const LoadingState: React.FC = () => {
  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: '2rem',
        position: 'absolute',
        opacity: 0.5,
        zIndex: 9999,
        backgroundColor: '#000000'
      }}>
      <CircularProgress />
    </div>
  );
};

export default LoadingState;
