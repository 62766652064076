import * as React from 'react';
import styled from '@emotion/styled';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useAppSelector } from 'hooks';

import styles from './NotificationDropdown.module.scss';

interface BadgeProps {
  hasNotifications: boolean;
}

const Badge = styled('span')<BadgeProps>(({ hasNotifications }) => ({
  color: hasNotifications ? '#E98C26' : '#fff',
  borderRadius: '50%',
  fontSize: '10px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}));

const NotificationText = styled('span')<BadgeProps>(({ hasNotifications }) => ({
  fontFamily: 'Outfit',
  fontSize: '12px',
  color: hasNotifications ? '#E98C26' : 'rgba(255, 255, 255, 0.7)',
  textTransform: 'none',
  marginLeft: '0.5rem',
  lineHeight: '36px'
}));

const NotificationImportantIconStyled = styled(NotificationImportantIcon)<BadgeProps>(
  ({ hasNotifications }) => ({
    color: hasNotifications ? '#E98C26' : '#fff',
    fontSize: '20px'
  })
);

const IconWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  height: '100%'
});

const NotificationDropdown: React.FC = () => {
  const notifications = useAppSelector((state) => state.user.promoNotifications);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const markAsRead = async (notificationId: number) => {
    // console.log('Notification ID:', notificationId);

    const formData = new FormData();
    formData.append('id', notificationId.toString());

    try {
      const hostname = window.location.hostname.split('.');
      const url = `https://backoffice.${hostname
        .slice(-2)
        .join('.')}/api/transaction-notifications/read`;
      // const response =
      await fetch(url, {
        method: 'POST',
        body: formData
      });
      // const result = await response.json();
      // console.log('Notification marked as read:', result);
    } catch (error) {
      // console.error('Error marking notification as read:', error);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNotificationClick = async (notificationId: number) => {
    const historyPath = 'hijack/cashier?state=history';
    const hostname = window.location.hostname.split('.');
    const cashierPath =
      window.location.hostname === 'hijack.local'
        ? `${process.env.REACT_APP_FRONT_OFFICE_LOCAL_URL}/${historyPath}`
        : `https://account.${hostname.slice(-2).join('.')}/${historyPath}`;

    await markAsRead(notificationId);

    window.location.href = cashierPath;
    // console.log(`Notification ${notificationId} clicked`);
    handleClose();
  };

  const getTimeLapse = (timestamp: number): string => {
    const now = new Date();
    const notificationTime = new Date(timestamp * 1000); // Assuming timestamp is in seconds
    const diffInSeconds = Math.floor((now.getTime() - notificationTime.getTime()) / 1000);

    const timeUnits = [
      { unit: 'year', value: 365 * 24 * 60 * 60 },
      { unit: 'month', value: 30 * 24 * 60 * 60 },
      { unit: 'day', value: 24 * 60 * 60 },
      { unit: 'hour', value: 60 * 60 },
      { unit: 'minute', value: 60 },
      { unit: 'second', value: 1 }
    ];

    for (const { unit, value } of timeUnits) {
      const amount = Math.floor(diffInSeconds / value);
      if (amount >= 1) {
        return `${amount} ${unit}${amount > 1 ? 's' : ''} ago`;
      }
    }

    return 'just now';
  };

  const unreadNotifications = notifications.filter((notification) => notification.isRead === 0);
  const sortedNotifications = [...notifications].sort((a, b) => b.id - a.id);
  const notificationCount = unreadNotifications.length;
  const hasNotifications = notificationCount > 0;

  return (
    <div style={{ height: '100%' }}>
      <Button
        id='notification-button'
        aria-controls={open ? 'notification-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        style={{ height: '100%', borderRadius: '0px', display: 'flex', alignItems: 'center' }}>
        <IconWrapper>
          <NotificationText hasNotifications={hasNotifications}>Notifications</NotificationText>
          <NotificationImportantIconStyled hasNotifications={hasNotifications} />
          <Badge hasNotifications={hasNotifications}>{notificationCount}</Badge>
        </IconWrapper>
      </Button>
      <Menu
        id='notification-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{ 'aria-labelledby': 'notification-button' }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{ style: { borderRadius: '0px', minWidth: '13rem' } }}>
        <div className={styles.notification_list}>
          {sortedNotifications.map((notification) => (
            <MenuItem
              key={notification.id}
              onClick={() => handleNotificationClick(notification.id)}
              className={styles.notification_item}
              style={{ paddingLeft: '1.5rem', paddingRight: '1.5rem' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <span
                  className={
                    notification.isRead === 0
                      ? styles.notification_text_bold
                      : styles.notification_text
                  }
                  style={{ marginRight: '10px', textAlign: 'left' }}>
                  {notification.message}
                </span>
                <span className={styles.notification_text} style={{ textAlign: 'right' }}>
                  {getTimeLapse(notification.timestamp)}
                </span>
              </div>
            </MenuItem>
          ))}
        </div>
      </Menu>
    </div>
  );
};

export default React.memo(NotificationDropdown);
