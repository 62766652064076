import { forwardRef, useLayoutEffect, useRef, useState } from 'react';

type OvalPropType = {
  height: number;
  width: number;
  strokeWidth?: number;
};

export const OvalTable = forwardRef<SVGRectElement, OvalPropType>(
  ({ height, width, strokeWidth = 0 }: OvalPropType, ref) => {
    return (
      <svg
        width={width + strokeWidth}
        height={height + strokeWidth}
        style={{ position: 'fixed', top: 0, left: `calc((100% - ${width + strokeWidth}px) / 2)` }}
      >
        <g>
          <rect
            x={strokeWidth / 2}
            y={strokeWidth / 2}
            ref={ref}
            strokeWidth={strokeWidth}
            stroke='black'
            fill='none'
            width={width}
            height={height}
            rx={height / 2}
            ry={height / 2}
          />
        </g>
      </svg>
    );
  }
);

export type Position = {
  left: number;
  top: number;
  isLeft: boolean;
  isCenter: boolean;
  isRight: boolean;
  isTop: boolean;
  isMiddle: boolean;
  isBottom: boolean;
  angle: number;
  spotlightOffsetY: number;
};

export const useGeneratePositions = () => {
  const ref = useRef<SVGRectElement>(null);
  const [positions, setPositions] = useState<Position[]>([]);

  useLayoutEffect(() => {
    if (ref?.current) {
      const width = +(ref.current.getAttribute('width') ?? '');
      const height = +(ref.current.getAttribute('height') ?? '');
      const radius = height / 2;
      const factor = 1;

      ref.current.setAttributeNS(null, 'rx', `${radius * factor}`);
      ref.current.setAttributeNS(null, 'ry', `${radius * factor}`);

      const halfOfWidth = width / 2;
      const halfOfHeight = height / 2;
      const totalLength = ref.current.getTotalLength();
      const initialPoint = ref.current.getPointAtLength(0);

      const horizontalLength = width - initialPoint.x * 2;
      const halfOfHorizontalLength = horizontalLength / 2;

      const verticalCurveLength = (totalLength - horizontalLength * 2) / 2;
      const topLeftToBottomCenterLength =
        horizontalLength + verticalCurveLength + halfOfHorizontalLength;

      const currentPositions: Position[] = [];

      // Distance between the table and the player who are at the bottom of the table (adjust when necessary)
      const spotlightDistanceBottom = 0;

      // Loop through the number of players, calculate the position for each player and calculate the angle for the player spotlight
      for (
        let i = topLeftToBottomCenterLength;
        i < totalLength + topLeftToBottomCenterLength;
        i++
      ) {
        // Get the point on the path at the calculated length
        const p = ref.current.getPointAtLength(i % totalLength);

        // Get the next point on the path to calculate the angle
        const nextPoint = ref.current.getPointAtLength((i + 1) % totalLength);

        // Calculate the angle based on the difference between the current and next points
        const angle = Math.atan2(nextPoint.y - p.y, nextPoint.x - p.x) * (180 / Math.PI) - 90;

        // adds distance to the spotlight between the table and the bottom players
        const offsetY = p.y > halfOfHeight ? spotlightDistanceBottom : 0;

        const currentPosition: Position = {
          left: p.x,
          top: p.y,
          isLeft: p.x < halfOfWidth,
          isCenter: p.x === halfOfWidth,
          isRight: p.x > halfOfWidth,
          isTop: p.y < halfOfHeight,
          isMiddle: p.y === halfOfHeight,
          isBottom: p.y > halfOfHeight,
          angle: angle,
          spotlightOffsetY: offsetY
        };

        currentPositions.push(currentPosition);
      }

      setPositions(currentPositions);
    }
  }, []);

  return { positions, ref };
};
