import { BET_PREFIX } from 'constants/gameConstants';
import { useAppSelector } from 'hooks';
import { getCardValue } from 'utils/functions';

import { mapToCard } from 'components/Card';
import { PokerCard } from 'components/PokerCard/PokerCard';

const PlayerCards = () => {
  const game = useAppSelector<any>((state) => state.currentTable.game);
  const seatId = useAppSelector((state) => state.currentTable.currentPlayer.seatId);

  // Sorts the cards in descending order
  const cards = [
    game[`p${seatId}card1`],
    game[`p${seatId}card2`],
    game[`p${seatId}card3`],
    game[`p${seatId}card4`],
    game[`p${seatId}card5`]
  ]
    .filter((f) => f)
    .sort((a, b) => getCardValue(b) - getCardValue(a));

  const isPlayerFolded = game['p' + seatId + 'bet'].substring(0, 1) === BET_PREFIX.FOLD;

  return (
    <>
      {cards.length > 0 && (
        <>
          <div className='player-cards-container'>
            {cards.map((card: string, index: number) => (
              <PokerCard
                key={`${card}-${index}`}
                card={mapToCard(card)}
                isPlayerFolded={isPlayerFolded}
                pCard={card}
              />
            ))}
          </div>
        </>
      )}
    </>
  );
};

export default PlayerCards;
