export enum ZIndex {
  base = 1,
  seat,
  table,
  chip,
  card,
  player,
  zoom,
  action,
  header,
  widget,
  overlay
}
