export const SOCKET_SERVER_URL =
  process.env.REACT_APP_WS_URL ?? `http://${window.location.hostname}:8002`;
export const SOCKET_GAME_SERVER_URL = process.env.REACT_APP_WS_GAME_URL;
export const FRONT_OFFICE_LOCAL_URL =
  process.env.REACT_APP_FRONT_OFFICE_URI ?? `http://${window.location.hostname}:8666`;
export const cashierPath = 'hijack/cashier/deposit';
export const OKTA_ISSUER = process.env.REACT_APP_OKTA_ISSUER;
export const OKTA_CLIENT_ID = process.env.REACT_APP_OKTA_CLIENT_ID;
export const OKTA_REDIRECT_URI = process.env.REACT_APP_OKTA_REDIRECT_URI;
export const OKTA_LOGOUT_URI = process.env.REACT_APP_OKTA_LOGOUT_URI;
export const APP_DOMAIN = process.env.REACT_APP_DOMAIN;
export const DESCOPE_COOKIE = process.env.REACT_APP_DESCOPE_COOKIE;
export const DESCOPE_SESSION = process.env.REACT_APP_DESCOPE_SESSION;
export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;
