import React, { CSSProperties } from 'react';
import { useAppSelector } from 'hooks';
import { ZIndex } from 'styles';
import { potWinningHand } from 'utils/functions';

const hasWinner = (key: string) => {
  return ['GAME_MSG_ALLFOLD'].indexOf(key) > -1;
};

const tableMessageStyle: CSSProperties = {
  background: 'rgba(0, 0, 0, 0.8)',
  padding: '5px 10px',
  margin: '0px auto'
};

const TableWinnerMessage = () => {
  const {
    pots,
    showdown,
    languageKey,
    msg: tableMsg
  } = useAppSelector<any>((state) => state.currentTable.game);
  const msg = hasWinner(languageKey) ? tableMsg : potWinningHand(pots, showdown);

  return (
    msg && (
      <div
        style={{
          position: 'absolute',
          top: `55px`,
          zIndex: ZIndex.overlay,
          display: 'flex',
          gap: '20px'
        }}>
        <div style={tableMessageStyle}>
          <div>{msg}</div>
        </div>
      </div>
    )
  );
};

export default TableWinnerMessage;
