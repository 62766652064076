import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import Box from '@mui/material/Box';
import Button, { ButtonProps } from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Paper from '@mui/material/Paper';
import NoDataFound from 'assets/images/no-data-found.svg';
import { hiJackColors } from 'constants/colorConstants';
import { ETournamentStatus } from 'reducers/playerGames';
import { ZIndex } from 'styles';

import { Card } from 'components/Card';

import GameDetails, { TGameDetail } from './GameDetails';
import JoinedTableCards, { isEmptyCard } from './JoinedTableCards';
import { TPotDetail } from './PotDetails';
import YourTurn from './YourTurn';

export type TItemPanel = {
  card1: Card;
  card2: Card;
  card3?: Card;
  card4?: Card;
  card5?: Card;
  gameInfo: TGameDetail;
  potDetail: TPotDetail;
};

type TLobbyPanelProps = {
  title: string;
  data: TItemPanel[];
  isLoading: boolean;
};

enum observeMode {
  yes = 'yes',
  no = 'no'
}

const LobbyPanel = (props: TLobbyPanelProps) => {
  const { title, data, isLoading } = props;
  const detailWrapperRef = useRef<HTMLDivElement | null>(null);
  const dataKey = detailWrapperRef.current?.getAttribute('data-key');
  const currentIndex = dataKey ? Number(dataKey) + 1 : 0;

  const renderLoader = () => (
    <DetailWrapper
      style={{
        height: '200px',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
      <LoaderWrapper style={{ flexDirection: 'column' }}>
        <CircularProgressStyle size={60} />
        <PanelLoaderLabel>Loading. . . </PanelLoaderLabel>
      </LoaderWrapper>
    </DetailWrapper>
  );

  const renderContent = () =>
    data?.length !== 0 ? (
      data?.map((item, index) => {
        const cards = [item.card1, item.card2, item.card3, item.card4, item.card5].filter(
          (card) => card
        );
        const isEmptyCards = cards.every((card) => isEmptyCard(card));
        const typePanel =
          item.potDetail.tableType === 'm' || item.potDetail.tableType === 't'
            ? 'tournament'
            : 'cashGames';
        return (
          <DetailWrapper ref={detailWrapperRef} key={typePanel + index} data-key={index}>
            <GroupWrapper>
              {(item.potDetail.tableType === 'm' || item.potDetail.tableType === 't') &&
              item.potDetail.tournamentStatus === ETournamentStatus.Playing &&
              item.potDetail.hand === '' ? (
                <LoaderWrapper>
                  <CircularProgressStyle />
                  <LoaderLabel>Waiting for the tournament to start</LoaderLabel>
                </LoaderWrapper>
              ) : (
                <div>
                  <div style={{ display: 'flex', flexDirection: 'column', gap: '3px' }}>
                    <JoinedTableCards bet={item.potDetail.bet} cards={cards} />
                    <YourTurn data={item.potDetail} isEmptyCards={isEmptyCards} />
                  </div>
                </div>
              )}
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <GameDetails data={{ ...item.gameInfo, ...item.potDetail }} />
              </div>
              <Link
                style={{ textDecoration: 'none', color: '#FFFFFF' }}
                to={`/game/${item.gameInfo.gameID}/type/${item.gameInfo.game}?observe=${observeMode.no}`}
                target={'_blank'}
                rel='noopener'>
                <BtnReturnGame variant='contained' title={'Return to Game'}>
                  Return to Game
                </BtnReturnGame>
              </Link>
            </GroupWrapper>
          </DetailWrapper>
        );
      })
    ) : (
      <DetailWrapper style={{ height: '200px', justifyContent: 'center' }}>
        <NoDataFoundWrapper>
          <img src={NoDataFound} alt='' style={{ height: 100 }} />
          <LabelH4>No Activity found</LabelH4>
        </NoDataFoundWrapper>
      </DetailWrapper>
    );

  return (
    <MainPanel elevation={1}>
      <Header>
        <LabelStyle>{title}</LabelStyle>
      </Header>
      <ContentWrapper
        style={{ height: data?.length === 0 ? '200px' : currentIndex < 4 ? '100%' : '250px' }}>
        {isLoading ? renderLoader() : renderContent()}
      </ContentWrapper>
    </MainPanel>
  );
};

export default LobbyPanel;

const LabelH4 = styled('h4')({
  fontFamily: 'Outfit',
  color: hiJackColors.white
});

const NoDataFoundWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'nowrap',
  justifyContent: 'center',
  alignItems: 'center'
});

const LabelStyle = styled('label')({
  paddingLeft: 4,
  fontFamily: 'Outfit'
});

const PanelLoaderLabel = styled('label')({
  color: hiJackColors.white,
  fontSize: '16px',
  fontWeight: 500,
  fontFamily: 'Outfit'
});

const CircularProgressStyle = styled(CircularProgress)({
  color: hiJackColors.orange
});

const LoaderLabel = styled('label')({
  color: hiJackColors.white,
  fontSize: '9px',
  fontWeight: 500
});

const LoaderWrapper = styled(Box)({
  display: 'flex',
  columnGap: '8px',
  alignItems: 'center'
});

const BtnReturnGame = styled(Button)<ButtonProps>(() => ({
  fontSize: '10px',
  backgroundColor: hiJackColors.tealBlue,
  borderRadius: 8,
  padding: '4px 5px',
  color: hiJackColors.white,
  '&:hover': {
    backgroundColor: hiJackColors.tealBlueLight
  },
  width: '126px',
  height: '100%',
  textTransform: 'none',
  fontFamily: 'Outfit'
}));

const MainPanel = styled(Paper)({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: hiJackColors.darkSlateGray,
  width: '100%',
  borderRadius: 8
});

const Header = styled(Box)({
  borderTopRightRadius: 8,
  borderTopLeftRadius: 8,
  backgroundColor: hiJackColors.black,
  width: '100%',
  height: 40,
  padding: 8,
  fontSize: '1rem',
  color: hiJackColors.white
});

const ContentWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  padding: 12,
  rowGap: 10,
  overflow: 'auto',
  height: '250px',
  '&::-webkit-scrollbar': {
    width: 4
  },
  '&::-webkit-scrollbar-thumb': {
    background: hiJackColors.mediumGray
  },
  '&::-webkit-scrollbar-thumb:hover': {
    background: hiJackColors.mediumLightGray
  }
});

const GroupWrapper = styled(Box)({
  display: 'grid',
  gridTemplateColumns: '1fr 100px auto',
  width: '100%',
  gap: '1rem'
});

const DetailWrapper = styled(Box)({
  backgroundColor: hiJackColors.midnightBlue,
  display: 'flex',
  flexDirection: 'row',
  borderRadius: '7.6087px',
  padding: '0.5rem 0.5rem',
  minWidth: '12rem',
  zIndex: ZIndex.action
});
