import React, { useContext, useEffect } from 'react';
import { NumericFormat } from 'react-number-format';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  Box,
  Button,
  createTheme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputAdornment,
  Modal,
  OutlinedInput,
  Slider,
  ThemeProvider,
  Typography
} from '@mui/material';
import GlobalContext from 'contexts/GlobalContext';
import Decimal from 'decimal.js';
import { useAppSelector } from 'hooks';
import useGameService from 'services/GameService';
import { calculateStepValue, currencyFormatter, toLocaleStringNumber } from 'utils/functions';
import { cashierPath, FRONT_OFFICE_LOCAL_URL } from 'utils/urls';

const BuyInModal = () => {
  const tablelimit = useAppSelector<any>((state) => state.currentTable.game.tablelimit);
  const tablelow = useAppSelector<any>((state) => state.currentTable.game.tablelow);
  const tablelowOverride = useAppSelector<any>((state) => state.currentTable.game.tablelowOverride);
  const players = useAppSelector<any>((state) => state.currentTable.players);
  const gameID = useAppSelector((state) => state.currentTable.game.gameID);
  const hand = useAppSelector((state) => state.currentTable.game.hand);
  const isPennyTable = useAppSelector((state) => state.currentTable.game.isPennyTable);
  const bb = useAppSelector((state) => state.currentTable.game.bb);
  const isMaintenance = useAppSelector((state) => state.maintenanceSchedule.isMaintenance);
  // const [curChipsFieldValue, setCurChipsFieldValue] = useState(0);

  const { sitDown, leaveTable } = useGameService();
  const navigate = useNavigate();
  const userBalance = useAppSelector((state) => state.user.playerBankAmount);
  const {
    openAskBuyin,
    setOpenAskBuyin,
    buyinAmount,
    setBuyinAmount,
    setIsBuyinValueSet,
    openInfoMsg,
    setInfoMsg,
    manuallyAssignedSeatId,
    setManuallyAssignedSeatId,
    setIsJoining,
    isTriggerBuyIn,
    setIsTriggerBuyIn
  } = useContext(GlobalContext);

  let maxLimit = tablelimit;
  let minLimit = tablelow > 0 ? tablelow : 0;

  if (tablelowOverride > minLimit && !isTriggerBuyIn) minLimit = tablelowOverride;
  if (tablelowOverride > maxLimit && !isTriggerBuyIn) maxLimit = tablelowOverride;

  const sliderStep = isPennyTable ? calculateStepValue(bb) : 1;

  const [searParams] = useSearchParams();
  const isPlayerObserver = searParams.get('observe') === 'yes' ? true : false;
  const availableSeatId = players.findIndex((f: any) => !!f && !f.ID);
  let half = isPennyTable
    ? (Number(maxLimit) / 2).toFixed(2)
    : Decimal.ceil(maxLimit / 2).toNumber();
  const tableType = useAppSelector((state) => state.currentTable.game.tabletype);
  maxLimit = isPennyTable
    ? new Decimal(maxLimit).toDP(2).toNumber()
    : Decimal.floor(maxLimit).toNumber();

  useEffect(() => {
    // setCurChipsFieldValue(buyinAmount);
    setBuyinAmount(buyinAmount);
  }, [minLimit, maxLimit, tablelowOverride, buyinAmount, openAskBuyin, setBuyinAmount]);

  useEffect(() => {
    // setCurChipsFieldValue(tablelowOverride);
    if (!isTriggerBuyIn) {
      setBuyinAmount(tablelowOverride);
      if (tablelowOverride > minLimit) minLimit = tablelowOverride;
      if (tablelowOverride > maxLimit) maxLimit = tablelowOverride;
      half = isPennyTable
        ? (Number(maxLimit) / 2).toFixed(2)
        : Decimal.ceil(maxLimit / 2).toNumber();
    }
  }, [tablelowOverride]);

  const handleBuyinSlider = (e: Event) => {
    // setCurChipsFieldValue((e?.target as any).value);
    setBuyinAmount((e?.target as any).value);
    setIsBuyinValueSet(true);
  };

  const closeModal = () => {
    setOpenAskBuyin(false);
  };

  const handleClose = () => {
    setOpenAskBuyin(false);
    setIsBuyinValueSet(false);
    setInfoMsg(false);
    setBuyinAmount(minLimit);
    setIsTriggerBuyIn(false);

    if (isTriggerBuyIn) {
      leaveTable(true);
      navigate('/');
      // setTimeout(() => window.location.reload(), 100);
    }
  };

  const handleCloseModal = () => {
    setInfoMsg(false);
  };

  const handleCloseAskBuyin = () => {
    if (hand === '' || +hand > 3) {
      if (buyinAmount > 0) {
        setIsTriggerBuyIn(false);
        setOpenAskBuyin(false);
        setIsBuyinValueSet(true);
        setIsJoining(true);

        if (manuallyAssignedSeatId > 0) {
          sitDown(gameID, manuallyAssignedSeatId, buyinAmount);
          setManuallyAssignedSeatId(0);
        } else if (availableSeatId > 0) {
          sitDown(gameID, availableSeatId, buyinAmount);
        } else {
          //TO DO
        }
      } else {
        setInfoMsg(true);
      }
    }
  };

  const handleBuyinTextField = (event: React.ChangeEvent<HTMLInputElement>) => {
    // setCurChipsFieldValue((event?.target as any).value);
    setIsBuyinValueSet(true);
    setBuyinAmount((event?.target as any).value);
  };

  const handlePasteValue = (event: React.ClipboardEvent<HTMLInputElement>) => {
    const pastedValue = Number(event.clipboardData.getData('text'));
    // setCurChipsFieldValue(pastedValue);
    setBuyinAmount(pastedValue);
  };

  return (
    <>
      {!isMaintenance && tableType !== 'm' && tableType !== 't' && (
        <Modal open={!isPlayerObserver ? openAskBuyin : false}>
          <Box sx={{ ...modalStyle, boxShadow: 10 }}>
            <Typography
              id='modal-modal-title'
              variant='h5'
              component='h2'
              sx={{ textAlign: 'center', marginBottom: '2rem' }}>
              BUY-IN
            </Typography>

            {minLimit > tablelow ? (
              <div style={{ textAlign: 'center', gap: '1rem', marginBottom: '1rem' }}>
                In accordance with our fairness policy,
                <br />
                Hijack mandates that players who return to same table within one hour must rejoin
                the table with the identical chip count they had when they left.
              </div>
            ) : (
              isTriggerBuyIn && (
                <div style={{ textAlign: 'center', gap: '1rem', marginBottom: '1rem' }}>
                  The game has been idle for more than 2 minutes. All buy-ins will be reset.
                </div>
              )
            )}

            <div style={{ display: 'flex', gap: '1rem', marginBottom: '1rem' }}>
              <ThemeProvider theme={BuyInPreSetBtn}>
                <Button onClick={() => setBuyinAmount(minLimit)}>
                  Min:
                  <strong style={{ marginLeft: '0.25rem' }}>
                    ${toLocaleStringNumber(minLimit)}
                  </strong>
                </Button>
                <Button onClick={() => setBuyinAmount(+half)} disabled={+half < minLimit}>
                  1/2:
                  <strong style={{ marginLeft: '0.25rem' }}>${toLocaleStringNumber(half)}</strong>
                </Button>
                <Button onClick={() => setBuyinAmount(maxLimit)}>
                  Max:
                  <strong style={{ marginLeft: '0.25rem' }}>
                    ${toLocaleStringNumber(maxLimit)}
                  </strong>
                </Button>
              </ThemeProvider>
            </div>

            {userBalance >= minLimit && (
              <div style={{ padding: '0rem 0.5rem' }}>
                <Slider
                  aria-label='BUY-IN'
                  defaultValue={minLimit}
                  onChange={(e) => handleBuyinSlider(e)}
                  value={buyinAmount}
                  step={sliderStep}
                  max={+maxLimit}
                  min={+minLimit}
                  sx={{ color: '#CC6B47' }}
                />
              </div>
            )}

            <div
              style={{
                marginTop: '0rem'
              }}>
              {userBalance >= minLimit ? (
                <>
                  <ThemeProvider theme={TextFieldTheme}>
                    <FormControl fullWidth sx={{ m: 1 }}>
                      <NumericFormat
                        id='outlined-adornment-amount'
                        customInput={OutlinedInput}
                        value={buyinAmount}
                        onChange={handleBuyinTextField}
                        onPaste={handlePasteValue}
                        decimalScale={isPennyTable ? 2 : 0}
                        allowNegative={false}
                        startAdornment={<InputAdornment position='start'>$</InputAdornment>}
                      />
                    </FormControl>
                  </ThemeProvider>
                </>
              ) : (
                <div style={{ fontSize: '1.5rem' }}>
                  Sorry, you can't join the table. Your balance is {currencyFormatter(userBalance)},
                  minimum buy-in is{' '}
                  {currencyFormatter(tablelowOverride > tablelow ? tablelowOverride : tablelow)}
                </div>
              )}
            </div>

            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '2rem',
                gap: '2rem'
              }}>
              <ThemeProvider theme={CancelBtnStyle}>
                <Button
                  sx={{ display: 'block', opacity: '.8' }}
                  variant='contained'
                  onClick={handleClose}>
                  {isTriggerBuyIn ? 'EXIT TABLE' : 'CANCEL'}
                </Button>
              </ThemeProvider>

              {userBalance >= minLimit ? (
                <ThemeProvider theme={BuyInBtnStyle}>
                  <Button
                    sx={{ display: 'block' }}
                    variant='contained'
                    onClick={handleCloseAskBuyin}
                    disabled={
                      buyinAmount > userBalance || buyinAmount < minLimit || buyinAmount > maxLimit
                    }>
                    BUY-IN
                  </Button>
                </ThemeProvider>
              ) : (
                <a
                  href={
                    window.location.hostname === 'hijack.local'
                      ? `${FRONT_OFFICE_LOCAL_URL}/${cashierPath}`
                      : 'https://account.' +
                        window.location.hostname.split('.')[1] +
                        '.' +
                        window.location.hostname.split('.')[2] +
                        `/${cashierPath}`
                  }
                  target='_blank'
                  rel='noreferrer'
                  style={{ textDecoration: 'none' }}>
                  <Button sx={{ display: 'block' }} variant='contained' onClick={closeModal}>
                    BUY CHIPS
                  </Button>
                </a>
              )}
            </div>
          </Box>
        </Modal>
      )}
      <Dialog
        open={openInfoMsg}
        onClose={handleCloseModal}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'>
        <DialogTitle id='alert-dialog-title'>Information</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Please add more chips to your buy-in.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default BuyInModal;

const modalStyle = {
  position: 'absolute' as const,
  top: '40%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: '#012425',
  color: 'white',
  padding: '4rem 7rem',
  width: '40rem',
  borderRadius: '0.5rem'
};

const TextFieldTheme = createTheme({
  palette: {
    mode: 'dark'
  },
  components: {
    MuiFormControl: {
      styleOverrides: {
        root: {
          margin: '0.5rem 0rem !important',
          color: 'black'
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          color: 'black',
          backgroundColor: 'white',
          fontSize: '1.5rem'
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: 'rgba(0, 0, 0, 0.7) !important',
          fontSize: '1.5rem'
        }
      }
    }
  }
});

const CancelBtnStyle = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          color: 'white',
          backgroundColor: '#5b6671',
          '&:hover': {
            color: 'white',
            backgroundColor: '#37393b'
          },
          '&:disabled': {
            color: 'white',
            opacity: '0.4'
          },
          fontSize: '1rem',
          fontWeight: '400',
          padding: '0.5rem 1rem',
          minWidth: 'unset',
          textTransform: 'none',
          width: '100%'
        }
      }
    }
  }
});

const BuyInBtnStyle = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          color: 'white',
          backgroundColor: '#E98C26',
          '&:hover': {
            color: 'white',
            backgroundColor: '#A7651C'
          },
          '&:disabled': {
            color: 'white',
            backgroundColor: '#E98C26',
            opacity: '0.4'
          },
          fontSize: '1rem',
          fontWeight: '400',
          padding: '0.5rem 1rem',
          minWidth: 'unset',
          textTransform: 'none',
          width: '100%'
        }
      }
    }
  }
});

const BuyInPreSetBtn = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          color: 'white',
          backgroundColor: '#5b6671',
          '&:hover': {
            color: 'white',
            backgroundColor: '#37393b'
          },
          '&:disabled': {
            color: 'white',
            opacity: '0.4'
          },
          fontWeight: '400',
          padding: '0.5rem 0.75rem',
          minWidth: 'unset',
          textTransform: 'none',
          width: '100%'
        }
      }
    }
  }
});
