import React, { CSSProperties } from 'react';

import PostBlind from './PostBlindAction';
import WaitForBigBlind from './WaitForBigBlindAction';

import 'components/UserActions/UserActions.scss';

const JoinActions = () => {
  return (
    <div className='player-actions'>
      <div style={actionZIndex} className='poker-actions'>
        <div
          style={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'row',
            gap: '1rem',
            width: '100%',
            paddingTop: '1rem',
            paddingBottom: '1rem',
            justifyContent: 'center',
            zIndex: 3
          }}>
          <PostBlind />
          <WaitForBigBlind />
        </div>
      </div>
    </div>
  );
};

export default JoinActions;

const actionZIndex: CSSProperties = {
  zIndex: 2
};
