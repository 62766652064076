import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Paper from '@mui/material/Paper';
import { hiJackColors } from 'constants/colorConstants';
import {
  CONNECT,
  GOT_MULTIBAG_PRIZE,
  GOT_TOURNAMENT_REBUY,
  GOT_TOURNAMENT_REGISTER,
  GOT_TOURNAMENT_UNREGISTER,
  GOT_TOURNAMENTS,
  JOINED_MTT,
  LOBBY_DATA_RECEIVED,
  REGISTER_SOCKET,
  TRACK_LOCATION
} from 'constants/eventConstants';
import { hiJackLabels } from 'constants/LabelConstants';
import GlobalContext from 'contexts/GlobalContext';
import { LobbyProvider } from 'contexts/LobbyContext';
import { SocketContext } from 'contexts/SocketContext';
import { useAppDispatch, useAppSelector } from 'hooks';
import Cookies from 'js-cookie';
import {
  setSitAndGoList,
  setTournamentList,
  updateGroupedCashGames,
  updateGroupedOmahaCashGames,
  updateOmahaCashGames,
  updateTexasCashGames
} from 'reducers/lobby';
import { PlayerGame } from 'reducers/playerGames';
import { getLocalTime, groupBy, sortData } from 'utils/functions';
import {
  extractAccessTokenFromDescopeCookie,
  extractIDTokenFromDescopeCookie,
  extractRefreshTokenFromDescopeCookie
} from 'utils/functions';
import { DESCOPE_COOKIE } from 'utils/urls';

import FreshDeskLink from 'components/FreshDeskLink';
import LoadingState from 'components/Loading/LoadingState';
import LobbyTable from 'components/LobbyTable/LobbyTable';
import InvalidLocationModal from 'components/Location/InvalidLocationModal';
import MaintenanceScheduleNotification from 'components/MaintenanceSchedule/MaintenanceScheduleNotification';

import HeaderAppBar from './components/HeaderAppBar';
import LobbyHeaderAction from './components/LobbyHeaderAction';
import LobbyPanel from './components/LobbyPanel';
import TournamentPanel from './components/TournamentPanel';

import styles from './Lobby.module.scss';

interface cashGamesGroup {
  gameName: string;
  stakes: string;
  maxPlayers: number;
  tablesCount: number;
  tables: any;
}

enum observeMode {
  yes = 'yes',
  no = 'no'
}

const Lobby: React.FC<any> = () => {
  const dispatch = useAppDispatch();
  const maintenance = useAppSelector((state) => state.maintenanceSchedule);
  const isMaintenance = useAppSelector((state) => state.maintenanceSchedule.isMaintenance);
  const playerGames = useAppSelector((state) => state.playerGames);
  const guid = useAppSelector((state) => state.user.playerGUID);
  const isRestrictedFlag = useAppSelector((state) => state.user.isRestrictedFlag);

  const socket = useContext(SocketContext);
  const {
    isLocated,
    isLocationInvalid,
    setIsLocationInvalid,
    setInvalidLocationMsg,
    setIsLocated
  } = useContext(GlobalContext);

  const [playerGamesTournament, setPlayerGamesTournament] = useState<any[]>([]);
  const [playerGamesCash, setPlayerGamesCash] = useState<any[]>([]);
  const [isCashGameLoadingPanel, setIsCashGameLoadingPanel] = useState<boolean>(true);
  const [isTournaLoadingPanel, setIsTournaLoadingPanel] = useState<boolean>(true);
  //Table display
  const [registrationMsg, setRegistrationMsg] = useState('');
  const [registrationTitle, setRegistrationTitle] = useState('');
  const [openModal, setOpenModal] = useState(false);

  const { hasFilter, sortingField, sortingDirection, selectedTournament } = useAppSelector(
    (state) => state.lobby
  );

  const locationSegments = window.location.hostname.split('.');
  const loginRedirectRef = useRef(
    `https://account.${locationSegments[1]}.${locationSegments[2]}/login`
  );

  const getToken = useCallback(() => {
    const descopeCookie = Cookies.get(DESCOPE_COOKIE as string);
    if (!descopeCookie || typeof descopeCookie !== 'string') {
      window.location.href = loginRedirectRef.current;
      return {
        idToken: null,
        descopeSession: null,
        descopeRefresh: null
      };
    }

    return {
      idToken: extractIDTokenFromDescopeCookie(descopeCookie),
      descopeSession: extractAccessTokenFromDescopeCookie(descopeCookie),
      descopeRefresh: extractRefreshTokenFromDescopeCookie(descopeCookie)
    };
  }, []);

  useEffect(() => {
    const cashPlayerGames = playerGames.filter((data: PlayerGame) => data.tabletype === 's');
    const tournaPlayerGames = playerGames.filter(
      (data: PlayerGame) => data.tabletype === 'm' || data.tabletype === 't'
    );
    const dataTPanel = cashPlayerGames.map((data: PlayerGame) => ({
      card1: data.card1,
      card2: data.card2,
      card3: data.card3,
      card4: data.card4,
      card5: data.card5,
      gameInfo: { gameID: data.gameID, game: data.game, isPrivate: false },
      potDetail: {
        totalPot: data.pot,
        myTurn: data.myTurn,
        bet: data.bet,
        status: data.status,
        tableType: data?.tabletype,
        hand: data?.hand,
        tournamentStatus: data?.tournamentStatus
      }
    })) as any[];
    setPlayerGamesCash(dataTPanel);

    const dataTournaPanel = tournaPlayerGames.map((data: PlayerGame) => ({
      card1: data.card1,
      card2: data.card2,
      gameInfo: { gameID: data.gameID, game: data.game, isPrivate: false },
      potDetail: {
        totalPot: data.pot,
        myTurn: data.myTurn,
        bet: data.bet,
        status: data.status,
        tableType: data?.tabletype,
        hand: data?.hand,
        tournamentStatus: data?.tournamentStatus
      }
    })) as any[];
    setPlayerGamesTournament(dataTournaPanel);
    setTimeout(() => {
      setIsTournaLoadingPanel(false);
      setIsCashGameLoadingPanel(false);
    }, 2000);
  }, [playerGames]);

  useEffect(() => {
    socket.on(CONNECT, () => {
      socket.emit(REGISTER_SOCKET, { page: 'lobby' });
    });

    socket.on(LOBBY_DATA_RECEIVED, (receivedCashGames: any) => {
      if (
        isLocated &&
        guid !== '' &&
        (receivedCashGames !== null || receivedCashGames !== undefined)
      ) {
        const texasGames = receivedCashGames['texas'];
        //group games based on max number of seats and stakes
        const groupedByseats = groupBy(texasGames, 'seats');
        Object.keys(groupedByseats).forEach((seat) => {
          groupedByseats[seat] = groupBy(groupedByseats[seat], 'stakes');
        });

        let groupedFinal: cashGamesGroup[] = [];
        let rawTexasGames = receivedCashGames.texas;
        Object.keys(groupedByseats).forEach((seat) => {
          Object.keys(groupedByseats[seat]).forEach((stake) => {
            groupedFinal.push({
              gameName: 'texas',
              stakes: `${stake}`,
              maxPlayers: Number(seat),
              tablesCount: groupedByseats[seat][stake].length,
              tables: groupedByseats[seat][stake]
            });
          });
        });

        if (hasFilter && sortingField !== null && sortingDirection !== null) {
          groupedFinal = sortData(groupedFinal, sortingField, sortingDirection);
          rawTexasGames = sortData(rawTexasGames, sortingField, sortingDirection);
        }

        const omahaGames = receivedCashGames['omaha'];
        //group games based on max number of seats and stakes
        const groupedOmahaBySeats = groupBy(omahaGames, 'seats');
        Object.keys(groupedOmahaBySeats).forEach((seat) => {
          groupedOmahaBySeats[seat] = groupBy(groupedOmahaBySeats[seat], 'stakes');
        });

        let groupedOmahaFinal: cashGamesGroup[] = [];
        let rawOmahaGames = receivedCashGames.omaha;
        Object.keys(groupedOmahaBySeats).forEach((seat) => {
          Object.keys(groupedOmahaBySeats[seat]).forEach((stake) => {
            groupedOmahaFinal.push({
              gameName: 'omaha',
              stakes: `${stake}`,
              maxPlayers: Number(seat),
              tablesCount: groupedOmahaBySeats[seat][stake].length,
              tables: groupedOmahaBySeats[seat][stake]
            });
          });
        });

        if (hasFilter && sortingField !== null && sortingDirection !== null) {
          groupedOmahaFinal = sortData(rawOmahaGames, sortingField, sortingDirection);
          rawOmahaGames = sortData(rawOmahaGames, sortingField, sortingDirection);
        }

        dispatch(updateGroupedCashGames(groupedFinal));
        dispatch(updateTexasCashGames(rawTexasGames));
        dispatch(updateOmahaCashGames(rawOmahaGames));
        dispatch(updateGroupedOmahaCashGames(groupedOmahaFinal));
      }
    });

    socket.on(GOT_TOURNAMENTS, (data: any) => {
      if (data) {
        const sitAndGo = data.response.filter((row: any) => row.tabletype === 't');
        const tournaments = data.response.filter((row: any) => row.tabletype === 'm');

        dispatch(setSitAndGoList(sitAndGo));
        dispatch(setTournamentList(tournaments));
      }
    });

    socket.on(GOT_MULTIBAG_PRIZE, (data: any) => {
      if (data.length > 0) {
        const filtered = data.filter(
          (d: any) => d.playerId === (guid === '' ? localStorage.getItem('playerGUID') : guid)
        );
        if (filtered.length > 0) {
          const totalPrize = filtered.reduce((acc: any, current: any) => acc + current.prize, 0);

          if (totalPrize > 0) {
            setRegistrationTitle('Notification');
            setRegistrationMsg(
              `Congratulations! You've earned $${totalPrize} for your extra bag in the multi-flight tournament. Well done on your performance!`
            );
            setOpenModal(true);
          }
        }
      }
    });

    socket.on(GOT_TOURNAMENT_REGISTER, (data: any) => {
      if (data) {
        setRegistrationTitle(data?.response?.title);
        setRegistrationMsg(data?.response?.msg);
        setOpenModal(true);

        if (data?.response.isSitAndGo) {
          window.open(
            isMaintenance
              ? '#'
              : `/game/${data?.gameID}/type/${data?.game}?observe=${observeMode.no}`
          );
        }
      }
    });

    socket.on(GOT_TOURNAMENT_REBUY, (data: any) => {
      if (data) {
        setOpenModal(true);
        setRegistrationTitle(data?.title);
        setRegistrationMsg(data?.msg);
      }
    });

    socket.on(GOT_TOURNAMENT_UNREGISTER, (data: any) => {
      if (data) {
        setRegistrationTitle(data?.response?.title);
        setRegistrationMsg(data?.response?.msg);
        setOpenModal(true);
      }
    });

    socket.on(JOINED_MTT, (data: any) => {
      if (data) {
        window.open(
          isMaintenance ? '#' : `/game/${data?.gameID}/type/${data?.game}?observe=${observeMode.no}`
        );
      }
    });

    return () => {
      socket.off(GOT_TOURNAMENT_REBUY);
      socket.off(CONNECT);
      socket.off(JOINED_MTT);
      socket.off(GOT_TOURNAMENTS);
      socket.off(GOT_MULTIBAG_PRIZE);
      socket.off(LOBBY_DATA_RECEIVED);
      socket.off(GOT_TOURNAMENT_REGISTER);
      socket.off(GOT_TOURNAMENT_UNREGISTER);
    };
  }, [socket, dispatch, hasFilter, sortingDirection, sortingField, guid, isLocated]);

  const handleClose = () => {
    setOpenModal(false);
  };

  const geoFindMe = () => {
    function success(position: any) {
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;
      const { descopeSession } = getToken();

      if (!descopeSession) {
        window.location.href = loginRedirectRef.current;
        return;
      }
      socket.emit(TRACK_LOCATION, {
        geoLocationError: false,
        jwt: descopeSession,
        latitude: latitude,
        longitude: longitude,
        playerGUID: guid,
        deviceID: localStorage.getItem('deviceID') === null ? '' : localStorage.getItem('deviceID')
      });
    }

    function error(err: any) {
      setIsLocationInvalid(true);
      if (err.code === 1) {
        setInvalidLocationMsg(
          "You've decided not to share your position, but it's OK. We won't ask you again."
        );
      } else if (err.code === 2) {
        setInvalidLocationMsg("The network is down or the positioning service can't be reached.");
      } else if (err.code === 3) {
        setInvalidLocationMsg('The attempt timed out before it could get the location data.');
      } else {
        setInvalidLocationMsg('Geolocation failed due to unknown error.');
      }
      // const { descopeSession } = getToken();
      // socket.emit(TRACK_LOCATION, {
      //   //PH
      //   // latitude: 10.715136,
      //   // longitude: 122.5555968,
      //   //US
      //   geoLocationError: true,
      //   jwt: descopeSession,
      //   latitude: 37.141302,
      //   longitude: -106.2719877,
      //   playerGUID: guid,
      //   deviceID: localStorage.getItem('deviceID') === null ? '' : localStorage.getItem('deviceID')
      // });
    }

    if (!navigator.geolocation) {
      alert('Geolocation is not supported by your browser');
    } else {
      navigator.geolocation.getCurrentPosition(success, error);
    }
  };

  useEffect(() => {
    if (!isLocated && guid !== '' && isRestrictedFlag === 1) {
      geoFindMe();
    } else if (isRestrictedFlag === 0) {
      setIsLocated(true);
    }
  }, [isLocated, guid, isRestrictedFlag]);

  if (!socket.connected && !isLocationInvalid) {
    return <LoadingState />;
  }

  const tableHeaderData = [
    { id: 'gameID', label: 'Game ID' },
    { id: 'gameTypeDisplayName', label: 'Game' },
    { id: 'stakes', label: 'Stake' },
    { id: 'tablelimitDisplay', label: 'Limit' },
    { id: 'seats', label: 'Table Capacity' },
    { id: 'seatsTakenDisplay', label: 'Seats Taken' },
    { id: 'tablelowDisplay', label: 'Min buy-in' }
  ];

  return (
    <LobbyProvider>
      <div className={styles.main_wrapper}>
        <FreshDeskLink />
        <HeaderAppBar />
        <div>
          <MaintenanceScheduleNotification>
            {isMaintenance
              ? `Maintenance in progress. Server up by  ${getLocalTime(
                  Number(maintenance.end)
                )}. Kindly exit after finishing your game. Thank you for your cooperation!`
              : maintenance.displayMessage !== undefined
              ? `${maintenance.title} ${maintenance.displayMessage}`
              : ''}
          </MaintenanceScheduleNotification>
        </div>
        <LobbyWrapper>
          <MainWrapper>
            <AdsWrapper>
              <AdsContent>
                <DummyAdsBox />
              </AdsContent>
              <AdsContent>
                <DummyAdsBox />
              </AdsContent>
            </AdsWrapper>
            <MainSubWrapper>
              <LobbyHeaderAction />
              <LobbyTable headers={tableHeaderData} />
            </MainSubWrapper>
          </MainWrapper>

          <PanelWrapper>
            {selectedTournament && <TournamentPanel />}
            <LobbyPanel
              title={hiJackLabels.lobby.cashGame}
              isLoading={isCashGameLoadingPanel}
              data={playerGamesCash}
            />
            <LobbyPanel
              title={hiJackLabels.lobby.tournamentGame}
              isLoading={isTournaLoadingPanel}
              data={playerGamesTournament}
            />
          </PanelWrapper>
        </LobbyWrapper>

        <Dialog
          open={openModal}
          onClose={handleClose}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
          PaperComponent={StyledPaper}>
          <BoxStyle>
            <DialogTitle id='alert-dialog-title'>{registrationTitle}</DialogTitle>
            <DialogContent>
              <DialogContentText style={{ color: hiJackColors.white }}>
                {registrationMsg}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <BtnClose onClick={handleClose}>Close</BtnClose>
            </DialogActions>
          </BoxStyle>
        </Dialog>
        <InvalidLocationModal />
      </div>
    </LobbyProvider>
  );
};

const StyledPaper = styled(Paper)`
  background-color: ${hiJackColors.darkJungleGreen};
`;
const BoxStyle = styled(Box)({
  color: hiJackColors.white,
  backgroundColor: hiJackColors.darkJungleGreen,
  borderRadius: 8
});
const BtnClose = styled(Button)({
  color: hiJackColors.white,
  backgroundColor: hiJackColors.darkGray,
  textTransform: 'none',
  '&:hover, &:focus': {
    backgroundColor: hiJackColors.midDarkOrange
  }
});

const LobbyWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  width: '100%'
});

const AdsWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'nowrap',
  width: '10%',
  height: '100%',
  rowGap: 10
});

const PanelWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'nowrap',
  width: '450px',
  height: '100%',
  rowGap: 10,
  paddingRight: '14px'
});

const AdsContent = styled(Box)({
  width: '100%'
});

const DummyAdsBox = styled(Box)({
  backgroundColor: hiJackColors.black,
  width: '100%',
  height: 295
});

const MainWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  padding: '0px 12px',
  columnGap: '10px'
});

const MainSubWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  width: '100%'
});

export default Lobby;
