import { configureStore } from '@reduxjs/toolkit';

import currentTableReducer from './reducers/currentTable';
import liveChatNotificationsReducer from './reducers/liveChatNotifications';
import lobbyReducer from './reducers/lobby';
import maintenanceScheduleReducer from './reducers/maintenanceSchedule';
import playerGamesReducer from './reducers/playerGames';
import serverDetailsReducer from './reducers/serverDetails';
import userReducer from './reducers/user';

const store = configureStore({
  reducer: {
    currentTable: currentTableReducer,
    user: userReducer,
    playerGames: playerGamesReducer,
    liveChatNotifications: liveChatNotificationsReducer,
    lobby: lobbyReducer,
    maintenanceSchedule: maintenanceScheduleReducer,
    serverDetails: serverDetailsReducer
  },
  devTools: !!process.env.REACT_APP_REDUX_DEV_TOOL_ENABLE,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false
    })
});

export default store;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
