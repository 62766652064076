import { HAND_TYPE, months } from 'constants/gameConstants';
import Decimal from 'decimal.js';
import { Player } from 'reducers/currentTable';

export function isEmpty(obj: object) {
  return Object.keys(obj).length === 0;
}

export function removeDuplicates(arr: any[]) {
  return arr.filter((item, index) => arr.indexOf(item) === index);
}

/**
 * @param gotLobbyResponse Raw data received on 'gotLobby' socket event
 * @returns array[]
 */
export function newLobbySchema(gotLobbyResponse: any) {
  const array_version_of_raw_data = Object.values(gotLobbyResponse.response);

  const final = array_version_of_raw_data.map((data: any) => {
    if (!isEmpty(data.games)) {
      data.games = Object.values(data.games);
    } else {
      data.games = Object.values(data.games);
    }

    return data;
  });

  return final;
}

export function getCookie(cookieName: string) {
  const name = cookieName + '=';
  const cookieArray = document.cookie.split(';');
  for (let i = 0; i < cookieArray.length; i++) {
    let c = cookieArray[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

/**
 *
 * @param oktaToken Raw okta token as set in cookie storage
 * @returns {string} the jwt id token
 */
export function extractIDTokenFromCookie(oktaToken: string): string {
  const decodedOktaToken = decodeURIComponent(oktaToken);
  const oktaTokenObj = JSON.parse(decodedOktaToken) as any;
  return oktaTokenObj?.idToken?.idToken;
}

export function extractAccessTokenFromCookie(oktaToken: string): string {
  const decodedOktaToken = decodeURIComponent(oktaToken);
  const oktaTokenObj = JSON.parse(decodedOktaToken) as any;

  return oktaTokenObj?.accessToken?.accessToken;
}

export function extractIDTokenFromDescopeCookie(descopeCookie: string): string {
  const descopeTokenObj = cookieCleanUp(descopeCookie);
  return descopeTokenObj?.id_token;
}

export function extractAccessTokenFromDescopeCookie(descopeCookie: string): string {
  const descopeTokenObj = cookieCleanUp(descopeCookie);
  return descopeTokenObj?.access_token;
}

export function extractRefreshTokenFromDescopeCookie(descopeCookie: string): string {
  const descopeTokenObj = cookieCleanUp(descopeCookie);
  return descopeTokenObj?.refresh_token;
}

function cookieCleanUp(cookie: string): any {
  return JSON.parse(
    decodeURIComponent(cookie)?.replace(/\\054/g, ',')?.replace(/\s/g, '')?.replace(/\\"/g, '"')
  );
}
/**
 * Sets an okta cookie in the browser: for testing only
 * @returns
 */
export function setOktaCookie(cookieName: string, cookieValue: any): void {
  const cookieArray = document.cookie.split(';');

  for (let i = 0; i < cookieArray.length; i++) {
    let c = cookieArray[i];

    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }

    if (c.indexOf(`${cookieName}_${cookieName}`) === 0) continue;
    if (c.indexOf(cookieName) === 0) {
      const tempOktaToken = c.substring(cookieName.length + 1, c.length).replaceAll('"', '');
      const rawData = decodeURIComponent(tempOktaToken);
      let oktaToken = JSON.parse(rawData) as any;
      oktaToken = { ...oktaToken, ...cookieValue };

      return JSON.stringify(oktaToken) as any;
    }
  }
}

export function groupBy(array: any, groupingField: string) {
  const groupCategoriesObject = array.reduce((group: any, game: any) => {
    const category = game[groupingField];
    group[category] = group[category] ?? [];
    group[category].push(game);
    return group;
  }, {});

  return groupCategoriesObject;
}

export function sortData(rowData: any, sortingColumn: string, sortingDirection: string) {
  if (sortingColumn === null) {
    return rowData;
  }

  return rowData.slice().sort((a: any, b: any) => {
    let aValue = a[sortingColumn];
    let bValue = b[sortingColumn];

    // Function to parse currency values and handle 'K'
    const parseCurrency = (value: string) => {
      if (value.includes('K')) {
        return parseFloat(value.replace(/\$/g, '').replace('K', '')) * 1000;
      }
      return parseFloat(value.replace(/\$/g, ''));
    };

    // Handle Stake column by splitting into small blind and big blind
    if (sortingColumn === 'stakes') {
      const parseStake = (value: string | number) => {
        if (typeof value === 'string') {
          const [smallBlind, bigBlind] = value.replace(/\$/g, '').split('/');
          return {
            smallBlind: parseCurrency(smallBlind),
            bigBlind: parseCurrency(bigBlind)
          };
        }
        return { smallBlind: 0, bigBlind: 0 }; // Return default values if not string
      };

      const aStake = parseStake(aValue);
      const bStake = parseStake(bValue);

      // Sort by big blind first, then small blind if big blinds are equal
      if (aStake.bigBlind === bStake.bigBlind) {
        aValue = aStake.smallBlind;
        bValue = bStake.smallBlind;
      } else {
        aValue = aStake.bigBlind;
        bValue = bStake.bigBlind;
      }
    } else if (sortingColumn === 'tablelimit' || sortingColumn === 'tablelow') {
      // Handle Limit and Tablelow columns by removing dollar sign, handling 'K', and converting to number
      aValue = parseCurrency(aValue);
      bValue = parseCurrency(bValue);
    }

    // If the values are equal, return 0 (no sorting needed)
    if (aValue === bValue) {
      return 0;
    }

    // Sort in ascending or descending order
    if (sortingDirection === 'asc') {
      return aValue < bValue ? -1 : 1;
    } else {
      return aValue < bValue ? 1 : -1;
    }
  });
}

export function currencyFormatter(val: number | string) {
  return convertToNumber(val).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD'
  });
}

export function numberFormatter(val: number | string) {
  return convertToNumber(val).toLocaleString('en-US');
}

export function getCardValue(card: string) {
  // Remove suit
  let value: any = card?.substring(0, card.length - 1);

  // Turn letters into numbers
  if (isNaN(value)) {
    switch (value) {
      case 'A':
        // Ace
        value = 14;
        break;
      case 'K':
        value = 13;
        break;
      case 'Q':
        value = 12;
        break;
      case 'J':
        value = 11;
    }
  } else {
    value = parseInt(value);
  }
  return value;
}

export function parseOverBet(overBet: any) {
  if (!!overBet && overBet.split('|').length > 1) {
    const overBetValues = overBet.split('|');

    return {
      seatId: +overBetValues[0],
      bet: +overBetValues[1]
    };
  }

  return {
    seatId: 0,
    bet: 0
  };
}

export function convertToNumber(val: any) {
  // this will never happen be an empty string
  if (isNaN(val) || val === null) {
    return 0;
  }
  // But, just want to make sure
  if (typeof val === 'string') {
    if (val.trim() === '') {
      return 0;
    }
  }

  const value = new Decimal(val).toDP(2).toNumber();

  // Check if the value has decimal places
  const stringValue = value.toString();

  // If there are decimal places, format to 2 decimal places
  if (stringValue.includes('.')) {
    return +value.toFixed(2);
  } else {
    // If it's a whole number, return the value without decimal places
    return +value.toFixed(0);
  }
}

export function calculateMaxTopUp(tablelimit: number, ppot: number, userBalance: number) {
  const allowedTopUp = Decimal.sub(tablelimit, ppot).toNumber();
  if (allowedTopUp <= 0) {
    return 0;
  }
  if (userBalance >= allowedTopUp) {
    return allowedTopUp;
  }
  if (userBalance < allowedTopUp) {
    return userBalance;
  }
  return 0;
}

// export function isWinner(runWinners: Run, seatId: number) {
//   for (const key in runWinners) {
//     const runWinner = (runWinners as any)[key];
//     if (!runWinner?.winTypes || !runWinner?.winners) {
//       continue;
//     }

//     if (runWinner.winTypes[runWinner.winners.indexOf(`${seatId}`)] === 'win') {
//       return true;
//     }
//   }

//   return false;
// }

export function getPlayerStatus(game: any, seatId: number) {
  /*
   *   Old reference https://github.com/Hijack-Poker/game/blob/main/texas/texasPlayer.js line 29
   *
   *   Player Status:
   *   0 = Seated
   *   1 = Sitout
   *   2 = Leave Game -- will kick the player after the game finishes
   *   3 = Show Cards
   *   4 = Join - wait for the button
   *   5 = Join - Buy the button
   *   6 =
   *   7 =
   *   8 = Show Cards
   */
  if (seatId < 1) {
    return {
      isWaiting: false
    };
  }

  if (game['p' + seatId + 'bet'].substring(0, 1) === 'J') {
    return {
      isWaiting: true
    };
  }

  switch (game['p' + seatId + 'status']) {
    case 4:
    case 5:
    case 9:
    case 10:
      // Join - wait for the button
      return {
        isWaiting: true
      };
    default:
      return {
        isWaiting: false
      };
  }
}

export function getPercentage(currentValue: number, targetValue: number) {
  return Math.min(Math.floor((currentValue / targetValue) * 100), 100);
}

export function isValidJsonString(str: string) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }

  return true;
}

export function getBetValue(player: Player) {
  if (!player.bet || player.bet.substr(0, 1) === 'J') {
    return 0;
  }

  return new Decimal(player.bet.replace('F', '')).toNumber();
}

export function secondsToTime(seconds: number) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  const timeString = [hours, minutes, remainingSeconds]
    .map((value) => String(value).padStart(2, '0')) // Add leading zero if needed
    .join(':'); // Format as HH:MM:SS

  return timeString;
}

export function encode(str: string) {
  return encodeURIComponent(str)
    .replace(/-/g, '%2D')
    .replace(/_/g, '%5F')
    .replace(/\./g, '%2E')
    .replace(/!/g, '%21')
    .replace(/~/g, '%7E')
    .replace(/\*/g, '%2A')
    .replace(/'/g, '%27')
    .replace(/\(/g, '%28')
    .replace(/\)/g, '%29');
}

export function decode(str: string) {
  return decodeURIComponent(
    str
      .replace(/\\%2D/g, '-')
      .replace(/\\%5F/g, '_')
      .replace(/\\%2E/g, '.')
      .replace(/\\%21/g, '!')
      .replace(/\\%7E/g, '~')
      .replace(/\\%2A/g, '*')
      .replace(/\\%27/g, "'")
      .replace(/\\%28/g, '(')
      .replace(/\\%29/g, ')')
  );
}

export function toTitleCase(str: string) {
  const titleCase = str
    .toLowerCase()
    .split(' ')
    .map((word: string) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(' ');

  return titleCase;
}

export function getTransformScale(
  width: number,
  height: number,
  minScale = 0.58,
  aspectRatio: number = 16 / 9,
  baseScreenWidth = 2560
) {
  const devicePixelRatio = window.devicePixelRatio || 1;
  const initialWidth = width * devicePixelRatio;
  const initialHeight = height * devicePixelRatio;
  const newWidth =
    initialHeight < initialWidth / aspectRatio ? initialHeight * aspectRatio : initialWidth;

  if (devicePixelRatio > 1) {
    return Math.min((newWidth / baseScreenWidth) * 0.88, 1);
  }

  return Math.min(Math.max(newWidth / baseScreenWidth, minScale), 1);
}

export function getOrdinalSuffix(numbers: string) {
  const numberArray = String(numbers).split(',');

  const formattedNumbers = numberArray.map((number) => {
    let suffix = 'th';

    if (number === '1') {
      suffix = 'st';
    } else if (number === '2') {
      suffix = 'nd';
    } else if (number === '3') {
      suffix = 'rd';
    }

    return `${number}${suffix}`;
  });

  if (formattedNumbers.length === 1) {
    return formattedNumbers[0];
  }

  const lastNumber = formattedNumbers.pop();
  const formattedString = formattedNumbers.join(', ') + ', and ' + lastNumber;

  return formattedString;
}

export function formatTime(seconds: number) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  let formattedTime = '';
  if (hours > 0) {
    formattedTime += `${hours} hour${hours > 1 ? 's' : ''} `;
  }
  if (minutes > 0) {
    formattedTime += `${minutes} minute${minutes > 1 ? 's' : ''} `;
  }
  if (remainingSeconds > 0) {
    formattedTime += `${remainingSeconds} second${remainingSeconds > 1 ? 's' : ''}`;
  }

  return formattedTime.trim();
}

export function getLocalTime(timestamp: number) {
  const date = new Date(timestamp * 1000);
  const ampm = date.getHours() >= 12 ? 'PM' : 'AM';

  // Format the date and time
  const formattedDate = `${months[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;
  const formattedTime = `${date.getHours() % 12 || 12}:${String(date.getMinutes()).padStart(
    2,
    '0'
  )} ${ampm}`;

  return `${formattedDate} ${formattedTime}`;
}

export function toDecimal(val: number, decimalPlaces = 6) {
  if (!val) {
    return new Decimal(0);
  }

  return new Decimal(val).toDP(decimalPlaces);
}

export function toLocaleStringNumber(val: any) {
  return Number(val).toLocaleString('en-US');
}

export function calculateCountdown(
  levelStarted: number,
  serverTime: number,
  countDownMinutes: number,
  nextLevel: number
) {
  const elapsedTime = serverTime - levelStarted;
  const remainingTime = countDownMinutes * 60 - elapsedTime;

  if (nextLevel !== -1 && remainingTime <= 0) {
    return '00:00:00'; // Countdown has reached zero
  } else if (nextLevel === -1 && remainingTime <= 0) {
    return 'Max Level';
  }

  return secondsToTime(remainingTime);
}

export function humanizeCountdown(seconds: number) {
  if (seconds === 0) {
    return 'Ended';
  }

  const timeUnits = [
    { label: 'day', value: Math.floor(seconds / 86400) },
    { label: 'hour', value: Math.floor((seconds % 86400) / 3600) },
    { label: 'min', value: Math.floor((seconds % 3600) / 60) },
    { label: 'sec', value: seconds % 60 }
  ];

  const parts = timeUnits
    .filter((unit) => unit.value !== 0)
    .map((unit) => `${unit.value} ${unit.label}${unit.value !== 1 ? 's' : ''}`);

  return parts.join(' ');
}

export function calculateStepValue(baseValue: number) {
  const magnitude = baseValue * 0.25;
  const validSteps = [0.01, 0.02, 0.05, 0.1, 0.2, 0.5];
  const closestStep = validSteps.reduce((acc, curr) => {
    return Math.abs(curr - magnitude) < Math.abs(acc - magnitude) ? curr : acc;
  });

  return closestStep;
}

export function isWinner(pots: any, showdown: any, seatId: number) {
  const pot = getWinnersPot(pots, showdown);

  return pot?.winners?.some(
    (f: any) => isHandTypeMatched(f, showdown?.handType) && +f.player === seatId
  );
}

export function isHighWinner(pots: any, showdown: any, seatId: number) {
  const pot = getWinnersPot(pots, showdown);

  return pot?.winners?.some(
    (f: any) =>
      isHandTypeMatched(f, showdown?.handType) &&
      +f.player === seatId &&
      (!f.handType || f.handType === HAND_TYPE.HI)
  );
}

export function isLowWinner(pots: any, showdown: any, seatId: number) {
  const pot = getWinnersPot(pots, showdown);

  return pot?.winners?.some(
    (f: any) =>
      isHandTypeMatched(f, showdown?.handType) &&
      +f.player === seatId &&
      f.handType === HAND_TYPE.LO
  );
}

export function isExecuteWinningHand(showdown: any) {
  const { currentStep } = showdown;

  if (+currentStep < 2) {
    return false;
  }

  return true;
}

export function potWinningHand(pots: any, showdown: any) {
  const { currentPot, handType } = showdown;

  if (!isExecuteWinningHand(showdown)) {
    return null;
  }

  return pots[currentPot]?.winners?.find(
    (player: any) => isHandTypeMatched(player, handType) && player?.hand
  )?.hand;
}

export function isWinningCard(pots: any, showdown: any, card: string) {
  const { currentPot, handType } = showdown;

  if (!isExecuteWinningHand(showdown)) {
    return false;
  }

  const pot = pots[currentPot] as { winners: any[] };

  return pot?.winners?.some((f) => isHandTypeMatched(f, handType) && f.cards.indexOf(card) > -1);
}

export function playerPotWin(pots: any, showdown: any, seatId: number) {
  const { currentPot, currentStep, handType } = showdown;

  if (+currentStep < 3) {
    return 0;
  }

  const pot = pots[currentPot] as { winners: any[] };

  return (
    pot?.winners
      ?.filter((f) => isHandTypeMatched(f, handType) && +f.player === seatId)
      .map((f) => +f.win)
      .reduce((total, num) => total + num, 0) ?? 0
  );
}

function getWinnersPot(pots: any, showdown: any): any {
  const { currentPot, currentStep } = showdown;

  if (+currentStep < 2) {
    return false;
  }

  return pots[currentPot] as { winners: any[] };
}

function isHandTypeMatched(player: any, handType: string) {
  if (!handType || !player.handType) {
    return true;
  }

  return player.handType === handType;
}
