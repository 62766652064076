import React from 'react';
import styled from '@emotion/styled';
import Box from '@mui/material/Box';
import { hiJackColors } from 'constants/colorConstants';

type TPotDetailProps = {
  data: TPotDetail;
  isEmptyCards: boolean;
};

export type TPotDetail = {
  myTurn: number;
  bet: string;
  status: number;
  hand: string;
};

const YourTurn = (props: TPotDetailProps) => {
  const { data, isEmptyCards } = props;

  const RenderTurnMessage = () => {
    if (!data || (data?.status === 0 && data?.hand === '' && data?.myTurn === 0)) {
      return null;
    }

    if (data?.hand !== '' && data?.myTurn === 1 && !isEmptyCards) {
      return <div style={myTurnStyles}>Your turn!</div>;
    }

    if (data?.bet?.substring(0, 1) === 'F') {
      return <div style={foldSitoutStyle}>Folded</div>;
    }

    if (data?.status === 1 && data?.hand === '' && data?.myTurn === 0) {
      return <div style={foldSitoutStyle}>Sat out</div>;
    }

    return null;
  };

  return <TurnWrapper>{RenderTurnMessage()}</TurnWrapper>;
};

const TurnWrapper = styled(Box)({
  boxShadow: `0px 0px 2.59854px ${hiJackColors.darkGray}, 0px 0px 8.5912px ${hiJackColors.darkGray}`,
  background: hiJackColors.darkGray,
  textAlign: 'center',
  fontSize: '0.5rem',
  width: '100%',
  height: 15,
  color: hiJackColors.white
});

const myTurnStyles: React.CSSProperties = {
  boxShadow: `0px 0px 2.59854px ${hiJackColors.orange}, 0px 0px 8.5912px ${hiJackColors.orange}`,
  background: hiJackColors.orange,
  padding: '.1rem 0'
};

const foldSitoutStyle: React.CSSProperties = {
  boxShadow: `0px 0px 2.59854px ${hiJackColors.deepMaroon}, 0px 0px 8.5912px ${hiJackColors.deepMaroon}`,
  background: hiJackColors.deepMaroon,
  padding: '.1rem 0'
};

export default YourTurn;
