import React, { CSSProperties, useEffect, useState } from 'react';
import { a, useSpring } from 'react-spring';
import { Paper } from '@mui/material';
import { HAND_TYPE } from 'constants/gameConstants';
import { useAppSelector } from 'hooks';
import { numberFormatter, toDecimal } from 'utils/functions';

type CashPropType = {
  cash: number;
  player: string;
  playerGUID: string;
  isCharged: boolean;
  seatId: number;
};

export const Cash = ({ cash, player, playerGUID, isCharged, seatId }: CashPropType) => {
  const {
    tableOverBet,
    tabletype: tableType,
    pots,
    showdown: { currentPot, currentStep, handType }
  } = useAppSelector((state) => state.currentTable.game);
  const [cashAmount, setCashAmount] = useState<number>(0);
  const [totalCashAmount, setTotalCashAmount] = useState<number>(0);
  const tableCharges = useAppSelector<any>((state) => state.currentTable.tableCharges);
  let multiplier = 0;
  if (tableCharges[playerGUID] !== undefined) {
    multiplier = tableCharges[playerGUID]?.multiplier;
    player += ` - C${multiplier}`;
  }

  useEffect(() => {
    let total = 0;

    for (let i = 0; i <= currentPot; i++) {
      const pot = pots[i];
      const winners = pot?.winners?.filter((f) => +f.player === +seatId);

      if (winners?.length > 0) {
        total += winners
          .filter(
            (f) =>
              i < +currentPot ||
              (i === +currentPot &&
                ((f.handType === HAND_TYPE.HI && handType === HAND_TYPE.LO) ||
                  (f.handType === handType && +currentStep > 2) ||
                  (!f.handType && +currentStep > 2)))
          )
          .map((f) => +f.win)
          .reduce((t, num) => t + num, 0);
      }
    }

    if (tableOverBet.seatId === seatId) {
      total += +tableOverBet?.bet ?? 0;
    }

    setTotalCashAmount(cash + total);
  }, [cash, seatId, currentPot, currentStep]);

  useSpring({
    number: totalCashAmount,
    config: {
      mass: 1,
      tension: 170,
      friction: 26,
      steps: 1.0
    },
    onChange({ value }) {
      /* Always update the value of number to be float */
      let bet = parseFloat(value.number).toFixed(2);
      if (tableType !== 's') bet = String(toDecimal(value.number));
      setCashAmount(+bet);
    }
  });

  return (
    <div style={playerInfoStyle}>
      <div style={playerCashStyle}>
        <Paper sx={textStyle} elevation={6} className={`${isCharged && 'bgglow'}`}>
          <a.span>
            {tableType === 'm' || tableType === 't'
              ? numberFormatter(cashAmount)
              : '$' + numberFormatter(cashAmount)}
          </a.span>
          <div style={playerNameStyle}>{player}</div>
        </Paper>
      </div>
    </div>
  );
};

const playerInfoStyle: CSSProperties = {
  position: 'absolute',
  top: 88,
  display: 'flex',
  flexDirection: 'column',
  width: '100%'
};

const playerNameStyle: CSSProperties = {
  whiteSpace: 'nowrap',
  display: 'flex',
  justifyContent: 'center',
  fontSize: '14px'
};

const playerCashStyle: CSSProperties = {
  display: 'flex',
  justifyContent: 'center'
};

const textStyle: CSSProperties = {
  borderRadius: '3rem',
  textAlign: 'center',
  backgroundColor: '#161b1f',
  fontSize: '1rem',
  color: '#e1e1e2',
  padding: '0.25rem 1.5rem'
};
