import React, { createContext, useState } from 'react';

type ScaleContextType = {
  scale: number;
  setScale: (value: number) => void;
};

export const ScaleContext = createContext<ScaleContextType>({
  scale: 1,
  setScale: () => {}
});

export const ScaleContextProvider = ({ children }: any) => {
  const [scale, setScale] = useState<number>(1);

  return <ScaleContext.Provider value={{ scale, setScale }}>{children}</ScaleContext.Provider>;
};

export default ScaleContext;
