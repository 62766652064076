import React from 'react';
import { Button, createTheme, Modal, ThemeProvider, Typography } from '@mui/material';
import { Box } from '@mui/system';

type PropType = {
  confirmationAnswer: (confirmation: any) => void;
};

function FoldConfirmationModal({ confirmationAnswer }: PropType) {
  const confirmAnswer = (answer: any) => {
    confirmationAnswer(answer);
  };

  return (
    <Modal open={true}>
      <Box sx={modalStyle}>
        <Typography
          id='modal-modal-title'
          sx={{ textAlign: 'center', marginBottom: '1rem', fontWeight: 'bold' }}>
          {`Hey partner, you sure? It's free to check!`}
        </Typography>
        <Typography id='modal-modal-title' sx={{ textAlign: 'center', marginBottom: '1rem' }}>
          {''}
        </Typography>
        <Typography sx={{ textAlign: 'center', marginBottom: '1rem' }}>{''}</Typography>
        <div
          style={{
            justifyContent: 'center',
            marginTop: '2rem',
            gap: '2rem'
          }}>
          <ThemeProvider theme={UnregisterBtnStyle}>
            <Button
              sx={{ display: 'block', opacity: '.8' }}
              variant='contained'
              onClick={() => confirmAnswer(2)}>
              {'Check'}
            </Button>
          </ThemeProvider>
          <ThemeProvider theme={UnregisterBtnStyle}>
            <Button
              sx={{ display: 'block', opacity: '.8' }}
              variant='contained'
              onClick={() => confirmAnswer(1)}>
              {'Fold Anyway'}
            </Button>
          </ThemeProvider>
          {/* <ThemeProvider theme={UnregisterBtnStyle}>
            <Button
              sx={{ display: 'block', opacity: '.8' }}
              variant='contained'
              onClick={() => confirmAnswer(2)}>
              {"Yes, don't ask again next time"}
            </Button>
          </ThemeProvider> */}
        </div>
      </Box>
    </Modal>
  );
}

export default FoldConfirmationModal;

const modalStyle = {
  position: 'absolute' as const,
  top: '40%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: '#012425',
  color: 'white',
  padding: '4rem 7rem',
  width: '40rem',
  borderRadius: '0.5rem'
};

const UnregisterBtnStyle = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          color: 'white',
          backgroundColor: '#5b6671',
          '&:hover': {
            color: 'white',
            backgroundColor: '#37393b'
          },
          '&:disabled': {
            color: 'white',
            opacity: '0.4'
          },
          fontSize: '1rem',
          fontWeight: '400',
          padding: '0.5rem 1rem',
          minWidth: 'unset',
          textTransform: 'none',
          width: '100%',
          marginTop: '10px'
        }
      }
    }
  }
});
