import { PropsWithChildren } from 'react';

export const GREEN = '#45be26';
export const YELOW_GREEN = '#BEB226';
export const ORANGE = 'orange';
export const RED = 'red';

export const getProgressColor = (progress: number) => {
  if (progress >= 75) {
    return RED;
  } else if (progress > 50) {
    return ORANGE;
  } else if (progress > 25) {
    return YELOW_GREEN;
  } else {
    return GREEN;
  }
};

export type TimerPropType = PropsWithChildren & {
  start: number;
  current: number;
  target: number;
  isStartProgress: boolean;
  size: number;
};
