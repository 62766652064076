import React, { CSSProperties } from 'react';
import Logo from 'assets/images/hjlogo.png';

import styles from './MobileRedirect.module.scss';
// import AppStoreImage from "../../assets/images/AppStoreImage.svg"
// import PlayStoreImage from "../../assets/images/PlayStoreImage.svg"

const MobileRedirect = () => {
  return (
    <div className={styles.main_wrapper} style={mobileRedirectStyles}>
      <div style={logoContainerStyles}>
        <div>
          <img src={Logo} alt='HIJACK POKER' style={{ maxWidth: '20rem' }} />
        </div>
      </div>

      <div style={messageStyles}>
        It appears that you are accessing Hijack Poker on a mobile device or with unsupported screen
        size. Our mobile app is almost here and will be available for download on the App Store and
        Google Play in the near future. Stay tuned!
      </div>

      {/* <div style={mobileStoreContainerStyles}>
          <div>
            <img src={PlayStoreImage} alt="Play Store" width="100%" />
          </div>
          <div>
            <img src={AppStoreImage} alt="App Store" width="100%"/>
          </div>
      </div> */}
    </div>
  );
};

const mobileRedirectStyles: CSSProperties = {
  display: 'grid',
  gridTemplateRows: '30vh 30vh 1fr',
  gridTemplateColumns: '1fr',
  justifyItems: 'center'
};

const logoContainerStyles: CSSProperties = {
  display: 'flex',
  placeItems: 'center'
};

const messageStyles: CSSProperties = {
  width: '90%',
  fontSize: '1.45rem',
  margin: '0 auto',
  textAlign: 'center',
  display: 'flex',
  placeItems: 'center'
};

// const mobileStoreContainerStyles: CSSProperties = {
//   display: 'flex',
//   flexDirection: 'column',
//   flexWrap: 'wrap',
//   justifyContent: 'center',
//   gap: '1rem',
//   marginTop: '-3rem'
// };

export default MobileRedirect;
