import { useCallback, useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { CSSProperties } from 'react';
import React from 'react';
import { a, useSpring } from 'react-spring';
import { HAND_TYPE, TABLE_HEIGHT, TABLE_WIDTH } from 'constants/gameConstants';
import GameContext from 'contexts/GameContext';
import { Decimal } from 'decimal.js';
import { useAppSelector } from 'hooks';
import * as _ from 'lodash';
import { TablePot } from 'reducers/currentTable';
import { numberFormatter, toDecimal } from 'utils/functions';
import { convertToNumber } from 'utils/functions';

import { getSeatPosition } from './Seats';
import WinChips from './WinChips';

import './Pot.scss';

const Pot = () => {
  const { pots, showdown } = useAppSelector((state) => state.currentTable.game);
  const { isShowdown } = useAppSelector<any>((state) => state.currentTable.gameState);
  const players = useAppSelector<any>((state) => state.currentTable.players);
  const currentPlayerSeatId = useAppSelector((state) => state.currentTable.currentPlayer.seatId);
  const ante = useAppSelector((state) => state.currentTable.game.ante);
  const { seatPositions } = useContext(GameContext);

  const winners = useAppSelector<any>((state) => state.currentTable.game.winners);
  const [playerWinners, setPlayerWinners] = useState(winners);

  const isMultiRunActive = useAppSelector(
    (state) => state.currentTable.currentPlayer.isMultiRunActive
  );

  const tableType = useAppSelector<any>((state) => state.currentTable.game?.tabletype);

  const [elementRefs, setElementRefs] = useState<React.RefObject<HTMLDivElement>[]>(() =>
    Array(pots.length)
      .fill(null)
      .map(() => React.createRef<HTMLDivElement>())
  );

  // Create refs for each label element
  useEffect(() => {
    setElementRefs((refs) =>
      Array.from({ length: pots.length }).map((_, i) => refs[i] || React.createRef())
    );
  }, [pots]);

  const [positions, setPositions] = useState<{ left: number; top: number }[]>([]);

  // Calculate and set the left and top positions of each element
  useEffect(() => {
    const minDistance = 10; // Distance between the pots
    const lineHeight = 30; // Height of a single line, adjust accordingly

    // Stores the width and height of each element
    const widths = elementRefs.map((ref) => (ref.current ? ref.current.offsetWidth : 0));
    const heights = elementRefs.map((ref) => (ref.current ? ref.current.offsetHeight : 0));

    const tablePots = pots.slice(0, 4);
    const remainingPots = pots.slice(4);

    const tablePotsHeight =
      tablePots.reduce((acc, _, i) => acc + heights[i], 0) + minDistance * (tablePots.length - 1);
    const remainingPotsWidth = remainingPots.reduce(
      (acc, _, i) => acc + widths[i + 4] + (i === remainingPots.length - 1 ? 0 : minDistance),
      0
    );

    const tableStartPosition = {
      left: 100, // Fixed left position for the table pots
      top: (TABLE_HEIGHT - tablePotsHeight) / 2
    }; // Starting position for the table

    // Calculate the starting position for the top center of the screen based on the remaining pots' width
    const screenTopCenterPosition = {
      left: (TABLE_WIDTH - remainingPotsWidth) / 2,
      top: -267.6
    };

    const positions: { left: number; top: number }[] = [];

    for (let i = 0; i < pots.length; i++) {
      let leftPosition, topPosition;

      if (i < 4) {
        // Display the first 4 pots vertically on the table
        leftPosition = tableStartPosition.left;
        topPosition = tableStartPosition.top + i * (lineHeight + minDistance);
      } else {
        // Display the remaining pots horizontally at the center top of the screen
        if (i === 4) {
          leftPosition = screenTopCenterPosition.left;
        } else {
          leftPosition = positions[i - 1].left + widths[i - 1] + minDistance;
        }

        topPosition = screenTopCenterPosition.top;
      }

      positions.push({ left: leftPosition, top: topPosition });
    }

    // Update the state with the new positions
    setPositions(positions);
  }, [elementRefs, pots]);

  /**
   * End of temporary data. Remove after implementing the actual data
   */

  const { setPotPosition } = useContext(GameContext);
  const potRef = useRef<HTMLDivElement>(null);
  const deadMoney = useAppSelector((state) => state.currentTable.game.deadMoney);

  useLayoutEffect(() => {
    const width = potRef.current?.offsetWidth ?? 0;
    const height = potRef.current?.offsetHeight ?? 0;

    setPotPosition({
      left: (potRef?.current?.offsetLeft ?? 0) + width / 2,
      top: (potRef?.current?.offsetTop ?? 0) + height / 2
    });
  }, [setPotPosition, potRef?.current?.offsetLeft, potRef?.current?.offsetTop]);

  const movePot = useSpring({
    transform: isMultiRunActive ? `translate(-275px, -45px)` : 'translate(0, 0)',
    from: {
      transform: `translate(0, 0)`
    }
  });

  /* Assign pot Value to newPotValue */
  const [newPotValue, setNewPotValue] = useState(0);

  /* Convert newPotValue to float and assign to potFloat
   * use the potFloat to update the text on the table
   */
  const [potFloat, setPotFloat] = useState<any>(convertToNumber(newPotValue));

  const [transform, setTransform] = useSpring(() => ({
    transform: `scale(1)`,
    reset: true,
    config: {
      duration: 500,
      mass: 1,
      tension: 170,
      friction: 26
    }
  }));

  const resetPotScale = useCallback(
    _.debounce(() => {
      setTransform.start({ transform: `scale(1)` });
    }, 300),
    []
  );

  useEffect(() => {
    const totalWin =
      pots[showdown.currentPot]?.winners
        ?.filter(
          (f: any) =>
            (f.handType === HAND_TYPE.HI && showdown.handType === HAND_TYPE.LO) ||
            (f.handType === showdown.handType && +showdown.currentStep > 2) ||
            (!f.handType && +showdown.currentStep > 2)
        )
        .map((f: any) => +f.win)
        .reduce((t: any, num: any) => t + num, 0) ?? 0;

    setNewPotValue(
      Decimal.sub(Decimal.sum(pots[showdown.currentPot]?.pot ?? 0, deadMoney), totalWin)
        .toDP(2)
        .toNumber()
    );

    if (pots[showdown.currentPot] && showdown.currentStep < 3) {
      setPlayerWinners([]);
    } else {
      setPlayerWinners(
        pots[showdown.currentPot]?.winners.filter(
          (f) => !f.handType || f.handType === showdown.handType
        ) ?? []
      );
    }
    setTransform.start({ transform: `scale(1.5)` });
    resetPotScale();
  }, [
    ante,
    deadMoney,
    pots,
    resetPotScale,
    setTransform,
    showdown.currentStep,
    showdown.currentPot
  ]);

  useSpring({
    number: newPotValue,
    config: {
      mass: 1,
      tension: 170,
      friction: 26,
      steps: 1.0
    },
    onChange({ value }) {
      /* Always update the value of number to be float */
      setPotFloat(parseFloat(value.number).toFixed(2));
    }
  });

  return (
    <>
      <a.div
        style={{
          ...potContainerStyle,
          ...transform,
          ...movePot,
          opacity: pots.length <= 1 || (isShowdown && !!pots[showdown.currentPot]?.label) ? 1 : 0
        }}
        className='pot-container'
        ref={potRef}>
        <span className='text'>
          {isShowdown && !!pots[showdown.currentPot]?.label
            ? pots[showdown.currentPot]?.label
            : 'Pot'}
          :{' '}
          <a.span>
            {tableType === 'm' || tableType === 't'
              ? numberFormatter(+potFloat)
              : '$' + numberFormatter(potFloat)}
          </a.span>
        </span>
      </a.div>

      {pots.length >= 2 && (
        <div>
          {!isShowdown &&
            pots?.map(({ pot }: TablePot, idx: number) => {
              let potLabel = `SP(${idx})`;
              let totalPot = pot;

              if (idx === 0) {
                potLabel = 'MP';
                totalPot =
                  tableType !== 's'
                    ? +toDecimal(Decimal.sum(totalPot, deadMoney, ante).toNumber())
                    : Decimal.sum(totalPot, deadMoney, ante).toDP(2).toNumber();
              }

              return (
                <div
                  key={idx}
                  ref={elementRefs[idx]}
                  style={{
                    ...sidePotContainerStyle,
                    position: 'absolute',
                    left: positions[idx]?.left,
                    top: positions[idx]?.top
                  }}
                  className='sidepot-container'>
                  <span className='text'>
                    <b>{potLabel}</b>:{' '}
                    {tableType === 'm' || tableType === 't'
                      ? numberFormatter(totalPot)
                      : '$' + numberFormatter(totalPot)}
                  </span>
                </div>
              );
            })}
        </div>
      )}

      {playerWinners.map((winner: any) => {
        const playerSeatPosition = getSeatPosition(
          seatPositions,
          players,
          +winner.player,
          currentPlayerSeatId
        );
        return (
          playerSeatPosition && (
            <WinChips
              key={`win-chip-${winner.player}`}
              seatId={+winner.player}
              seatPosition={playerSeatPosition}
            />
          )
        );
      })}
    </>
  );
};

export default Pot;

const potContainerStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  gap: '3px',
  bottom: `6rem`,
  borderRadius: `2rem`,
  padding: `0.5rem 1rem`,
  zIndex: 2
};

const sidePotContainerStyle: CSSProperties = {
  borderRadius: `2rem`,
  padding: `${0.5}rem 1rem`,
  zIndex: 2
};
