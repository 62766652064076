import React, { CSSProperties, useContext, useEffect } from 'react';
import { animated, useSpring } from 'react-spring';
import hijackTableLogo from 'assets/logo-table-hijack.svg';
import {
  BET_PATH_HEIGHT,
  BET_PATH_WIDTH,
  INNER_TABLE_HEIGHT,
  INNER_TABLE_WIDTH,
  OVERALL_HEIGHT,
  OVERALL_WIDTH,
  SEAT_DISTANCE_FROM_TABLE,
  SEAT_HEIGHT,
  SEAT_WIDTH,
  TABLE_HEIGHT,
  TABLE_PADDING_X,
  TABLE_PADDING_Y,
  TABLE_TOP_MARGIN,
  TABLE_WIDTH
} from 'constants/gameConstants';
import GameContext from 'contexts/GameContext';
import ScaleContext from 'contexts/ScaleContext';
import { useAppSelector } from 'hooks';

import Bets from './Bets';
import { DealerButton } from './DealerButton';
import HoldEmCards from './HoldEmCards';
import { OvalTable, useGeneratePositions } from './OvalTable';
import Pot from './Pot';
import Seats from './Seats';
import TableDetails from './TableDetails';
import TableWinnerMessage from './TableWinnerMessage';
import TournamentTableDetails from './TournamentTableDetails';

const Table = () => {
  const isShowdown = useAppSelector((state) => state.currentTable.gameState.isShowdown);

  const guid = useAppSelector((state) => state.user.playerGUID);
  const { setSeatPositions, setBetPositions, isPlayerReady } = useContext(GameContext);
  const { scale } = useContext(ScaleContext);

  const { positions: seatPositions, ref: seatPositionsRef } = useGeneratePositions();
  const { positions: betPositions, ref: betPositionsRef } = useGeneratePositions();

  useEffect(() => {
    setSeatPositions(seatPositions);
    setBetPositions(betPositions);
  }, [seatPositions, betPositions, setSeatPositions, setBetPositions]);

  const overlayAnimation = useSpring({
    from: {
      opacity: 0
    },
    to: isShowdown ? { opacity: 0.8 } : { opacity: 0.0 },
    config: { mass: 5, tension: 1000, friction: 50, duration: 500 }
  });

  return (
    <>
      <animated.div style={{ ...overlayAnimation, ...overlayEffect }}></animated.div>
      <div
        style={{
          position: 'absolute',
          top: `${TABLE_TOP_MARGIN * scale}px`,
          height: `calc(100% - ${TABLE_TOP_MARGIN * scale}px)`,
          width: '100%',
          transform: `scale(${scale})`,
          transformOrigin: 'top center'
        }}>
        <OvalTable width={OVERALL_WIDTH} height={OVERALL_HEIGHT} ref={seatPositionsRef} />
        <OvalTable width={BET_PATH_WIDTH} height={BET_PATH_HEIGHT} ref={betPositionsRef} />

        <div style={tableStyle}>
          <div style={ovalTableStyle}>
            <div style={ovalBorderStyle}></div>
            <Pot />
            <TableDetails />
            <TournamentTableDetails />
            <HoldEmCards />
            <TableWinnerMessage />
            <div style={innerOvalTableStyle}></div>
            <div style={betPathStyle}>
              {isPlayerReady && <Bets />}
              {isPlayerReady && <DealerButton />}
            </div>
          </div>
          <Seats guid={guid} />
        </div>
      </div>
    </>
  );
};

export default Table;

const tableStyle: CSSProperties = {
  position: 'absolute',
  top: 0,
  left: `calc((100% / 2) - (${OVERALL_WIDTH / 2}px) - ${SEAT_WIDTH / 2}px)`,
  width: OVERALL_WIDTH,
  height: OVERALL_HEIGHT,
  zIndex: 1
};

const ovalTableStyle: CSSProperties = {
  position: 'relative',
  marginTop: SEAT_DISTANCE_FROM_TABLE + SEAT_HEIGHT,
  marginLeft: SEAT_DISTANCE_FROM_TABLE + SEAT_WIDTH / 2,
  width: TABLE_WIDTH,
  height: TABLE_HEIGHT,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 1
};

const ovalBorderStyle: CSSProperties = {
  position: 'absolute',
  backgroundColor: '#B46041',
  border: '8px solid #ca856a',
  width: '100%',
  height: '100%',
  borderRadius: TABLE_HEIGHT,
  zIndex: 1
};

const innerOvalTableStyle: CSSProperties = {
  width: INNER_TABLE_WIDTH,
  height: INNER_TABLE_HEIGHT,
  borderRadius: INNER_TABLE_HEIGHT / 2,
  backgroundColor: '#ac5638',
  backgroundImage: `url(${hijackTableLogo})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  backgroundSize: `480px`,
  zIndex: 1
};

const betPathStyle: CSSProperties = {
  position: 'absolute',
  top: TABLE_PADDING_Y,
  left: TABLE_PADDING_X,
  width: BET_PATH_WIDTH,
  height: BET_PATH_HEIGHT,
  zIndex: 1
};

const overlayEffect: CSSProperties = {
  backgroundColor: '#000000',
  height: '100%',
  width: '100%',
  position: 'absolute'
};
