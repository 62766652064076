import { createSlice } from '@reduxjs/toolkit';

interface PromoNotification {
  id: number;
  message: string;
  isClaimed: number;
  isExpired: number;
  isRead: number;
  timestamp: number;
}

const initialState = {
  playerId: 0,
  totalWin: 0,
  points: 0,
  playerName: '',
  displayName: '',
  playerRank: '',
  playerBank: '',
  playerBankAmount: 0,
  playerChargeBalance: 0,
  playerAvatar: '',
  playerEmail: '',
  playerLevel: 0,
  playerLevelStr: '',
  playerLevelPrg: 0,
  playerLevelPoints: 0,
  playerGold: 0,
  playerDealerId: 0,
  playerFbId: '',
  playerGUID: '',
  loadVocab: '',
  dailyBonus: 0,
  token: '',
  ip: '',
  interface: {},
  playerCountry: '',
  playerLanguage: '',
  guest: true,
  playerCharged: false,
  playerChipsAdded: false,
  promoNotifications: [] as PromoNotification[],
  handRank: {
    name: 'High Card',
    value: 0
  },
  isRestrictedFlag: 1,
  errorMsg: {
    msg: '',
    addFunds: false
  }
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateBankAmount: (state, action) => {
      state.playerBankAmount = action.payload?.response?.playWinpot;
      state.playerChargeBalance = action.payload?.response?.playChargeBalance;
      state.promoNotifications = action.payload?.promoNotifications;
      // console.log(action.payload?.promoNotifications);
    },
    updateAvatar: (state, action) => {
      state.playerAvatar = action.payload?.playerAvatar;
    },
    updateCharged: (state, action) => {
      state.playerCharged = action.payload?.playerGUID === state.playerGUID;
    },
    updateUser: (state, action) => {
      return {
        ...state,
        ...(action.payload?.response ?? {}),
        displayName: action.payload?.response?.displayName
          ? decodeURIComponent(action.payload.response.displayName)
          : '',
        isRestrictedFlag: action.payload?.response.isRestrictedFlag
      };
    },
    updateHandRank: (state, action) => {
      state.handRank = action.payload;
    },
    updateErrorMsg: (state, action) => {
      state.errorMsg = action.payload;
    }
  }
});

export const {
  updateBankAmount,
  updateAvatar,
  updateCharged,
  updateUser,
  updateHandRank,
  updateErrorMsg
} = userSlice.actions;

export default userSlice.reducer;
