import { createSlice } from '@reduxjs/toolkit';

type LiveChatNotification = {
  gameID: number;
  playerGUID: string;
  lastUnreadMessageGUID: string;
  unreadMessageCount: number;
};

export const liveChatNotificationsSlice = createSlice({
  name: 'liveChatNotifications',
  initialState: [] as LiveChatNotification[],
  reducers: {
    updateLiveChatNotifications: (state, action) => {
      return action.payload;
    }
  }
});

export const { updateLiveChatNotifications } = liveChatNotificationsSlice.actions;

export default liveChatNotificationsSlice.reducer;
