import React, { CSSProperties, useCallback, useEffect, useRef, useState } from 'react';
import { a, config, useSprings } from 'react-spring';
import { audioPath } from 'constants/audioConstants';
import {
  BET_PREFIX,
  CARD_SOURCE_POSITION_LEFT,
  CARD_SOURCE_POSITION_TOP,
  SEAT_CONTAINER_WIDTH,
  SMALL_CARD_HEIGHT,
  SMALL_CARD_WIDTH
} from 'constants/gameConstants';
import { useAppSelector } from 'hooks';
import useSound from 'use-sound';
import { isWinningCard } from 'utils/functions';

import { isFaceDownCard, isValidCard, mapToCard } from 'components/Card';
import SmallCard from 'components/Card/SmallCard';
import { Position } from 'components/Table/OvalTable';

const INITIAL_CARD_TOP_POSITION = SMALL_CARD_HEIGHT - SEAT_CONTAINER_WIDTH - 5;
const INITIAL_CARD_LEFT_POSITION = 10;

type SeatCardsPropType = {
  cardPosition: CardPosition;
  position: Position;
  cards: string[];
  seatId: number;
};

export enum CardPosition {
  Left = 'LEFT',
  Right = 'RIGHT',
  Center = 'CENTER'
}

export const SeatCards = ({ position, cards, seatId }: SeatCardsPropType) => {
  const game = useAppSelector<any>((state) => state.currentTable.game);
  const { pots, showdown, languageKey } = game;

  const { preDeal, isShowdown } = useAppSelector((state) => state.currentTable.gameState);
  const [isCurrentUserFolded, setIsCurrentUserFolded] = useState(false);

  const [playSpritDealingCards] = useSound(audioPath.dealingCards, {
    sprite: {
      dealingCards: [0 * 1000, 0.5 * 1000]
    }
  });

  const playSoundDealingCard = useCallback(
    (id: string) => {
      playSpritDealingCards({ id });
    },
    [playSpritDealingCards]
  );

  useEffect(() => {
    setIsCurrentUserFolded(game['p' + seatId + 'bet'].substring(0, 1) === BET_PREFIX.FOLD);
  }, [game, seatId]);

  const sourceStyle = {
    opacity: 0,
    ...rotateCardStyle(CardPosition.Center),
    left: CARD_SOURCE_POSITION_LEFT,
    top: CARD_SOURCE_POSITION_TOP
  };

  const cardRefs = useRef([]);

  const cardSprings = useSprings(
    cards.length,
    cards.map((_: any, index: number) => ({
      ref: cardRefs.current[index],
      from: sourceStyle,
      to: preDeal || isCurrentUserFolded ? sourceStyle : getCardStyleByPosition(index, position),
      delay: (index + 1) * 200,
      config: config.stiff
    }))
  );

  useEffect(() => {
    playSoundDealingCard('dealingCards');
  }, [preDeal]);

  return (
    <>
      {cards
        .filter((card) => isValidCard(card) || isFaceDownCard(card))
        .map((card: string, index: number) => {
          const isFaceDown = isFaceDownCard(card);
          const isWinning = isWinningCard(pots, showdown, cards[index]);
          const pokerCard = mapToCard(cards[index]) ?? {};

          return (
            <a.div
              style={{
                ...cardStyle,
                ...(cardSprings[index] as any),
                ...((isCurrentUserFolded ||
                  (!isWinning &&
                    isShowdown &&
                    +showdown.currentStep > 1 &&
                    languageKey !== 'GAME_MSG_ALLFOLD')) &&
                  darkenCard)
              }}>
              <SmallCard elevation={4} {...pokerCard} isFaceDown={isFaceDown} />
            </a.div>
          );
        })}
    </>
  );
};

const cardStyle: CSSProperties = {
  position: 'absolute',
  zIndex: 2
};

const rotateCardStyle = (position: CardPosition) => {
  let degree;

  switch (position) {
    case CardPosition.Left:
      degree = -11.5;
      break;

    case CardPosition.Right:
      degree = 11.5;
      break;

    case CardPosition.Center:
    default:
      degree = 0;
      break;
  }

  return {
    transform: `rotate(${degree}deg)`
  } as CSSProperties;
};

const getCardStyleByPosition = (index: number, position: Position) => {
  if (position?.isRight) {
    return {
      opacity: 1,
      ...rotateCardStyle(CardPosition.Center),
      left:
        position.left +
        INITIAL_CARD_LEFT_POSITION +
        SMALL_CARD_WIDTH * index +
        SEAT_CONTAINER_WIDTH -
        10 * index,
      top: position.top - INITIAL_CARD_TOP_POSITION
    };
  }

  return {
    opacity: 1,
    ...rotateCardStyle(CardPosition.Center),
    left:
      position.left -
      INITIAL_CARD_LEFT_POSITION -
      SMALL_CARD_WIDTH * index -
      SMALL_CARD_WIDTH +
      10 * index,
    top: position.top - INITIAL_CARD_TOP_POSITION
  };
};

const darkenCard: CSSProperties = {
  filter: 'brightness(0.4)'
};
