export const FACEDOWN = 'facedown';

export const VIEW_SIZE_PERCENTAGE = 1;

export const TIME_PROGRESS_BAR_THICKNESS = 4;
export const AWAIT_BUY_IN_PROGRESS_BAR_THICKNESS = 4;

// CARD SETUP
export const SMALL_CARD_HEIGHT = 80;
export const SMALL_CARD_WIDTH = 48;

// CARD SOURCE SETUP
export const CARD_SOURCE_POSITION_TOP = 284;
export const CARD_SOURCE_POSITION_LEFT = 624;

// TABLE SETUP
export const TABLE_WIDTH = 1024;
export const TABLE_HEIGHT = 352;

export const TABLE_PADDING_X = 20;
export const TABLE_PADDING_Y = 18;

export const INNER_TABLE_WIDTH = TABLE_WIDTH - TABLE_PADDING_X * 2;
export const INNER_TABLE_HEIGHT = TABLE_HEIGHT - TABLE_PADDING_Y * 2;

export const SEAT_DISTANCE_FROM_TABLE = 100;
export const SEAT_WIDTH = 72;
export const SEAT_HEIGHT = 72;

export const BET_WIDTH = 32;
export const BET_HEIGHT = 32;

export const DEALER_WIDTH = 36;
export const DEALER_HEIGHT = 36;

export const OVERALL_WIDTH = TABLE_WIDTH + SEAT_DISTANCE_FROM_TABLE * 2;
export const OVERALL_HEIGHT = TABLE_HEIGHT + SEAT_DISTANCE_FROM_TABLE * 2;

export const BET_PATH_WIDTH = TABLE_WIDTH - TABLE_PADDING_X * 2 - DEALER_WIDTH;
export const BET_PATH_HEIGHT = TABLE_HEIGHT - TABLE_PADDING_Y * 2 - DEALER_HEIGHT;

export const TABLE_TOP_MARGIN = 130;

// SEAT SETUP
export const SEAT_CONTAINER_WIDTH = SEAT_WIDTH;
export const SEAT_CONTAINER_HEIGHT = SEAT_HEIGHT;
export const SEAT_TIMER_SIZE = SEAT_WIDTH + TIME_PROGRESS_BAR_THICKNESS * 2 * 2.25;

// SCREEN BREAKPOINT
export const ALLOWED_BROWSER_MINIMUM_SCREEN_SIZE = 992; // Measured in px

// SPOTLIGHT
export const SPOTLIGHT_HEIGHT = 250;
export const SPOTLIGHT_WIDTH = 250;

// LEFT PANE
export const LEFT_PANE_WIDTH = 350;

// COLORS
export const INSET_WINNER_AVATAR = '#BDFF94';
export const GLOW_OFF_WINNER_AVATAR = 'rgba(98,139,72,0)';
export const GLOW_ON_WINNER_AVATAR = 'rgba(98,139,72,1)';

export const INSET_SPLIT_WINNER_AVATAR = '#FFDF99';
export const GLOW_OFF_SPLIT_WINNER_AVATAR = 'rgba(233,140,38,0)';
export const GLOW_ON_SPLIT_WINNER_AVATAR = 'rgba(233,140,38,1)';

export const INSET_OTHER_WINNERS_AVATAR = '#FFDF99';
export const GLOW_OFF_OTHER_WINNERS_AVATAR = 'rgba(233,140,38,0)';
export const GLOW_ON_OTHER_WINNERS_AVATAR = 'rgba(233,140,38,1)';

export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

export const TABLE_TYPE_SITNGO = 't';
export const TABLE_TYPE_TOURNAMENT = 'm';

export const MULTI_RUN_VOTING_PREFIX = 'v';

export const BET_PREFIX = {
  FOLD: 'F',
  JOIN: 'J'
};

export const PLAYER_STATUS = {
  ACTIVE: 0,
  SITTING_OUT: 1,
  LEAVING: 2,
  SHOW_CARDS: 3,
  POST_BLIND: 4,
  WAIT_FOR_BB: 5,
  FOR_SITOUT: 6,
  FOR_CHARGING: 7,
  SHOW_CARDS_FOR_SITOUT: 8,
  IMMEDIATE_KICK: 9,
  EXIT_TABLE: 10,
  SHOW_CARDS_FOR_STANDUP: 11,
  BUSTED: 12,
  ADD_FUND: 13,
  KICK_AFTER_CHARGE_TIMER_EXPIRY: 14,
  EXIT_TABLE_WAIT_FOR_BB: 17,
  EXIT_TABLE_POST_BLIND: 18,
  MUCK_CARDS: 19,
  MUCK_CARDS_FOR_SITOUT: 20,
  MUCK_CARDS_FOR_STANDUP: 21,
  SHOW_CARDS_FOR_EXIT: 22
};

export const PLAYER_ACTION = {
  CALL: 'call',
  CHECK: 'check',
  BET: 'bet',
  RAISE: 'raise',
  FOLD: 'fold',
  ALLIN: 'allin',
  STRADDLE: 'straddle'
};

export const PLAYER_PRE_ACTION = {
  CALL_ANY: 'callany',
  CHECK_OR_FOLD: 'checkorfold',
  FOLD: 'fold',
  CHECK: 'check',
  CALL: 'call',
  RAISE: 'raise',
  ALLIN: 'allin'
};

export const GAME_HAND = {
  GAME_NOT_STARTED: '',
  DEALER_HAS_BEEN_CHOOSEN: 0,
  ADJUST_BLINDS: 1,
  SETUP_DEALER: 2,
  SETUP_SMALL_BLIND: 3,
  SETUP_BIG_BLIND: 4,
  DEAL_CARDS: 5,
  DEAL_FLOP: 6,
  FLOP_BETTING_ROUND: 7,
  DEAL_TURN: 8,
  TURN_BETTING_ROUND: 9,
  DEAL_RIVER: 10,
  RIVER_BETTING_ROUND: 11,
  AFTER_RIVER_BETTING_ROUND: 12,
  FIND_WINNERS: 13,
  PAY_WINNERS: 14,
  RECORD_NEW_STATS_AND_START_NEW_HAND: 15
};

export const HAND_TYPE = {
  HI: 'Hi',
  LO: 'Lo'
};
