import { createContext, PropsWithChildren, useCallback, useEffect, useState } from 'react';
import audioFile from 'assets/sounds/audio.mp3';
import { audioSprite } from 'constants/audioConstants';
import useSound from 'use-sound';

type GlobalContextType = {
  isLoading: any;
  openInfoMsg: any;
  openAskBuyin: boolean;
  buyinAmount: any;
  isBuyinValueSet: any;
  userSession: any;
  openAccessTokenModal: any;
  manuallyAssignedSeatId: number;
  openWaitForBuyIn: boolean;
  openAddFunds: boolean;
  isJoining: boolean;
  gameType: string;
  closeBuyInModal: boolean;
  isLocated: boolean;
  isLocationInvalid: boolean;
  invalidLocationMsg: string;
  deviceId: string;
  duplicateAccessMsg: string;
  isDuplicateAccess: boolean;
  isTriggerBuyIn: boolean;
  setIsLoading: (flag: boolean) => void;
  setOpenAskBuyin: (flag: boolean) => void;
  setBuyinAmount: (amount: number) => void;
  setOpenAddFunds: (flag: boolean) => void;
  setIsBuyinValueSet: (flag: boolean) => void;
  setInfoMsg: (tables: any) => any;
  setUserSession: (userSession: any) => any;
  playSound: (playSound: string) => void;
  setOpenAccessTokenModal: (flag: boolean) => void;
  setManuallyAssignedSeatId: (seatId: number) => void;
  setOpenWaitForBuyIn: (flag: boolean) => void;
  setIsJoining: (value: boolean) => void;
  setGameType: (value: string) => void;
  setCloseBuyInModal: (flag: boolean) => void;
  setIsLocated: (flag: boolean) => void;
  setIsLocationInvalid: (flag: boolean) => void;
  setInvalidLocationMsg: (msg: string) => void;
  setDeviceId: (deviceID: string) => void;
  setDuplicateAccessMsg: (msg: string) => void;
  setIsDuplicateAccess: (flag: boolean) => void;
  setIsTriggerBuyIn: (value: boolean) => void;
};

const globalContextDefault: GlobalContextType = {
  isLoading: false,
  openInfoMsg: false,
  openAskBuyin: false,
  buyinAmount: false,
  isBuyinValueSet: false,
  userSession: {
    user: 0
  },
  openAccessTokenModal: false,
  manuallyAssignedSeatId: 0,
  openWaitForBuyIn: false,
  openAddFunds: false,
  isJoining: false,
  gameType: '',
  closeBuyInModal: false,
  isLocated: false,
  isLocationInvalid: false,
  invalidLocationMsg: '',
  deviceId: '',
  duplicateAccessMsg: '',
  isDuplicateAccess: false,
  isTriggerBuyIn: false,
  setIsLoading: (_) => {},
  setInfoMsg: (_) => {},
  setOpenAskBuyin: (_) => {},
  setBuyinAmount: (_) => {},
  setIsBuyinValueSet: (_) => {},
  setUserSession: (_) => {},
  playSound: (_) => {},
  setOpenAccessTokenModal: (_) => {},
  setManuallyAssignedSeatId: (_) => {},
  setOpenWaitForBuyIn: (_) => {},
  setIsJoining: (_) => {},
  setGameType: (_) => {},
  setOpenAddFunds: (_) => {},
  setCloseBuyInModal: (_) => {},
  setIsLocated: (_) => {},
  setIsLocationInvalid: (_) => {},
  setInvalidLocationMsg: (_) => {},
  setDeviceId: (_) => {},
  setDuplicateAccessMsg: (_) => {},
  setIsDuplicateAccess: (_) => {},
  setIsTriggerBuyIn: (_) => {}
};

const GlobalContext = createContext<GlobalContextType>(globalContextDefault);

export const GlobalProvider = ({ children }: PropsWithChildren) => {
  const [isLoading, setIsLoading] = useState(true);
  const [openInfoMsg, setInfoMsg] = useState(false);
  const [openAskBuyin, setOpenAskBuyin] = useState(false);
  const [manuallyAssignedSeatId, setManuallyAssignedSeatId] = useState(0);
  const [buyinAmount, setBuyinAmount] = useState(0);
  const [isBuyinValueSet, setIsBuyinValueSet] = useState(false);
  const [userSession, setUserSession] = useState(
    JSON.parse(localStorage.getItem('userSession') || '{}')
  );
  const [openAccessTokenModal, setOpenAccessTokenModal] = useState(false);
  const [openWaitForBuyIn, setOpenWaitForBuyIn] = useState(false);
  const [openAddFunds, setOpenAddFunds] = useState(false);
  const [isJoining, setIsJoining] = useState(false);
  const [gameType, setGameType] = useState('texas');
  const [closeBuyInModal, setCloseBuyInModal] = useState(false);
  const [isLocated, setIsLocated] = useState(false);
  const [isLocationInvalid, setIsLocationInvalid] = useState(false);
  const [invalidLocationMsg, setInvalidLocationMsg] = useState('');
  const [deviceId, setDeviceId] = useState('');
  const [duplicateAccessMsg, setDuplicateAccessMsg] = useState('');
  const [isDuplicateAccess, setIsDuplicateAccess] = useState(false);
  const [isTriggerBuyIn, setIsTriggerBuyIn] = useState(false);

  useEffect(() => {
    //Update Session Data here
    localStorage.setItem('userSession', JSON.stringify(userSession));
  }, [userSession]);

  const [playSprite] = useSound(audioFile, {
    sprite: {
      allin: [audioSprite.allin.start * 1000, audioSprite.allin.length * 1000],
      crowdGroan: [audioSprite.crowdGroan.start * 1000, audioSprite.crowdGroan.length * 1000],
      bet: [audioSprite.bet.start * 1000, audioSprite.bet.length * 1000],
      check: [audioSprite.check.start * 1000, audioSprite.check.length * 1000],
      fold: [audioSprite.fold.start * 1000, audioSprite.fold.length * 1000],
      tableCard: [audioSprite.tableCard.start * 1000, audioSprite.tableCard.length * 1000],
      shock1: [audioSprite.shock1.start * 1000, audioSprite.shock1.length * 1000],
      shock2: [audioSprite.shock2.start * 1000, audioSprite.shock2.length * 1000],
      collectchips: [audioSprite.collectchips.start * 1000, audioSprite.collectchips.length * 1000],
      applause1: [audioSprite.applause1.start * 1000, audioSprite.applause1.length * 1000],
      notify: [audioSprite.notify.start * 1000, audioSprite.notify.length * 1000],
      button: [audioSprite.button.start * 1000, audioSprite.button.length * 1000],
      button2: [audioSprite.button2.start * 1000, audioSprite.button2.length * 1000],
      shuffle: [audioSprite.shuffle.start * 1000, audioSprite.shuffle.length * 1000],
      thunder: [audioSprite.thunder.start * 1000, audioSprite.thunder.length * 1000],
      button3: [audioSprite.button3.start * 1000, audioSprite.button3.length * 1000],
      heartBeat: [audioSprite.heartBeat.start * 1000, audioSprite.heartBeat.length * 1000]
    }
  });

  const playSound = useCallback(
    (id: string) => {
      playSprite({ id });
    },
    [playSprite]
  );

  return (
    <GlobalContext.Provider
      value={{
        isLoading,
        setIsLoading,
        openInfoMsg,
        setInfoMsg,
        openAskBuyin,
        setOpenAskBuyin,
        buyinAmount,
        setBuyinAmount,
        isBuyinValueSet,
        setIsBuyinValueSet,
        setUserSession,
        userSession,
        playSound,
        openAccessTokenModal,
        setOpenAccessTokenModal,
        manuallyAssignedSeatId,
        setManuallyAssignedSeatId,
        openWaitForBuyIn,
        setOpenWaitForBuyIn,
        openAddFunds,
        setOpenAddFunds,
        isJoining,
        setIsJoining,
        gameType,
        setGameType,
        closeBuyInModal,
        setCloseBuyInModal,
        isLocated,
        setIsLocated,
        isLocationInvalid,
        setIsLocationInvalid,
        invalidLocationMsg,
        setInvalidLocationMsg,
        deviceId,
        setDeviceId,
        duplicateAccessMsg,
        setDuplicateAccessMsg,
        isDuplicateAccess,
        setIsDuplicateAccess,
        isTriggerBuyIn,
        setIsTriggerBuyIn
      }}>
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalContext;
