import { createContext, useCallback, useState } from 'react';

type TableHistoryContextType = {
  isOpen: boolean;
  history?: any[];
  toggle: () => void;
  setIsOpen: (flag: boolean) => void;
};

export const TableHistoryContext = createContext<TableHistoryContextType>({
  isOpen: false,
  history: [],
  toggle: () => {},
  setIsOpen: (_: boolean) => {}
});

export const TableHistoryProvider = ({ children }: any) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = useCallback(() => {
    setIsOpen((prevState) => !prevState);
  }, []);

  return (
    <TableHistoryContext.Provider value={{ isOpen, setIsOpen, toggle }}>
      {children}
    </TableHistoryContext.Provider>
  );
};

export default TableHistoryContext;
