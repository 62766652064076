import React from 'react';
import { BET_PREFIX, FACEDOWN } from 'constants/gameConstants';
import { useAppSelector } from 'hooks';

import styles from './HandRank.module.scss';

const HandRank: React.FC = () => {
  const handRank = useAppSelector<any>((state) => state.user.handRank);
  const game = useAppSelector<any>((state) => state.currentTable.game);
  const seatId = useAppSelector((state) => state.currentTable.currentPlayer.seatId);

  //Check only the first card since it will have the same state with the other cards
  const isPlayerFolded =
    game['p' + seatId + 'bet'].substring(0, 1) === BET_PREFIX.FOLD ||
    game['p' + seatId + 'card1'].toLowerCase() === FACEDOWN;

  return (
    game[`p${seatId}card1`] &&
    !isPlayerFolded && (
      <div className={styles.HandRank}>
        <div className={styles.RankText}>{handRank?.name}</div>
      </div>
    )
  );
};

export default HandRank;
