import React, { createContext, Dispatch, PropsWithChildren, SetStateAction, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import GlobalErrorBoundary from 'components/GlobalErrorBoundary/GlobalErrorBoundary';
import { Position } from 'components/Table/OvalTable';

import { TableHistoryProvider } from './TableHistoryContext';
import { TexasChatContextProvider } from './TexasChatContext';

type GameContextType = {
  preselectedAction: string;
  potPosition: any;
  seatPositions: Position[];
  betPositions: Position[];
  tableCards: string[];
  isBlindPosted: boolean;
  playerMultiRunVote: string;
  openAddChips: boolean;
  openAddOnChips: boolean;
  canRebuyChips: boolean;
  openRebuyChips: boolean;
  isPlayerReady: boolean;
  isSatOut: boolean;
  isStandingUp: boolean;
  bustTime: string;
  isTimerOff: boolean;
  showTournamentDetails: boolean;
  dontAskAgain: boolean;
  setShowTournamentDetails: (value: boolean) => void;
  setPreselectedAction: Dispatch<SetStateAction<string>>;
  setPotPosition: (value: any) => void;
  setSeatPositions: (value: Position[]) => void;
  setBetPositions: (value: Position[]) => void;
  setTableCards: (value: string[]) => void;
  setIsBlindPosted: (value: boolean) => void;
  setPlayerMultiRunVote: (value: string) => void;
  setIsPlayerReady: (value: boolean) => void;
  setOpenAddChips: (value: boolean) => void;
  setOpenAddOnChips: (value: boolean) => void;
  setCanRebuyChips: (value: boolean) => void;
  setOpenRebuyChips: (value: boolean) => void;
  setIsSatOut: (value: boolean) => void;
  setIsStandingUp: (value: boolean) => void;
  setBustTime: (value: string) => void;
  setIsTimerOff: (value: boolean) => void;
  setDontAskAgain: (value: boolean) => void;
};

const GameContext = createContext<GameContextType>({
  preselectedAction: '',
  potPosition: { left: 0, top: 0 },
  seatPositions: [],
  betPositions: [],
  tableCards: [],
  isBlindPosted: false,
  playerMultiRunVote: '0',
  openAddChips: false,
  openAddOnChips: false,
  canRebuyChips: false,
  openRebuyChips: false,
  isPlayerReady: false,
  isSatOut: false,
  isStandingUp: false,
  bustTime: '',
  isTimerOff: false,
  showTournamentDetails: false,
  dontAskAgain: false,
  setShowTournamentDetails: () => {},
  setPreselectedAction: () => {},
  setPotPosition: () => {},
  setSeatPositions: () => {},
  setBetPositions: () => {},
  setTableCards: () => {},
  setIsBlindPosted: () => {},
  setPlayerMultiRunVote: () => {},
  setOpenAddChips: () => {},
  setOpenAddOnChips: () => {},
  setCanRebuyChips: () => {},
  setOpenRebuyChips: () => {},
  setIsPlayerReady: () => {},
  setIsSatOut: () => {},
  setIsStandingUp: () => {},
  setBustTime: () => {},
  setIsTimerOff: () => {},
  setDontAskAgain: () => {}
});

export const GameProvider = withRouter(({ children }: PropsWithChildren) => {
  const [preselectedAction, setPreselectedAction] = useState('');
  const [potPosition, setPotPosition] = useState({ left: 0, top: 0 });
  const [seatPositions, setSeatPositions] = useState<Position[]>([]);
  const [betPositions, setBetPositions] = useState<Position[]>([]);
  const [tableCards, setTableCards] = useState<string[]>([]);
  const [isBlindPosted, setIsBlindPosted] = useState(false);
  const [playerMultiRunVote, setPlayerMultiRunVote] = useState('0');
  const [openAddChips, setOpenAddChips] = useState(false);
  const [openAddOnChips, setOpenAddOnChips] = useState(false);
  const [canRebuyChips, setCanRebuyChips] = useState(false);
  const [openRebuyChips, setOpenRebuyChips] = useState(false);
  const [isPlayerReady, setIsPlayerReady] = useState(false);
  const [isSatOut, setIsSatOut] = useState(false);
  const [isStandingUp, setIsStandingUp] = useState(false);
  const [bustTime, setBustTime] = useState('');
  const [isTimerOff, setIsTimerOff] = useState(false);
  const [showTournamentDetails, setShowTournamentDetails] = useState(false);
  const [dontAskAgain, setDontAskAgain] = useState(false);

  return (
    <GameContext.Provider
      value={{
        preselectedAction,
        potPosition,
        seatPositions,
        betPositions,
        tableCards,
        isBlindPosted,
        playerMultiRunVote,
        openAddChips,
        openAddOnChips,
        canRebuyChips,
        openRebuyChips,
        isPlayerReady,
        isSatOut,
        isStandingUp,
        bustTime,
        isTimerOff,
        showTournamentDetails,
        dontAskAgain,
        setShowTournamentDetails,
        setPreselectedAction,
        setPotPosition,
        setSeatPositions,
        setBetPositions,
        setTableCards,
        setIsBlindPosted,
        setPlayerMultiRunVote,
        setOpenAddChips,
        setOpenAddOnChips,
        setCanRebuyChips,
        setOpenRebuyChips,
        setIsPlayerReady,
        setIsSatOut,
        setIsStandingUp,
        setBustTime,
        setIsTimerOff,
        setDontAskAgain
      }}>
      <TableHistoryProvider>
        <TexasChatContextProvider>
          <GlobalErrorBoundary>{children}</GlobalErrorBoundary>
        </TexasChatContextProvider>
      </TableHistoryProvider>
    </GameContext.Provider>
  );
});

export default GameContext;

function withRouter(Component: any) {
  function ComponentWithRouterProp(props: any) {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    return <Component {...props} router={{ location, navigate, params }} />;
  }

  return ComponentWithRouterProp;
}
