import { useContext } from 'react';
import GameContext from 'contexts/GameContext';
import useGameService from 'services/GameService';

import CheckboxAction from 'components/UserActions/PokerActions/CheckboxAction';

import 'components/UserActions/UserActions.scss';

const WaitForBigBlind = () => {
  const { waitForBB } = useGameService();
  const { preselectedAction, setPreselectedAction } = useContext(GameContext);

  const changeEvent = function () {
    setPreselectedAction('waitforbb');
    waitForBB();
  };

  return (
    <>
      <CheckboxAction
        label='Wait For Big Blind'
        value='waitforbb'
        onChange={changeEvent}
        checked={preselectedAction === '' || preselectedAction === 'waitforbb'}
      />
    </>
  );
};

export default WaitForBigBlind;
