import React, { CSSProperties, useCallback } from 'react';
import { NumericFormat } from 'react-number-format';
import { Button, styled } from '@mui/material';
import { FormControl, InputLabel, OutlinedInput } from '@mui/material';
import Slider from '@mui/material/Slider';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useAppSelector } from 'hooks';
import { calculateStepValue } from 'utils/functions';

const CustomizedSlider = styled(Slider)({
  color: '#e98c27'
});

const RaisePreSetBtn = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          color: 'white',
          backgroundColor: '#5b6671',
          '&:hover': {
            color: 'white',
            backgroundColor: '#37393b'
          },
          '&:disabled': {
            color: 'white',
            opacity: '0.4'
          },
          fontWeight: '400',
          padding: '0.25rem 0.75rem',
          minWidth: 'unset',
          textTransform: 'none',
          width: '100%'
        }
      }
    }
  }
});

const TextFieldTheme = createTheme({
  palette: {
    mode: 'dark'
  },
  components: {
    MuiFormControl: {
      styleOverrides: {
        root: {
          margin: '0.5rem 0rem !important'
        }
      }
    }
  }
});

const BaseStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  position: 'absolute',
  left: '100%',
  bottom: 0,
  width: '22rem',
  padding: '1.5rem 1.5rem 1rem',
  background: 'black',
  borderRadius: '0.25rem',
  rowGap: '8px'
};

const RaiseActionExpanded = ({ setAmount, amount, raiseButtonRef }: any) => {
  const seatId = useAppSelector((state) => state.currentTable.currentPlayer.seatId);
  const players = useAppSelector<any>((state) => state.currentTable.players);
  const isPennyTable = useAppSelector((state) => state.currentTable.game.isPennyTable);
  const bb = useAppSelector((state) => state.currentTable.game.bb);
  const {
    min,
    x2,
    x3,
    x5,
    oneHalf,
    oneFourth,
    fullPot,
    playersStack,
    x2isDisabled,
    x3isDisabled,
    x5isDisabled,
    oneHalfIsDisabled,
    oneFourthIsDisabled,
    fullPotDisabled,
    allInDisabled,
    hasPotLimit
  } = useAppSelector((state) => state.currentTable.gameState.pokerActions.betMultiplier);

  const handleRaiseSlider = (e: Event) => {
    setAmount((e?.target as any).value);
    raiseButtonRef.current.focus();
  };

  const updateAmount = useCallback(
    (e: any) => {
      let keyVal = e.target.value;

      if (!isNaN(+e.target.value) || keyVal === '.') {
        if (hasPotLimit && keyVal > fullPot) keyVal = fullPot;
        setAmount(keyVal);
      }
    },
    [players, seatId, setAmount]
  );

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      raiseButtonRef.current.focus();
    }
  };

  const handlePasteValue = (event: React.ClipboardEvent<HTMLInputElement>) => {
    const pastedValue = Number(event.clipboardData.getData('text'));
    if (pastedValue > playersStack) {
      setAmount(playersStack);
    }
  };

  return (
    <div style={BaseStyle}>
      <div style={{ display: 'flex', gap: '3px' }}>
        <ThemeProvider theme={RaisePreSetBtn}>
          {!oneFourthIsDisabled && (
            <Button size='small' onClick={() => setAmount(oneFourth)}>
              1/4
            </Button>
          )}
          {!oneHalfIsDisabled && (
            <Button size='small' onClick={() => setAmount(oneHalf)}>
              1/2
            </Button>
          )}
          {!x2isDisabled && (
            <Button size='small' onClick={() => setAmount(x2)}>
              2x
            </Button>
          )}
          {!x3isDisabled && (
            <Button size='small' onClick={() => setAmount(x3)}>
              3x
            </Button>
          )}
          {!x5isDisabled && (
            <Button size='small' onClick={() => setAmount(x5)}>
              5x
            </Button>
          )}
          {!fullPotDisabled && (
            <Button size='small' onClick={() => setAmount(fullPot)}>
              Pot
            </Button>
          )}
          {!allInDisabled && (
            <Button size='small' onClick={() => setAmount(playersStack)}>
              All In
            </Button>
          )}
        </ThemeProvider>
      </div>

      <div style={{ display: 'flex', paddingLeft: '1rem', paddingRight: '1rem' }}>
        <CustomizedSlider
          value={+amount}
          aria-label='Bet amount'
          min={min > playersStack ? playersStack : min}
          max={hasPotLimit ? fullPot : playersStack}
          step={isPennyTable ? calculateStepValue(bb) : 1}
          onChange={(e) => handleRaiseSlider(e)}
        />
      </div>

      <ThemeProvider theme={TextFieldTheme}>
        <FormControl fullWidth sx={{ m: 1 }}>
          <InputLabel htmlFor='outlined-adornment-amount'>Amount</InputLabel>
          <NumericFormat
            id='outlined-adornment-amount'
            customInput={OutlinedInput}
            value={
              hasPotLimit
                ? amount > fullPot
                  ? fullPot
                  : amount
                : amount > playersStack
                ? playersStack
                : amount
            }
            onChange={updateAmount}
            onPaste={handlePasteValue}
            allowNegative={false}
            label='Amount'
            decimalScale={isPennyTable ? 2 : 0}
            onKeyDown={handleKeyDown}
          />
        </FormControl>
      </ThemeProvider>
    </div>
  );
};

export default RaiseActionExpanded;
