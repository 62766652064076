import React, { useContext } from 'react';
import { a, useChain, useSpring, useSpringRef } from 'react-spring';
import ChipImage from 'assets/images/chip.svg';
import {
  BET_HEIGHT,
  BET_WIDTH,
  SEAT_DISTANCE_FROM_TABLE,
  SEAT_HEIGHT
} from 'constants/gameConstants';
import GameContext from 'contexts/GameContext';

import { Position } from './OvalTable';

type WinChipsPropType = {
  seatId: number;
  seatPosition: Position;
};

const WinChips = ({ seatPosition }: WinChipsPropType) => {
  const halfBetWidth = BET_WIDTH / 2;
  const halfBetHeight = BET_HEIGHT / 2;
  const halfSeatHeight = SEAT_HEIGHT / 2;
  const { potPosition } = useContext(GameContext);

  const startPosition = {
    left: potPosition.left - halfBetWidth,
    top: potPosition.top - halfBetHeight
  };

  const seatPositionTop =
    seatPosition.top - SEAT_DISTANCE_FROM_TABLE - halfSeatHeight - halfBetHeight;
  const seatPositionLeft = seatPosition.left - SEAT_DISTANCE_FROM_TABLE - halfBetWidth;

  const style1SpringRef = useSpringRef();
  const { opacity: opacity1, ...style1 } = useSpring({
    ref: style1SpringRef,
    from: {
      opacity: 1,
      top: startPosition.top,
      left: startPosition.left
    },
    to: {
      opacity: 0,
      top: seatPositionTop,
      left: seatPositionLeft
    },
    config: {
      duration: 500
    }
  });

  const style2SpringRef = useSpringRef();
  const { opacity: opacity2, ...style2 } = useSpring({
    ref: style2SpringRef,
    from: {
      opacity: 1,
      top: startPosition.top,
      left: startPosition.left
    },
    to: {
      opacity: 0,
      top: seatPositionTop,
      left: seatPositionLeft
    },
    config: {
      duration: 500
    }
  });

  const style3SpringRef = useSpringRef();
  const { opacity: opacity3, ...style3 } = useSpring({
    ref: style3SpringRef,
    from: {
      opacity: 1,
      top: startPosition.top,
      left: startPosition.left
    },
    to: {
      opacity: 0,
      top: seatPositionTop,
      left: seatPositionLeft
    },
    config: {
      duration: 500
    }
  });

  useChain([style1SpringRef, style2SpringRef, style3SpringRef], [0, 0.2, 0.4], 1000);

  return (
    <>
      {
        <a.div
          style={{
            zIndex: 2,
            position: 'absolute',
            ...style1,
            opacity: opacity1.to((f: number) => (f > 0.8 ? 0 : f > 0.2 ? 1 : f))
          }}>
          <img src={ChipImage} alt='' style={{ width: BET_WIDTH, height: BET_HEIGHT }} />
        </a.div>
      }
      <a.div
        style={{
          zIndex: 2,
          position: 'absolute',
          ...style2,
          opacity: opacity2.to((f: number) => (f > 0.8 ? 0 : f > 0.2 ? 1 : f))
        }}>
        <img src={ChipImage} alt='' style={{ width: BET_WIDTH, height: BET_HEIGHT }} />
      </a.div>
      <a.div
        style={{
          zIndex: 2,
          position: 'absolute',
          ...style3,
          opacity: opacity3.to((f: number) => (f > 0.8 ? 0 : f > 0.2 ? 1 : f))
        }}>
        <img src={ChipImage} alt='' style={{ width: BET_WIDTH, height: BET_HEIGHT }} />
      </a.div>
    </>
  );
};

export default WinChips;
