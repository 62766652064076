import React from 'react';
import { CircularProgress } from '@material-ui/core';

import AuthLogo from 'components/AuthLogo/AuthLogo';

import styles from './LoadingState.module.scss';

const LoadingState: React.FC = () => {
  return (
    <div className={styles.main_wrapper}>
      <div className={styles.inner_wrapper}>
        <AuthLogo />
        <div
          style={{
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            paddingTop: '2rem'
          }}
        >
          <CircularProgress />
        </div>
      </div>
    </div>
  );
};

export default LoadingState;
