import React, { useContext, useEffect, useRef } from 'react';
import Avatar from '@mui/material/Avatar';
import { LEFT_PANE_WIDTH } from 'constants/gameConstants';
import ScaleContext from 'contexts/ScaleContext';
import TableHistoryContext from 'contexts/TableHistoryContext';
import { useAppSelector } from 'hooks';
import { ZIndex } from 'styles';
import { numberFormatter } from 'utils/functions';

import { isFaceDownCard, mapToCard } from 'components/Card';
import SmallCard from 'components/Card/SmallCard';

import styles from './TableHistory.module.scss';

const TableHistory = () => {
  const { isOpen } = useContext(TableHistoryContext);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const historyMessagesRef = useRef<HTMLDivElement>(null);
  const tableType = useAppSelector<any>((state) => state.currentTable.game.tabletype);
  const { scale } = useContext(ScaleContext);
  const {
    tableHistory,
    game: { msg, hand }
  } = useAppSelector((state) => state.currentTable);

  useEffect(() => {
    if (historyMessagesRef?.current) {
      const { scrollHeight, clientHeight, scrollTop } = historyMessagesRef.current;

      if (scrollHeight - clientHeight === scrollTop) {
        setTimeout(
          () =>
            messagesEndRef.current?.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            }),
          200
        );
      }
    }
  }, [msg, hand]);

  return (
    <div
      className={styles.table_history}
      style={{
        height: '100%',
        zIndex: ZIndex.overlay,
        width: LEFT_PANE_WIDTH * scale,
        visibility: isOpen ? 'visible' : 'hidden'
      }}>
      <div className={styles.header}>
        <div
          style={{
            padding: `calc(1rem * ${scale})`,
            fontSize: `max(calc(1rem * ${scale}), 12px)`
          }}>
          Table History
        </div>
      </div>
      <div
        className={styles.body}
        ref={historyMessagesRef}
        style={{ padding: `calc(1rem * ${scale})` }}>
        <div className={styles.history_list} style={{ gap: `calc(0.5rem * ${scale})` }}>
          {tableHistory
            ?.filter((f) => !!f.msg || f.isShowCard)
            .map(
              (
                {
                  msg,
                  avatar,
                  systemMsg,
                  isShowCard,
                  playerCards,
                  tableCards,
                  totalPot,
                  isShowPot
                },
                i
              ) => (
                <React.Fragment key={i}>
                  {!!msg && (
                    <div className={styles.history_item} style={{ gap: `calc(0.5rem * ${scale})` }}>
                      {!systemMsg && (
                        <Avatar
                          style={{
                            width: `calc(28px * ${scale})`,
                            height: `calc(28px * ${scale})`
                          }}
                          src={avatar}
                        />
                      )}
                      <div
                        className={styles.history_detail}
                        style={{
                          flexGrow: 1,
                          marginLeft: 0,
                          fontSize: `max(calc(0.8rem * ${scale}), 11px)`,
                          padding: `calc(0.5rem * ${scale}) calc(0.75rem * ${scale})`,
                          borderRadius: `calc(0.5rem * ${scale})`
                        }}>
                        <p>{msg}</p>
                      </div>
                      {isShowPot && (
                        <div
                          className={styles.history_detail}
                          style={{
                            marginLeft: 0,
                            display: 'flex',
                            fontSize: `max(calc(1rem * ${scale}), 11px)`,
                            padding: `calc(0.5rem * ${scale}) calc(0.75rem * ${scale})`,
                            borderRadius: `calc(0.5rem * ${scale})`
                          }}>
                          {tableType === 's' ? '$' : ''}
                          {numberFormatter(Number(totalPot))}
                        </div>
                      )}
                    </div>
                  )}
                  {isShowCard &&
                    playerCards?.map(
                      (
                        { card1, card2, isFaceDownCard1, isFaceDownCard2, avatar: cardAvatar }: any,
                        j: any
                      ) => (
                        <React.Fragment key={`playerCard${j}`}>
                          <div
                            className={styles.history_item}
                            style={{ gap: `calc(0.5rem * ${scale})` }}>
                            <Avatar
                              style={{
                                width: `calc(28px * ${scale})`,
                                height: `calc(28px * ${scale})`
                              }}
                              src={cardAvatar}
                            />
                            <div
                              className={styles.history_detail}
                              style={{
                                marginLeft: 0,
                                display: 'flex',
                                gap: `calc(6px * ${scale})`,
                                fontSize: `max(calc(1rem * ${scale}), 11px)`,
                                padding: `calc(0.5rem * ${scale}) calc(0.75rem * ${scale})`,
                                borderRadius: `calc(0.5rem * ${scale})`
                              }}>
                              <SmallCard
                                {...mapToCard(card1)}
                                isFaceDown={isFaceDownCard1}
                                enableScale
                              />
                              <SmallCard
                                {...mapToCard(card2)}
                                isFaceDown={isFaceDownCard2}
                                enableScale
                              />
                            </div>
                          </div>
                        </React.Fragment>
                      )
                    )}
                  {!!tableCards && tableCards?.length > 0 && (
                    <div
                      className={styles.history_detail}
                      style={{
                        marginLeft: 0,
                        display: 'flex',
                        gap: `calc(6px * ${scale})`,
                        padding: `calc(0.5rem * ${scale}) calc(0.75rem * ${scale})`,
                        borderRadius: `calc(0.5rem * ${scale})`,
                        width: 'fit-content'
                      }}>
                      {tableCards?.map((card, i) => {
                        return (
                          !isFaceDownCard(card) && (
                            <SmallCard key={i} {...mapToCard(card)} enableScale />
                          )
                        );
                      })}
                    </div>
                  )}
                </React.Fragment>
              )
            )}

          <div ref={messagesEndRef} />
        </div>
      </div>
    </div>
  );
};

export default TableHistory;
