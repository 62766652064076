import { CSSProperties, useContext, useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import Fab from '@mui/material/Fab';
import GameContext from 'contexts/GameContext';
import { useAppSelector } from 'hooks';
import useGameService from 'services/GameService';

const StandUpButton = () => {
  const { leaveTable, imBack } = useGameService();
  const { isStandingUp, setIsStandingUp } = useContext(GameContext);

  const [openModal, setOpenModal] = useState(false);

  const hand = useAppSelector((state) => state.currentTable.game.hand);
  const chargeFrequency = useAppSelector((state) => state.currentTable.game.chargeFrequency);
  const isWaiting = true;
  // const isWaiting = useAppSelector<any>((state) => state.currentTable.currentPlayer.isWaiting);
  const seatId = useAppSelector<any>((state) => state.currentTable.currentPlayer.seatId);
  const playerStatus = useAppSelector<number>(
    (state) => (state.currentTable.game as any)[`p${seatId}status`]
  );

  useEffect(() => {
    setIsStandingUp(playerStatus === 2);
  }, [playerStatus]);

  const exitTable = () => {
    if (!isStandingUp) {
      setOpenModal(true);
    } else {
      comeBack();
    }
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleLeave = () => {
    setOpenModal(false);
    leaveTable();
    setIsStandingUp(true);
    // New requirement, stood up the player.
    // Don't close the game tab.
    // setTimeout(() => window.close(), 1000);
  };

  const comeBack = () => {
    if (hand === '' || +hand > 3) {
      imBack(seatId);
      setIsStandingUp(false);
    }
  };

  return (
    <>
      <Fab
        onClick={exitTable}
        variant='extended'
        style={isStandingUp ? { ...clickedButton } : { ...buttonStyle }}>
        {isStandingUp ? 'Cancel Stand Up' : 'Stand Up'}
      </Fab>
      <Dialog
        open={openModal}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'>
        <DialogTitle id='alert-dialog-title'>Are you sure?</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            {isWaiting
              ? `You are about to leave the table. Please confirm`
              : `You are about to leave the table. You will have a dead hand and ongoing ${chargeFrequency}-min charge timer will be forfeited. Please confirm`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleLeave}>Leave</Button>
          <Button onClick={handleClose} autoFocus>
            Stay
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const buttonStyle: CSSProperties = {
  // marginLeft:"1.5rem"
};
const clickedButton: CSSProperties = {
  backgroundColor: 'green'
};

export default StandUpButton;
