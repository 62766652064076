import { CSSProperties } from 'react';
import { a, animated, useSpring, useTrail } from 'react-spring';
import { SMALL_CARD_HEIGHT, SMALL_CARD_WIDTH } from 'constants/gameConstants';
import { useAppSelector } from 'hooks';
import * as _ from 'lodash';
import { ZIndex } from 'styles';

import { mapToCard } from 'components/Card';
import CommunityCard from 'components/Card/CommunityCard';

const getFixCards = (multiRunCards: any, runMax: number, runIndex: number, hand: string) => {
  let max = runIndex;

  if (hand === '5' && runMax === 1) {
    return [];
  }

  if (+hand > 5 && +hand < 10) {
    max = 1;
  }

  const cards = _.range(max).map((i) => {
    let currentRun = multiRunCards[`run${runIndex}`] ?? [];

    if (hand === '5' && runMax > 1) {
      currentRun = multiRunCards[`run${i + 1}`] ?? [];
      return currentRun.slice(0, 3);
    }

    if (['6', '7'].includes(hand)) {
      return currentRun.slice(0, 3);
    }

    if (+hand > 7 && +hand < 15) {
      return currentRun.slice(0, 4);
    }

    return [];
  });

  return cards;
};

const getDynamicCards = (multiRunCards: any, runMax: number, runIndex: number, hand: string) => {
  let numberOfCards = 0;

  if (hand === '5' && runMax > 1) {
    numberOfCards = 2;
  }

  if (['6', '7'].includes(hand)) {
    numberOfCards = 2;
  }

  if (+hand > 7 && +hand < 15) {
    numberOfCards = 1;
  }

  const cards = [];
  for (let i = 1; i <= runIndex; i++) {
    const tempCards = multiRunCards[`run${i}`] ?? [];
    if (tempCards.length >= numberOfCards) {
      const startIndex = tempCards.length - numberOfCards;
      cards.push(tempCards.slice(startIndex));
    }
  }

  return cards;
};

const TrailCards = ({ cards }: any) => {
  const trailProps = useTrail(cards.length, {
    from: { x: -50, opacity: 0 },
    to: { x: 0, opacity: 1 },
    config: { mass: 5, tension: 500, friction: 80 }
  });

  return (
    <>
      {cards.length > 0 && (
        <div style={CardsRowContainerStyle}>
          {trailProps.map((props: any, index: any) => {
            const card = cards[index];
            const isFaceDown = props?.opacity?.to((o: any) => o !== 1).toJSON();

            return (
              <animated.div key={`trail-card-${index}`} style={props}>
                <CommunityCard
                  elevation={6}
                  suit={card?.suit}
                  rank={card?.rank}
                  isFaceDown={!!isFaceDown}
                  card={card?.card}
                />
              </animated.div>
            );
          })}
        </div>
      )}
    </>
  );
};

const HoldEmCards = () => {
  const multiRunCards = useAppSelector((state) => state.currentTable.game.multiRunCards);
  const runs = useAppSelector((state) => state.currentTable.game.runs);
  const hand = useAppSelector((state) => state.currentTable.game.hand);
  const languageKey = useAppSelector((state) => state.currentTable.game.languageKey);

  const isMultiRunActive = useAppSelector<any>(
    (state) => state.currentTable.currentPlayer.isMultiRunActive
  );

  const runArray = runs?.split(',').length > 2 ? runs?.split(',') : [];
  const currentRunHand = runArray[1];
  const currentRunIndex = +(runArray[2] ?? '1');
  const currentRunMax = +(runArray[0] ?? '1');
  const currentHand = currentRunHand ?? hand;

  const fixCards = getFixCards(multiRunCards, currentRunMax, currentRunIndex, currentHand)
    .map((m) => m.map(mapToCard))
    .filter((f: any) => !!f);

  const dynamicCards = getDynamicCards(
    multiRunCards,
    currentRunMax,
    currentRunIndex,
    currentHand
  ).map((m) => m.map(mapToCard).filter((f: any) => !!f));

  const hasFixCards = !!currentRunHand && +currentRunHand > 5 && +currentRunHand < 10;

  const moveHoldemCards = useSpring({
    transform: isMultiRunActive ? 'translateY(0)' : `translateY(-25px)`,
    from: {
      transform: `translateY(-25px)`
    }
  });

  return (
    <>
      {hand !== '15' && languageKey !== 'GAME_MSG_ALLFOLD' && (
        <>
          <div style={HoldemPlaceholderStyle}>
            {currentRunMax === 1 && (
              <div style={CardsRowContainerStyle}>
                {_.range(5).map((i: any) => (
                  <div key={`fix-${i}`} style={OutlinedCardStyle} />
                ))}
              </div>
            )}
          </div>
          <a.div
            style={{
              ...HoldemContainerStyle,
              ...moveHoldemCards,
              alignItems: hasFixCards ? 'center' : 'flex-start'
            }}>
            <div style={CardsColumnContainerStyle}>
              {fixCards?.map((cards, i) => (
                <TrailCards key={`fix-card-${i}`} cards={cards} />
              ))}
            </div>
            <div style={CardsColumnContainerStyle}>
              {dynamicCards?.map((cards, i) => (
                <TrailCards key={`dynamic-card-${i}`} cards={cards} />
              ))}
            </div>
          </a.div>
        </>
      )}
    </>
  );
};

export default HoldEmCards;

const OutlinedCardStyle: CSSProperties = {
  width: SMALL_CARD_WIDTH,
  height: SMALL_CARD_HEIGHT,
  boxSizing: 'border-box',
  borderRadius: '0.25rem',
  display: 'block',
  border: '2px dashed rgba(255, 255, 255, 0.25)',
  zIndex: 2
};

const HoldemPlaceholderStyle: CSSProperties = {
  display: 'flex',
  position: 'absolute',
  bottom: 160,
  gap: 8,
  width: 272,
  alignItems: 'center',
  zIndex: ZIndex.card
};

const HoldemContainerStyle: CSSProperties = {
  display: 'flex',
  position: 'absolute',
  gap: 8,
  width: 272,
  alignItems: 'center',
  zIndex: ZIndex.card,
  transform: `translateY(-25px)`
};

const CardsColumnContainerStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: 8,
  alignItems: 'center',
  zIndex: ZIndex.card
};

const CardsRowContainerStyle: CSSProperties = {
  display: 'flex',
  gap: 8,
  width: '100%',
  zIndex: ZIndex.card
};
