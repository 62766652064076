import { createSlice } from '@reduxjs/toolkit';

import { Card } from 'components/Card';

export enum ETournamentStatus {
  Announced = 'A',
  Registering = 'R',
  Playing = 'P',
  Finished = 'F',
  Cancelled = 'C'
}

export type PlayerGame = {
  gameID: number;
  game: string;
  tabletype: string;
  pot: number;
  position: number;
  myTurn: number;
  overbet: string;
  hand: string;
  card1: Card;
  card2: Card;
  card3?: Card;
  card4?: Card;
  card5?: Card;
  bet: string;
  status: string;
  tournamentStatus: ETournamentStatus;
};

export const playerGamesSlice = createSlice({
  name: 'playerGames',
  initialState: [] as PlayerGame[],
  reducers: {
    updatePlayerGames: (state, action) => {
      return action.payload;
    }
  }
});

export const { updatePlayerGames } = playerGamesSlice.actions;

export default playerGamesSlice.reducer;
