import { useContext, useEffect, useState } from 'react';
import { Button, createTheme, Modal, ThemeProvider, Typography } from '@mui/material';
import { Box } from '@mui/system';
import GlobalContext from 'contexts/GlobalContext';

const DuplicateScreenModal = () => {
  const [showDuplicateModal, setShowDuplicateModal] = useState(false);
  const { isDuplicateAccess, duplicateAccessMsg } = useContext(GlobalContext);
  const [msg, setMsg] = useState('');

  useEffect(() => {
    if (isDuplicateAccess) {
      setMsg(duplicateAccessMsg);
      setShowDuplicateModal(true);
    } else {
      setMsg(duplicateAccessMsg);
      setShowDuplicateModal(false);
    }
  }, [isDuplicateAccess]);

  const handleExitTable = () => {
    window.close();

    // Just in case the player open the game manually using URL
    const currentUrl = location.pathname;
    window.location.href = currentUrl;
  };

  return (
    <Modal open={showDuplicateModal}>
      <Box sx={modalStyle}>
        <Typography
          id='modal-modal-title'
          sx={{ textAlign: 'center', marginBottom: '1rem', fontWeight: 'bold' }}>
          {msg}
        </Typography>
        <div
          style={{
            justifyContent: 'center',
            marginTop: '2rem',
            gap: '2rem'
          }}>
          <ThemeProvider theme={UnregisterBtnStyle}>
            <Button
              sx={{ display: 'block', opacity: '.8' }}
              variant='contained'
              onClick={handleExitTable}>
              EXIT TABLE
            </Button>
          </ThemeProvider>
        </div>
      </Box>
    </Modal>
  );
};

export default DuplicateScreenModal;

const modalStyle = {
  position: 'absolute' as const,
  top: '40%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: '#012425',
  color: 'white',
  padding: '4rem 7rem',
  width: '40rem',
  borderRadius: '0.5rem'
};

const UnregisterBtnStyle = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          color: 'white',
          backgroundColor: '#5b6671',
          '&:hover': {
            color: 'white',
            backgroundColor: '#37393b'
          },
          '&:disabled': {
            color: 'white',
            opacity: '0.4'
          },
          fontSize: '1rem',
          fontWeight: '400',
          padding: '0.5rem 1rem',
          minWidth: 'unset',
          textTransform: 'none',
          width: '100%',
          marginTop: '10px'
        }
      }
    }
  }
});
