export const hiJackLabels = {
  lobby: {
    label: 'Lobby',
    balance: 'Balance',
    notification: 'Notification',
    cashier: 'Cashier',
    cashGame: 'Your Cash Games',
    tournamentGame: 'Your Tournaments'
  }
};
