import React from 'react';
import { Button, createTheme, ThemeProvider } from '@mui/material';
import useGameService from 'services/GameService';

import 'components/UserActions/UserActions.scss';

const ShowCard = () => {
  const { showCard } = useGameService();

  const DefaultBtn = createTheme({
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            color: 'white',
            backgroundColor: '#5b6671',
            '&:hover': {
              color: 'white',
              backgroundColor: '#37393b'
            },
            '&:disabled': {
              color: 'white',
              opacity: '0.4'
            },
            fontSize: '1rem',
            fontWeight: '400',
            padding: '0.75rem 1rem',
            maxWidth: '10rem',
            textTransform: 'none',
            width: '100%'
          }
        }
      }
    }
  });

  return (
    <ThemeProvider theme={DefaultBtn}>
      <Button size='small' onClick={showCard}>
        Show Cards
      </Button>
    </ThemeProvider>
  );
};

export default ShowCard;
