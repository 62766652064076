import betChips from 'assets/sounds/bet_chips.wav';
import check from 'assets/sounds/check.wav';
import chipsToPot from 'assets/sounds/chips_to_pot.wav';
import dealingCards from 'assets/sounds/dealing_cards.wav';
import fold from 'assets/sounds/fold.wav';
import playerFlipCards from 'assets/sounds/player_flip_cards.wav';
import playerOutOfTime from 'assets/sounds/player_out_of_time.wav';
import playerWins from 'assets/sounds/player_wins.wav';
import playersTurn from 'assets/sounds/players_turn.wav';
import tableFlipCards from 'assets/sounds/table_flip_cards.wav';

export const audioSprite = {
  allin: {
    start: 0,
    length: 0.5
  },
  crowdGroan: {
    start: 0.9,
    length: 1.5
  },
  bet: {
    start: 2.9,
    length: 0.2
  },
  check: {
    start: 3.9,
    length: 0.2
  },
  fold: {
    start: 4.9,
    length: 0.2
  },
  tableCard: {
    start: 6.01,
    length: 0.08
  },
  shock1: {
    start: 9.9,
    length: 1
  },
  shock2: {
    start: 6.9,
    length: 1.9
  },
  collectchips: {
    start: 11.9,
    length: 1.1
  },
  applause1: {
    start: 13.9,
    length: 7.5
  },
  notify: {
    start: 21.9,
    length: 0.8
  },
  button: {
    start: 23.9,
    length: 1
  },
  button2: {
    start: 25.9,
    length: 0.1
  },
  shuffle: {
    start: 26.9,
    length: 0.6
  },
  thunder: {
    start: 27.9,
    length: 1.2
  },
  button3: {
    start: 31.9,
    length: 0.1
  },
  heartBeat: {
    start: 33,
    length: 11
  }
};

export const audioPath = {
  betChips,
  check,
  chipsToPot,
  dealingCards,
  fold,
  playerFlipCards,
  playerOutOfTime,
  playerWins,
  playersTurn,
  tableFlipCards
};
