import { CSSProperties, useContext } from 'react';
import { GiTrophy } from 'react-icons/gi';
import { Link } from 'react-router-dom';
import { Tooltip, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import Logo from 'assets/images/hjlogo.png';
import { TABLE_TYPE_SITNGO, TABLE_TYPE_TOURNAMENT } from 'constants/gameConstants';
import GameContext from 'contexts/GameContext';
import ScaleContext from 'contexts/ScaleContext';
import { useAppSelector } from 'hooks';
import useGameService from 'services/GameService';
import { ZIndex } from 'styles';
import { currencyFormatter, getLocalTime } from 'utils/functions';
import { FRONT_OFFICE_LOCAL_URL } from 'utils/urls';

import MaintenanceScheduleNotification from 'components/MaintenanceSchedule/MaintenanceScheduleNotification';

import TableActions from './TableActions';

import './UserActions.scss';

const TopAction = () => {
  const { scale } = useContext(ScaleContext);
  const userBalance = useAppSelector((state) => state.user.playerBankAmount);
  const userChargeBalance = useAppSelector((state) => state.user.playerChargeBalance);
  const isPlayerSeated = useAppSelector((state) => state.currentTable.currentPlayer.isSeated);

  const maintenance = useAppSelector((state) => state.maintenanceSchedule);
  const game = useAppSelector((state) => state.currentTable.game);
  const {
    tabletype: tableType,
    schedule,
    status: gameStatus,
    isFinalMultiFlight,
    maximumTopUp
  } = game;
  const isMaintenance = useAppSelector((state) => state.maintenanceSchedule.isMaintenance);
  const { subscribeTournamentDetails } = useGameService();

  const cashierPath = 'hijack/cashier/deposit';
  const {
    showTournamentDetails,
    setShowTournamentDetails,
    canRebuyChips,
    setOpenAddChips,
    setOpenAddOnChips,
    setIsTimerOff,
    setOpenRebuyChips
  } = useContext(GameContext);
  const canAddOnChips = useAppSelector((state) => state.currentTable.game.canAddOnChips);
  const tournamentId = useAppSelector((state) => state.currentTable.game.tournamentId);

  const handleOpenAddChipsModal = () => {
    setOpenAddChips(true);
    setIsTimerOff(false);
  };

  const handleOpenAddOnChipsModal = () => {
    setOpenAddOnChips(true);
    setIsTimerOff(false);
  };

  const handlecanRebuyChipsModal = () => {
    setOpenRebuyChips(true);
    setIsTimerOff(false);
  };

  const toggleDetails = () => {
    setShowTournamentDetails(!showTournamentDetails);
    subscribeTournamentDetails(tournamentId);
  };

  return (
    <div className='site_header' style={topActionStyles}>
      <Grid container>
        <Grid
          item
          xs
          style={{
            display: 'flex',
            alignSelf: 'center',
            alignItems: 'center',
            transform: `scale(${scale})`,
            transformOrigin: 'top left'
          }}>
          <a href='' className='logo_link' style={{ marginLeft: '1rem' }}>
            <img src={Logo} alt='' style={{ maxWidth: '100px' }} />
          </a>
        </Grid>
        <Grid
          item
          xs
          style={{
            transform: `scale(${scale})`,
            transformOrigin: 'top right',
            marginBottom: scale === 1 ? '0px' : `${-110 * scale}px`
          }}>
          <div className='site_navigation dark-bg'>
            {isPlayerSeated &&
              userBalance >= 1 &&
              tableType !== TABLE_TYPE_TOURNAMENT &&
              tableType !== TABLE_TYPE_SITNGO &&
              maximumTopUp > 0 && (
                <div className='nav_item'>
                  <a onClick={handleOpenAddChipsModal}>Add Chips</a>
                </div>
              )}
            {isPlayerSeated &&
              userBalance >= 1 &&
              tableType === TABLE_TYPE_TOURNAMENT &&
              schedule !== 'Rolling' &&
              canAddOnChips &&
              isFinalMultiFlight !== 1 && (
                <div className='nav_item'>
                  <a onClick={handleOpenAddOnChipsModal}>Add-On</a>
                </div>
              )}
            {userBalance >= 1 &&
              tableType === TABLE_TYPE_TOURNAMENT &&
              schedule !== 'Rolling' &&
              canRebuyChips &&
              gameStatus === 'P' && (
                <div className='nav_item'>
                  <a onClick={handlecanRebuyChipsModal}>Re-Entry</a>
                </div>
              )}
            <div className='nav_item'>
              <Link to='/' target='_blank' rel='noopener'>
                Lobby
              </Link>
            </div>
            {(tableType === TABLE_TYPE_TOURNAMENT || tableType === TABLE_TYPE_SITNGO) && (
              <div className='nav_item'>
                <a onClick={toggleDetails}>
                  <GiTrophy style={{ fontSize: '1.3rem' }} />
                  &nbsp;Tournament{' '}
                </a>
              </div>
            )}
            <div className='nav_item balance' style={{ textAlign: 'center' }}>
              <a
                href={
                  window.location.hostname === 'hijack.local'
                    ? `${FRONT_OFFICE_LOCAL_URL}/${cashierPath}`
                    : 'https://account.' +
                      window.location.hostname.split('.')[1] +
                      '.' +
                      window.location.hostname.split('.')[2] +
                      `/${cashierPath}`
                }
                target='_blank'
                rel='noreferrer'>
                <Typography>
                  Balance <br />
                  {currencyFormatter(userBalance)} |{' '}
                  <Tooltip
                    title={
                      <span>The Promo balance is applicable solely for cash game charges</span>
                    }>
                    <span style={{ color: 'orange' }}>{currencyFormatter(userChargeBalance)}</span>
                  </Tooltip>
                </Typography>
              </a>
            </div>
          </div>
          <TableActions />
        </Grid>
        <MaintenanceScheduleNotification>
          {isMaintenance
            ? `Maintenance in progress. Server up by  ${getLocalTime(
                Number(maintenance.end)
              )}. Kindly exit after finishing your game. Thank you for your cooperation!`
            : maintenance.displayMessage !== undefined
            ? `${maintenance.title} ${maintenance.displayMessage}`
            : ''}
        </MaintenanceScheduleNotification>
      </Grid>
    </div>
  );
};

const topActionStyles: CSSProperties = {
  position: 'absolute',
  top: '0',
  zIndex: ZIndex.header
};

export default TopAction;
