import { CSSProperties } from 'react';
import { useAppSelector } from 'hooks';
import { isWinningCard } from 'utils/functions';

import { Card } from 'components/Card';

import './PokerCard.scss';

type PokerCardPropType = {
  card: Card;
  pCard: string;
  isPlayerFolded: boolean;
};

export const PokerCard = ({ card, pCard, isPlayerFolded }: PokerCardPropType) => {
  const { pots, showdown, languageKey } = useAppSelector<any>((state) => state.currentTable.game);
  const { isShowdown } = useAppSelector<any>((state) => state.currentTable.gameState);

  const isCurrentWinningCard = isWinningCard(pots, showdown, pCard);

  return (
    <>
      {!!card && (
        <div
          className={`poker-card-container poker-card mdc-elevation--z6`}
          style={{
            ...((isPlayerFolded ||
              (!isCurrentWinningCard &&
                isShowdown &&
                +showdown.currentStep > 1 &&
                languageKey !== 'GAME_MSG_ALLFOLD')) &&
              darkenCard)
          }}>
          <div className={`card-number-container`}>
            <div className={`card-number ${card.suit || 'h'}`}>{card.rank}</div>
          </div>
          <div className={`background-card ${card.suit || 'h'}`}></div>
        </div>
      )}
    </>
  );
};

const darkenCard: CSSProperties = {
  filter: 'brightness(0.4)'
};
