import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  title: '',
  displayMessage: '',
  start: '',
  end: '',
  isMaintenance: false
};

export const maintenanceSlice = createSlice({
  name: 'maintenanceSchedule',
  initialState,
  reducers: {
    setMaintenanceSchedule: (state, action) => {
      return action.payload;
    }
  }
});

export const { setMaintenanceSchedule } = maintenanceSlice.actions;

export default maintenanceSlice.reducer;
