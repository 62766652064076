import { CSSProperties, useEffect, useState } from 'react';
import React from 'react';
import { useAppSelector } from 'hooks';

const TournamentTableDetails = () => {
  const game = useAppSelector((state) => state.currentTable.game);
  const tournamentStatus = useAppSelector((state) => state.currentTable.tournamentStatus);
  const [levelCountdown, setLevelCountdown] = useState('00:00:00');

  useEffect(() => {
    if (tournamentStatus.length > 0) {
      const filteredGame = tournamentStatus.filter(
        (d: any) => d.tournamentId === game.tournamentId
      );

      if (filteredGame.length > 0) {
        const firstTournamentStatus = filteredGame[0] as any;
        setLevelCountdown(firstTournamentStatus?.levelCountDown);
      }
    }
  }, [tournamentStatus]);

  return game.tabletype !== 's' ? (
    <div style={TournamentTableDetailsContainerStyle}>
      <p>{game.lvlDisplay === 0 ? 'Break' : 'Level ' + game.lvlDisplay}</p>
      <p>{Number(game?.nextLevel) === -1 ? 'Max Level' : levelCountdown}</p>
      <p>
        {game.isMultiFlightBreak
          ? '-'
          : game.currentSB + '/' + game.currentBB + ' (' + game.currentAnte + ')'}
      </p>
      <p>
        {' '}
        Next Level:{' '}
        {game.isMultiFlightBreak
          ? '-'
          : game.isOnBreak && game.isMultiFlight === 1 && game.isFinalMultiFlight === 1
          ? game.sb + '/' + game.bb + ' (' + game.ante + ')'
          : game.nextLevelIsBreak === 1
          ? 'Break'
          : Number(game?.nextLevel) !== -1
          ? game.nextSB + '/' + game.nextBB + ' (' + game.nextAnte + ')'
          : 'None'}
      </p>
    </div>
  ) : (
    <></>
  );
};

export default TournamentTableDetails;

const TournamentTableDetailsContainerStyle: CSSProperties = {
  position: 'absolute',
  bottom: `4rem`,
  textAlign: 'center',
  fontFamily: 'Outfit',
  fontWeight: 600,
  fontStyle: 'normal',
  fontSize: `1.5rem`,
  lineHeight: `2rem`,
  color: 'rgba(255, 255, 255, 0.6)',
  zIndex: 2,
  marginLeft: '580px',
  marginBottom: '45px'
};
