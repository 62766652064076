import React, { useCallback, useEffect, useState } from 'react';
import { Button, createTheme, ThemeProvider } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import 'components/UserActions/UserActions.scss';

type CheckboxActionType = {
  label?: string;
  value?: string;
  checked?: boolean;
  onChange?: (value: string) => void;
  disabled?: boolean;
};

const CheckboxAction = ({
  label,
  value = '',
  checked,
  onChange,
  disabled = false
}: CheckboxActionType) => {
  const [internalChecked, setInternalChecked] = useState(checked);
  const [internalDisabled, setInternalDisabled] = useState(disabled);

  useEffect(() => {
    setInternalChecked(checked);
  }, [checked]);

  useEffect(() => {
    setInternalDisabled(disabled);
  }, [disabled]);

  const handleToggle = useCallback(() => {
    const finallChecked = !internalChecked;
    if (onChange) {
      onChange(finallChecked ? value : '');
      setInternalChecked(!internalChecked);
    }
  }, [internalChecked, onChange, value]);

  const DefaultBtn = createTheme({
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            color: 'white',
            backgroundColor: '#5b6671',
            '&:hover': {
              color: 'white',
              backgroundColor: '#37393b'
            },
            '&:disabled': {
              color: 'white',
              opacity: '0.4'
            },
            fontSize: '1rem',
            fontWeight: '400',
            padding: '0.30rem 0.55rem',
            minWidth: 'unset',
            textTransform: 'none',
            width: '100%'
          }
        }
      },
      MuiFormControlLabel: {
        styleOverrides: {
          root: {
            marginLeft: '0rem',
            whiteSpace: 'nowrap'
          }
        }
      }
    }
  });

  return (
    <ThemeProvider theme={DefaultBtn}>
      <Button size='small' onClick={handleToggle} disabled={internalDisabled}>
        <FormControlLabel
          sx={{ '& .MuiFormControlLabel-label': { fontSize: '1rem', padding: { x: 15 } } }}
          style={{ pointerEvents: 'none' }}
          label={label}
          checked={internalChecked}
          control={
            <Checkbox
              sx={{
                color: 'white',
                '&.Mui-checked': {
                  color: '#E98C26'
                },
                '& .MuiSvgIcon-root': { fontSize: '1.5rem' }
              }}
            />
          }
        />
      </Button>
    </ThemeProvider>
    // <div onClick={handleToggle} className="poker-action-btn">
    //   <div className="poker-action-content">
    //     <FormControlLabel
    //       sx={{ '& .MuiFormControlLabel-label': { fontSize: '1rem', padding: { x: 15 } } }}
    //       style={{ pointerEvents: "none" }}
    //       label={label}
    //       checked={internalChecked}
    //       control={
    //         <Checkbox
    //           sx={{
    //             color: "white",
    //             '&.Mui-checked': {
    //               color: "#E98C26",
    //             },
    //             '& .MuiSvgIcon-root': { fontSize: '1.5rem' }
    //           }}
    //         />
    //       }
    //     />
    //   </div>
    // </div>
  );
};

export default CheckboxAction;
