import React, { useContext, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Box, Button, createTheme, Modal, ThemeProvider, Typography } from '@mui/material';
import GlobalContext from 'contexts/GlobalContext';
import { useAppSelector } from 'hooks';
import { convertToNumber } from 'utils/functions';

import CountDownTimer from 'components/Timer/CountDownTimer';

const WaitForBuyInModal = () => {
  const {
    closeBuyInModal,
    openWaitForBuyIn,
    setCloseBuyInModal,
    setOpenWaitForBuyIn,
    setIsBuyinValueSet,
    setInfoMsg
  } = useContext(GlobalContext);
  const [searParams] = useSearchParams();
  const isPlayerObserver = searParams.get('observe') === 'yes' ? true : false;
  const seatId = useAppSelector((state) => state.currentTable.currentPlayer.seatId);
  const bust = useAppSelector((state) => state.currentTable.game.bust);
  const playersStack = convertToNumber(
    useAppSelector((state) => state.currentTable.players[seatId]?.stack)
  );
  const { tabletype, languageKey, bb } = useAppSelector((state) => state.currentTable.game);
  let isStartProgress =
    playersStack >= bb && languageKey === 'GAME_MSG_BUSTED_PAUSE' && bust !== null;
  const label = {
    gameTitle: 'GAME STARTS IN...',
    gameDescription: 'Waiting for other players to buy-in.',
    btnOk: 'Okay'
  };

  const handleClose = () => {
    setOpenWaitForBuyIn(false);
    setCloseBuyInModal(true);
    setIsBuyinValueSet(false);
    setInfoMsg(false);
    isStartProgress = false;
  };

  const onComplete = () => {
    setOpenWaitForBuyIn(false);
  };

  useEffect(() => {
    if (isStartProgress && !closeBuyInModal) {
      setOpenWaitForBuyIn(true);
    }
  }, [isStartProgress, bust]);

  return (
    <Modal
      open={
        !isPlayerObserver
          ? isStartProgress && openWaitForBuyIn && tabletype !== 'm' && tabletype !== 't'
          : false
      }>
      <Box
        sx={{
          ...modalStyle,
          boxShadow: 10,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '2rem'
        }}>
        <Typography id='modal-modal-title' variant='h5' component='h2' sx={{ textAlign: 'center' }}>
          {label.gameTitle}
        </Typography>
        <CountDownTimer isStartProgress={isStartProgress} size={110} onComplete={onComplete} />
        <div style={{ fontSize: '1.5rem', textAlign: 'center' }}>{label.gameDescription}</div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            gap: '2rem',
            width: '100%'
          }}>
          <ThemeProvider theme={CancelBtnStyle}>
            <Button
              sx={{ display: 'block', opacity: '.8', textTransform: 'uppercase' }}
              variant='contained'
              onClick={handleClose}>
              {label.btnOk}
            </Button>
          </ThemeProvider>
        </div>
      </Box>
    </Modal>
  );
};

export default WaitForBuyInModal;

const modalStyle = {
  position: 'absolute' as const,
  top: '40%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: '#012425',
  color: 'white',
  padding: '4rem 7rem',
  width: '40rem',
  borderRadius: '0.5rem'
};

const CancelBtnStyle = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          color: 'white',
          backgroundColor: '#5b6671',
          '&:hover': {
            color: 'white',
            backgroundColor: '#37393b'
          },
          '&:disabled': {
            color: 'white',
            opacity: '0.4'
          },
          fontSize: '1rem',
          fontWeight: '400',
          padding: '0.5rem 1rem',
          minWidth: 'unset',
          textTransform: 'none',
          width: '100%'
        }
      }
    }
  }
});
